import React, { useState } from 'react'
import { observer } from 'mobx-react'
import {
  GuitarStrum,
  GuitarStrumController,
  GuitarStrumPattern,
  GuitarStrumSequence,
  GuitarStrumStore,
  TimeSignature,
} from './controller'

const DEFAULT_TIME_SIGNATURE: TimeSignature = {
  label: '4/4',
  beatsPerMeasure: 4,
  beatType: 4,
}

const DEFAULT_STRUM_PATTERN: GuitarStrumPattern = {
  label: 'D U D U',
  strumSequence: [
    { kind: 'd', beat: 1, beatFraction: 0 },

    { kind: 'd', beat: 2, beatFraction: 0 },

    { kind: 'u', beat: 2, beatFraction: 0.75 },

    { kind: 'u', beat: 3, beatFraction: 0.25 },
    { kind: 'd', beat: 3, beatFraction: 0.5 },

    { kind: 'd', beat: 4, beatFraction: 0.0 },
    { kind: 'u', beat: 4, beatFraction: 0.25 },
    { kind: 'd', beat: 4, beatFraction: 0.5 },
    { kind: 'u', beat: 4, beatFraction: 0.75 },
  ],
}

export function createGuitarStrumPlayer() {
  const strumStore = new GuitarStrumStore()
  const strumController = new GuitarStrumController(strumStore, DEFAULT_TIME_SIGNATURE, DEFAULT_STRUM_PATTERN)

  const GuitarStrumPlayer = observer(() => {
    const { bpm, percentage, strumSequence } = strumStore
    const { setBpm, start, stop } = strumController
    return (
      <div>
        <GuitarStrumControls bpm={bpm} onChangeBpm={setBpm} onStart={start} onStop={stop} />
        <GuitarStrumPattern strumSequence={strumSequence} strumProgress={percentage} />
      </div>
    )
  })

  return { GuitarStrumPlayer }
}

const GuitarStrumControls = observer(
  ({
    bpm,
    onChangeBpm,
    onStart,
    onStop,
  }: {
    bpm: number
    onChangeBpm: (e: React.ChangeEvent<HTMLInputElement>) => void
    onStart: () => void
    onStop: () => void
  }) => {
    const [isPlaying, setPlaying] = useState(false)

    const start = () => {
      setPlaying(true)
      onStart()
    }

    const stop = () => {
      setPlaying(false)
      onStop()
    }

    return (
      <div>
        <div className='flex'>
          <div>Strum:</div>
          <button onClick={isPlaying ? stop : start} className='px-4 py-2 bg-gray-200'>
            {isPlaying ? 'Stop' : 'Play'}
          </button>
          <input type='number' value={bpm} onChange={onChangeBpm} className='px-4 py-2 border border-gray-200' />
        </div>
      </div>
    )
  },
)

const GuitarStrumPattern = ({
  strumSequence,
  strumProgress,
}: {
  strumSequence: GuitarStrumSequence
  strumProgress: number
}) => {
  const left = `${strumProgress}%`
  return (
    <div className='h-24 w-100 max-w-2xl flex relative rounded-md overflow-hidden'>
      <div className='h-24 w-1 bg-indigo-100 absolute' style={{ left }}></div>
      {strumSequence.map((strum, i) => {
        const label = getStrumLabel(strum)
        return (
          <div key={i} className='h-full w-1/4 border border-l-indigo-300 border-r-indigo-700 text-center flex-col'>
            <div className='h-1/2 flex items-center justify-center bg-indigo-800 text-white'>{label}</div>
            <div className='h-1/2 flex items-center justify-center bg-indigo-400'>{strum.kind}</div>
          </div>
        )
      })}
    </div>
  )
}

function getStrumLabel(strum: GuitarStrum) {
  if (strum.beatFraction === 0) {
    return strum.beat
  }

  if (strum.beatFraction === 0.5) {
    return '&'
  }

  return ''
}
