import mobxRemotedev from 'mobx-remotedev'
import { KeyboardAssistKind, KeyboardAssistSelectorStore } from 'components/keyboard_assist_selector/store'
import { action, makeObservable } from 'mobx'
import { Option } from '../base/selects'

@mobxRemotedev
export class KeyboardAssistSelectorController {
  constructor(private readonly store: KeyboardAssistSelectorStore) {
    makeObservable(this)
  }

  @action setSelectedAssist(assist: Option) {
    const { key, value } = assist
    this.store.selectedAssist = [value as KeyboardAssistKind, key]
  }
}
