import mobxRemotedev from 'mobx-remotedev'
import { VolumeControlStore } from 'components/volume_control/store'
import { action, makeObservable } from 'mobx'
import { Sampler } from 'tone'

@mobxRemotedev
export class VolumeControlController {
  constructor(private readonly store: VolumeControlStore, private readonly Instrument: Sampler) {
    makeObservable(this)
  }

  @action.bound setVolume(e: React.ChangeEvent<HTMLInputElement>) {
    const volume = parseInt(e.target.value)
    this.store.volume = volume
    this.Instrument.volume.value = volume
  }
}
