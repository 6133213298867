import { SheetMusicStore, SheetMusicXMLDocument } from './store'
import { makeObservable, computed } from 'mobx'
import { OpenSheetMusicDisplay } from 'opensheetmusicdisplay/build/dist/src'

export class SheetMusicPresenter {
  constructor(private readonly store: SheetMusicStore) {
    makeObservable(this)
  }

  @computed get osmdNode(): HTMLElement {
    return this.store.rootNode
  }

  @computed get osmd(): OpenSheetMusicDisplay {
    return this.store.osmd
  }

  @computed get currentSheetMusic(): SheetMusicXMLDocument | undefined {
    if (this.store.state.kind !== 'loaded') {
      return
    }

    return this.store.state.sheet
  }

  @computed get loading(): boolean {
    return this.store.state.kind === 'loading'
  }

  @computed get idle(): boolean {
    return this.store.state.kind === 'idle'
  }
}
