import mobxRemotedev from 'mobx-remotedev'
import {
  HotkeyActionDevice,
  HotkeyActionDeviceName,
  HotkeyManagerStore,
  HotkeyRegistryName,
} from 'modules/hotkey_manager/store'
import { action, makeObservable } from 'mobx'
import { HotkeyRegistry } from 'modules/hotkey_registry'
import { HotkeyAction } from 'modules/hotkey_registry/types'

@mobxRemotedev
export class HotkeyManagerController {
  constructor(private readonly store: HotkeyManagerStore) {
    makeObservable(this)
  }

  @action.bound fireAction(action: HotkeyAction) {
    const entries = Array.from(this.store.hotkeyRegistries.entries())
    entries.forEach(([registryName, registry]) => {
      if (this.store.activeRegistries.get(registryName)) {
        registry.controller.fireAction(action)
      }
    })
  }

  @action.bound addRegistry(registryName: HotkeyRegistryName, registry: HotkeyRegistry) {
    if (this.store.hotkeyRegistries.has(registryName)) {
      throw new Error(`Hotkey Registry "${registryName}" has already been set`)
    }

    this.store.hotkeyRegistries.set(registryName, registry)
  }

  @action.bound addDevice(deviceName: HotkeyActionDeviceName, device: HotkeyActionDevice) {
    if (this.store.hotkeyActionDevices.has(deviceName)) {
      throw new Error(`Hotkey Action Device "${deviceName}" has already been set`)
    }

    this.store.hotkeyActionDevices.set(deviceName, device)
  }

  @action.bound activateRegistry(registryName: HotkeyRegistryName): boolean {
    if (!this.store.hotkeyRegistries.has(registryName)) {
      throw new Error(`Could not activate hotkey registry with name ${registryName} because it doesn't exist`)
    }

    if (this.store.activeRegistries.has(registryName)) {
      return false
    }

    this.store.activeRegistries.set(registryName, true)
    return true
  }

  @action.bound deactivateRegistry(registryName: HotkeyRegistryName): boolean {
    if (!this.store.hotkeyRegistries.has(registryName)) {
      throw new Error(`Could not deactivate hotkey registry with name ${registryName} because it doesn't exist`)
    }

    if (this.store.activeRegistries.has(registryName)) {
      return false
    }

    this.store.activeRegistries.set(registryName, false)
    return true
  }
}
