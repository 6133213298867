import * as React from 'react'
import { observer } from 'mobx-react'
import { RecordingToolStore } from 'components/recording_tool/store'
import { RecordingToolController } from 'components/recording_tool/controller'
import { RecordingToolPresenter } from 'components/recording_tool/presenter'
import { MicrophoneRecorderPresenter } from 'modules/microphone_recorder/presenter'
import { MicrophoneRecorderController } from 'modules/microphone_recorder/controller'
import { SingleRecording } from 'components/single_recording'
import { createHotkeyRegistry } from 'modules/hotkey_registry'
import { Singleton } from 'singleton'
import { RecordingTool } from 'components/recording_tool/recording_tool'
import { MicrophoneRecoderModule } from 'modules/microphone_recorder'

export function createRecordingTool(
  singleton: Singleton,
  modules: { microphoneRecordingModule: MicrophoneRecoderModule },
) {
  const { microphoneRecorderController, microphoneRecorderPresenter } = modules.microphoneRecordingModule
  const recordingToolStore = new RecordingToolStore()
  const recordingToolController = new RecordingToolController(
    recordingToolStore,
    singleton.hotkey.controller,
    microphoneRecorderController,
    microphoneRecorderPresenter,
    singleton.services.uploadService,
  )
  const recordingToolPresenter = new RecordingToolPresenter(recordingToolStore)

  const { hotkeyRegistry } = createHotkeyRegistry()
  recordingToolController.addHotkeyRegistry(hotkeyRegistry)

  const Recordings = observer(() => {
    const recordings = microphoneRecorderPresenter.playableRecordings
    const children = recordings.map((r) => (
      <li key={r.key} className='mt-2'>
        <SingleRecording recording={r} />
      </li>
    ))
    return <ul>{children}</ul>
  })

  const Component = observer(() => (
    <RecordingTool
      isRecording={microphoneRecorderPresenter.isRecording}
      onStartClick={recordingToolController.startRecording}
      onStopClick={recordingToolController.stopRecording}
      onMount={recordingToolController.enableHotkeys}
      onUnmount={recordingToolController.disableHotkeys}
      Recordings={Recordings}
    />
  ))

  return { RecordingTool: Component }
}
