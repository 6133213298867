export function loop(fn: () => void, hz?: number): () => void {
  const minimumElapsedTime = hz == null ? undefined : (1 / hz) * 1000
  let frameId: number
  let lastTick = performance.now()

  const tick = () => {
    frameId = requestAnimationFrame(tick)

    const elapsedTime = performance.now() - lastTick
    const enoughTimeElapsed = elapsedTime > minimumElapsedTime
    const shouldntCheck = minimumElapsedTime == null

    if (enoughTimeElapsed || shouldntCheck) {
      lastTick = performance.now()
      fn()
    }
  }

  const clear = () => {
    cancelAnimationFrame(frameId)
  }

  tick()
  return clear
}
