import { ArrowNarrowDownIcon } from '@heroicons/react/solid'
import { AudioNodeKind } from 'components/audio_node_manager/audio_node_constructor'
import { AudioNodePipeline, PipelineNode } from 'components/audio_node_manager/audio_node_pipeline'
import { AudioNodeSection } from 'components/audio_node_manager/audio_node_section'
import { TextButton } from 'components/base/buttons'
import { Select } from 'components/base/selects'
import { observer } from 'mobx-react'
import * as React from 'react'

export const PipelineSection = observer(
  ({
    pipeline,
    addNode,
    createNode,
  }: {
    pipeline: AudioNodePipeline | undefined
    addNode(node: PipelineNode): void
    createNode(t: AudioNodeKind): AudioNode
  }) => {
    if (pipeline == null) {
      return null
    }

    const nodes = pipeline.nodes.map(([type, node], index) => (
      <div key={index}>
        <AudioNodeSection
          node={node}
          type={type}
        />
        <ArrowNarrowDownIcon className='w-5 h-5' />
      </div>
    ))

    return (
      <div className='w-full p-4 bg-gray-100'>
        <div>
          <div className='w-full p-4 bg-white shadow-inner'>Input</div>
          <ArrowNarrowDownIcon className='w-5 h-5' />
          {nodes}
          <div className='w-full p-4 bg-white shadow-inner'>Output</div>
        </div>
        <CreateAudioNode
          addNode={addNode}
          createNode={createNode}
        />
      </div>
    )
  },
)

const CreateAudioNode = ({
  addNode,
  createNode,
}: {
  addNode(node: PipelineNode): void
  createNode(t: AudioNodeKind): AudioNode
}) => {
  const options = [
    { key: 'Gain', value: AudioNodeKind.GAIN },
    { key: 'Biquad Filter', value: AudioNodeKind.BIQUAD_FILTER },
    { key: 'Delay', value: AudioNodeKind.DELAY },
    { key: 'Oscillator', value: AudioNodeKind.OSCILLATOR },
    { key: 'Dynamics Compressor', value: AudioNodeKind.DYNAMICS_COMPRESSOR },
  ]
  const [nodeKind, setNodeKind] = React.useState(options[0].value)

  const newNode = () => {
    console.log(nodeKind)
    const node = createNode(nodeKind)
    addNode([nodeKind, node])
  }

  const selectNodeKind = React.useCallback((option) => {
    setNodeKind(option.value)
    console.log(option.value)
  }, [])

  return (
    <div>
      <Select
        options={options}
        onChange={selectNodeKind}
        defaultOption={options[0]}
      />
      <TextButton onClick={newNode}>Create Node</TextButton>
    </div>
  )
}
