import { MicrophoneDeviceStore } from 'modules/microphone_device/store'
import { MicrophoneDeviceController } from 'modules/microphone_device/controller'
import { MicrophoneDevicePresenter } from 'modules/microphone_device/presenter'
import { AudioNodeManagerController } from 'components/audio_node_manager/controller'

export function createMicrophoneDevice(audioNodeManagerController: AudioNodeManagerController) {
  const microphoneDeviceStore = new MicrophoneDeviceStore()
  const microphoneDeviceController = new MicrophoneDeviceController(microphoneDeviceStore, audioNodeManagerController)
  const microphoneDevicePresenter = new MicrophoneDevicePresenter(microphoneDeviceStore)

  return {
    microphoneDeviceController,
    microphoneDevicePresenter,
  }
}

export type MicrophoneDeviceModule = {
  microphoneDeviceController: MicrophoneDeviceController
  microphoneDevicePresenter: MicrophoneDevicePresenter
}
