import * as React from 'react'
import { InternalLayoutProps } from 'components/layout'
import './desktop.css'

export const DesktopLayout = (p: InternalLayoutProps) => {
  const { Visualisations } = p
  return (
    <div
      id='DesktopLayout'
      style={{ height: 'calc(100vh - 4.5rem - 2px)' }}
      className='grid'
    >
      <div
        id='DesktopLayout__VisualisationContainer'
        style={{ gridArea: 'a' }}
        className='overflow-y-scroll'
      >
        <Visualisations />
      </div>
      <div
        id='DesktopLayout__ToolsContainer'
        style={{ gridArea: 'b' }}
        className='overflow-scroll'
      >
        <Tools Tools={p.Tools} />
      </div>
      <div
        id='DesktopLayout__InstrumentsContainer'
        style={{ gridArea: 'c' }}
      >
        <Instruments Elements={p.Instruments} />
      </div>
    </div>
  )
}

const SectionTitle = ({ id, children }: { id: string; children: React.ReactNode }) => (
  <div
    id={id}
    className='absolute top-0 left-0 z-10 p-1 text-xs bg-gray-200'
  >
    {children}
  </div>
)

const Tools = ({ Tools }: { Tools: React.FunctionComponent[] }) => {
  return (
    <div
      id='DesktopLayout__Tools'
      style={{ flex: '250px 0 0' }}
      className='relative w-full h-full p-4 pt-8 border-l-2 border-gray-200 border-solid'
    >
      <SectionTitle id='Tools__Title'>Tools</SectionTitle>
      <div id='Tools__Body'>
        {Tools.map((Tool, i) => (
          <div
            key={i}
            className='py-4 first:mt-0 last:mb-0 first:pt-0'
          >
            <Tool />
          </div>
        ))}
      </div>
    </div>
  )
}

const Instruments = ({ Elements }: { Elements: React.FunctionComponent[] }) => {
  return (
    <div
      id='DesktopLayout__Instruments'
      className='relative w-full h-full'
    >
      <SectionTitle id='Instruments__Title'>Instruments</SectionTitle>
      {Elements.map((Element, i) => (
        <Element key={i} />
      ))}
    </div>
  )
}
