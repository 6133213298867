import * as React from 'react'
import { SolidUserIcon } from 'components/header_auth_form/mobile_icon'
import { InvisibleButton } from 'components/base/buttons'

export const MobileModal = ({ children }: { children: React.ReactNode }) => {
  const [visible, setVisible] = React.useState<boolean>(false)
  const Icon = SolidUserIcon
  const baseClass =
    'absolute z-10 p-2 bg-white border-2 border-gray-100 border-solid shadow-md right-0 rounded-md transition-opacity'
  const visibleClass = 'opacity-100 pointer-events-all visible'
  const hiddenClass = 'opacity-0 pointer-events-none invisible'
  const classes = [baseClass, !visible && hiddenClass, visible && visibleClass].filter((x) => !!x).join(' ')

  return (
    <div className='relative'>
      <InvisibleButton
        onClick={() => setVisible(!visible)}
        className=''
      >
        <Icon />
      </InvisibleButton>
      <div className={classes}>{children}</div>
    </div>
  )
}
