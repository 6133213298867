import { Recording } from 'modules/recording/recording'
import { HttpService } from './http_service'

export class UploadService {
  serviceUrl: string = '/upload'

  constructor(private readonly httpService: HttpService) {}

  // function that creates a presigned upload url, then uploads the recording to the presigned url using a formdata object
  public async uploadRecording(recording: Recording): Promise<Response | null> {
    console.log('UploadService.uploadRecording')
    try {
      const fileName = `${recording.key}.webm`
      const saveRecordingUrl = `${this.serviceUrl}/recording`
      const uploadRecordingUrl = await this.createRecordingUploadUrl(recording.key)
      await this.httpService.put({ url: uploadRecordingUrl, body: recording.blob })
      const body = {
        title: 'default title',
        description: 'default description',
        key: recording.key,
        filename: fileName,
        duration: recording.duration,
        size: recording.size,
        tags: ['voice', 'recording'],
      }
      const response = await this.httpService.post({ path: saveRecordingUrl, body })
      const { recordingId } = await response.json()
      console.log('uploadRecording', recordingId)
      return recordingId
    } catch (e) {
      console.error(`UploadService.uploadRecording: Error uploading recording with error ${e}}`)
      return null
    }
  }

  private async createRecordingUploadUrl(key: string): Promise<string> {
    try {
      const response = await this.httpService.get({ path: `${this.serviceUrl}/recording?key=${key}` })
      const { signedUrl } = await response.json()
      if (signedUrl == null) {
        throw new Error('No signed url returned')
      }
      return signedUrl
    } catch (e) {
      console.error(
        `UploadService.createRecordingUploadUrl: Error creating upload url for key: ${key} with error ${e}}`,
      )
      throw e
    }
  }
}
