import mobxRemotedev from 'mobx-remotedev'
import { HotkeyManagerStore, HotkeyRegistryName } from 'modules/hotkey_manager/store'
import { computed, makeObservable } from 'mobx'
import { HotkeyRegistry } from 'modules/hotkey_registry'

@mobxRemotedev
export class HotkeyManagerPresenter {
  constructor(private readonly store: HotkeyManagerStore) {
    makeObservable(this)
  }

  @computed get registries(): [HotkeyRegistryName, HotkeyRegistry][] {
    return Array.from(this.store.hotkeyRegistries.entries())
  }
}
