import mobxRemotedev from 'mobx-remotedev'
import { action, makeObservable } from 'mobx'
import { MicrophoneRecorderStore } from './store'
import { MicrophoneDeviceController } from 'modules/microphone_device/controller'
import { MicrophoneDevicePresenter } from 'modules/microphone_device/presenter'
import { createRecording } from 'modules/recording'
import { Recording } from 'modules/recording/recording'
import { bound } from 'utilities/bound'

@mobxRemotedev
export class MicrophoneRecorderController {
  private unsubscribeMicrophone: () => void | undefined

  constructor(
    private readonly store: MicrophoneRecorderStore,
    private readonly microphoneDeviceController: MicrophoneDeviceController,
    private readonly microphoneDevicePresenter: MicrophoneDevicePresenter,
  ) {
    makeObservable(this)
  }

  @bound public async start() {
    try {
      if (this.store.state.kind !== 'connected') {
        await this.connect()
      }
      if (this.store.state.kind !== 'connected') {
        throw new Error('Connecting to MicrophoneDevice failed')
      }

      const { mediaRecorder } = this.store.state
      const { recording } = createRecording()

      recording.setRecording()
      mediaRecorder.ondataavailable = recording.addData
      this.setRecording(mediaRecorder, recording)

      mediaRecorder.start()
    } catch (e) {
      console.error(e)
      this.setFailed()
    }
  }

  @bound public async stop(): Promise<Recording> {
    try {
      return new Promise<Recording>((resolve, reject) => {
        if (this.store.state.kind !== 'recording') {
          return reject('Tried to record when MicrophoneDevice is not connected')
        }

        const { mediaRecorder, recording } = this.store.state
        mediaRecorder.onstop = () => {
          recording.setRecorded()
          this.saveRecording(recording)
          this.disconnect()
          resolve(recording)
        }
        mediaRecorder.onerror = (e) => reject(e)
        mediaRecorder.stop()
      })
    } catch (e) {
      console.error(e)
      this.setFailed()
    }
  }

  private async connect() {
    try {
      this.unsubscribeMicrophone = await this.microphoneDeviceController.subscribe()
      const { audioContext, audioDevice } = this.microphoneDevicePresenter.connection

      const source = audioContext.createMediaStreamSource(audioDevice)
      const destination = audioContext.createMediaStreamDestination()
      const gainNode = audioContext.createGain()
      gainNode.gain.value = 1 // TODO put a volume slider here
      source.connect(gainNode)
      gainNode.connect(destination)

      // supported types
      // https://source.chromium.org/chromium/chromium/src/+/master:third_party/blink/web_tests/fast/mediarecorder/MediaRecorder-isTypeSupported.html?q=MediaRecorder-isTypeSupported&ss=chromium
      const options = { mimeType: 'audio/webm;codecs=opus' }
      const recorder = new MediaRecorder(destination.stream, options)

      this.setRecorder(recorder)
    } catch (e) {
      this.setFailed()
    }
  }

  private disconnect() {
    if (this.store.state.kind !== 'recording') {
      throw new Error('Tried to disconnect when there is no recording')
    }
    try {
      const { mediaRecorder } = this.store.state
      mediaRecorder.onstop = undefined
      mediaRecorder.ondataavailable = undefined
      this.unsubscribeMicrophone()
      this.setInit()
    } catch (e) {
      this.setFailed()
    }
  }

  @action private setRecording(mediaRecorder: MediaRecorder, recording: Recording) {
    this.store.state = { kind: 'recording', mediaRecorder, recording }
  }

  @action private saveRecording(recording: Recording) {
    this.store.recordings.unshift(recording)
  }

  @action private setInit() {
    this.store.state = { kind: 'init' }
  }

  @action private setFailed() {
    this.store.state = { kind: 'failed' }
  }

  @action private setRecorder(mediaRecorder: MediaRecorder) {
    this.store.state = { kind: 'connected', mediaRecorder }
  }
}
