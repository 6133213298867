import * as Tone from 'tone'
import { AcousticGuitarSamplePaths } from './sample_paths'
import { publicPath } from 'global_env'

export const AcousticGuitar = new Tone.Sampler({
  //baseUrl: `${publicPath}assets/instrument_samples/`,
  baseUrl: `https://sounds.lamusi.de/`,
  urls: AcousticGuitarSamplePaths,
}).toDestination()

AcousticGuitar.volume.value = -12
