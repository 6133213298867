import mobxRemotedev from 'mobx-remotedev'
import { MicrophoneInputStore } from './store'
import { computed, makeObservable } from 'mobx'

@mobxRemotedev
export class MicrophoneInputPresenter {
  constructor(private readonly store: MicrophoneInputStore) {
    makeObservable(this)
  }

  @computed get pitch(): number {
    return this.store.pitch
  }

  @computed get pitchHistory(): number[] {
    return this.store.pitchData
  }

  @computed get isRecording(): boolean {
    return this.store.state.kind === 'connected'
  }
}
