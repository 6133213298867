import * as React from "react"
import { observer } from "mobx-react"
import { SoundExercisePlayerStore } from "components/sound-exercise-player/store"
import { Player, SoundExercisePlayerController } from 'components/sound-exercise-player/controller'
import { SoundExercisePlayerPresenter } from "components/sound-exercise-player/presenter"
import { Select } from 'components/base/selects'
import { PrimaryButton } from 'components/base/buttons'
import { NumberInput } from 'components/base/inputs'

export function createSoundExercisePlayer() {
  const soundExercisePlayerStore = new SoundExercisePlayerStore()
  const player = new Player()
  const soundExercisePlayerController = new SoundExercisePlayerController(soundExercisePlayerStore, player)
  const soundExercisePlayerPresenter = new SoundExercisePlayerPresenter(soundExercisePlayerStore)

  const baseNote = ['C', 'D', 'E', 'F', 'G', 'A', 'B'].map((n) => ({ key: n, value: n }))
  const scaleType = [
    'major',
    'harmonic minor',
    'gypsy',
    'dorian',
    'phrygian',
    'lydian',
    'mixolydian',
    'syntonolydian',
    'ionian',
  ].map((n) => ({ key: n, value: n }))

  const SoundExercisePlayer = observer(() => {
    return (
      <div>
        <div>
          <label>Octave</label>
          <NumberInput value={soundExercisePlayerStore.octave} onChange={soundExercisePlayerController.setOctave} />
        </div>
        <div>
          <label>Octaves</label>
          <NumberInput value={soundExercisePlayerStore.octaves} onChange={soundExercisePlayerController.setOctaves} />
        </div>
        <div>
          <label>Reverse?</label>
          <input
            type='checkbox'
            checked={soundExercisePlayerStore.reverse}
            onChange={soundExercisePlayerController.setReverse}
          />
        </div>
        <Select
          label='Base Note'
          defaultOption={baseNote[0]}
          options={baseNote}
          onChange={soundExercisePlayerController.setBaseNote}
        />
        <Select
          label='Scale Type'
          defaultOption={scaleType[0]}
          options={scaleType}
          onChange={soundExercisePlayerController.setScaleType}
        />
        <PrimaryButton onClick={soundExercisePlayerController.play}>Play</PrimaryButton>
      </div>
    )
  })

  return { SoundExercisePlayer }
}
