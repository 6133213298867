import mobxRemotedev from 'mobx-remotedev'
import { LayoutStore, DeviceKind, OrientationKind } from 'components/layout/store'
import { makeObservable, action } from 'mobx'

@mobxRemotedev
export class LayoutController {
  constructor(private readonly store: LayoutStore) {
    makeObservable(this)
    this.bindResizeHandler()
    this.resize(window.innerWidth, window.innerHeight)
  }

  private bindResizeHandler() {
    window.addEventListener('resize', () => this.resize(window.innerWidth, window.innerHeight))
  }

  // copying the bootstrap breakpoints
  @action private resize(width: number, height: number) {
    let device: DeviceKind
    let orientation: OrientationKind

    if (width > height) {
      orientation = OrientationKind.LANDSCAPE
    } else {
      orientation = OrientationKind.PORTRAIT
    }

    if (width <= 525) {
      device = DeviceKind.MOBILE
    } else if (width <= 768) {
      device = DeviceKind.TABLET
    } else {
      device = DeviceKind.DESKTOP
    }

    this.store.layout = { device, orientation }
  }
}
