import { makeObservable, observable, IObservableArray } from 'mobx'
import mobxRemotedev from 'mobx-remotedev'

const INITIAL_SEARCH_ITEMS = [
  {
    url: '/assets/sheet_music/Hanon_Condensed_Exercises_Nos_1-30.xml',
    name: 'Hanon Virtuoso Piano Exercises 1-30',
  },
]

@mobxRemotedev
export class SheetMusicSelectorStore {
  constructor() {
    makeObservable(this)
  }

  sheetMusic: IObservableArray<SearchItem> = observable.array(INITIAL_SEARCH_ITEMS, { deep: false })

  @observable.ref selectedSheetMusic: SearchItem | undefined
}

export type SearchItem = {
  name: string
  url: string
}
