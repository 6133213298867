import { observable, makeObservable, IObservableArray } from 'mobx'
import { OpenSheetMusicDisplay } from 'opensheetmusicdisplay'

export class SheetMusicStore {
  constructor() {
    makeObservable(this)
  }

  @observable.ref rootNode: HTMLElement | undefined
  @observable.ref osmd: OpenSheetMusicDisplay

  @observable.ref state: SheetMusicState = {
    kind: 'idle',
  }

  sheets: IObservableArray<[]> = observable.array([], { deep: false })
}

export type SheetMusicState = { kind: 'idle' } | { kind: 'loading' } | { kind: 'loaded'; sheet: SheetMusicXMLDocument }

export type SheetMusicXMLDocument = {
  kind: 'xml'
  name: string
  url: string
  doc: Document
}
