import mobxRemotedev from 'mobx-remotedev'
import { HomeStore } from 'pages/home/store'
import { computed, makeObservable } from 'mobx'

@mobxRemotedev
export class HomePresenter {
  constructor(private readonly store: HomeStore) {
    makeObservable(this)
  }

  @computed get noop(): undefined {
    return undefined
  }
}
