import React from 'react'
import { action, makeObservable, observable } from 'mobx'
import { Woodblock } from 'instruments/woodblock'
import { AcousticGuitar } from 'instruments/guitar-acoustic'

export type TimeSignature = {
  label: string
  beatsPerMeasure: number
  beatType: number
}

export type GuitarStrum = {
  kind?: 'd' | 'u' | 'm'
  beat: number
  beatFraction: number
}

export type GuitarStrumSequence = GuitarStrum[]

export type GuitarStrumPattern = {
  label: string
  strumSequence: GuitarStrumSequence
}

export class GuitarStrumStore {
  @observable bpm: number = 60
  @observable percentage: number = 0
  @observable strumPattern: GuitarStrumPattern
  @observable timeSignature: TimeSignature
  @observable strumSequence: GuitarStrumSequence

  audioContext: AudioContext
  nextNoteTime: number
  schedulerId: number
  startTime: number
  noteSequence: GuitarStrumSequence

  constructor() {
    makeObservable(this)
  }
}

export class GuitarStrumController {
  constructor(
    private readonly store: GuitarStrumStore,
    timeSignature: TimeSignature,
    strumPattern: GuitarStrumPattern,
  ) {
    makeObservable(this)
    this.init(timeSignature, strumPattern)
  }

  @action.bound
  private init(timeSignature: TimeSignature, strumPattern: GuitarStrumPattern) {
    this.store.timeSignature = timeSignature
    this.store.strumPattern = strumPattern
    this.store.audioContext = new AudioContext()
    this.store.nextNoteTime = this.store.audioContext.currentTime
    this.store.noteSequence = []

    this.setupStrumSequence(strumPattern)
  }

  @action.bound
  private setupStrumSequence(strumPattern: GuitarStrumPattern) {
    const beats = 4
    const beatType = 4
    const totalBeatFractions = beats * beatType
    const beatFractionIncrement = 1 / beats

    const strumSequence: GuitarStrumSequence = Array.from({ length: totalBeatFractions }).map((_, i) => {
      const beatFraction = (i * beatFractionIncrement) % 1
      const currentFraction = i + 1
      const beat = Math.ceil(currentFraction / beats)
      const kind = strumPattern.strumSequence.find(
        (strum) => strum.beat === beat && strum.beatFraction === beatFraction,
      )?.kind

      return { beat, beatFraction, kind }
    })

    this.store.strumSequence = strumSequence
  }

  @action.bound
  public start() {
    const { strumSequence, audioContext } = this.store
    this.store.noteSequence = [...strumSequence].sort((a, b) => a.beat - b.beat || a.beatFraction - b.beatFraction)
    this.store.startTime = audioContext.currentTime
    this.store.nextNoteTime = audioContext.currentTime
    this.scheduleNote()
    this.store.schedulerId = requestAnimationFrame(this.scheduler)
  }

  @action.bound
  private scheduler() {
    const { audioContext, nextNoteTime } = this.store
    if (nextNoteTime < audioContext.currentTime) {
      this.scheduleNote()
    }
    this.updateProgressPercentage()
    this.store.schedulerId = requestAnimationFrame(this.scheduler)
  }

  @action.bound
  private scheduleNote() {
    const { bpm, timeSignature, noteSequence } = this.store
    // play the current strum, and move it to the end of the queue
    const currentStrum = noteSequence.shift()
    noteSequence.push(currentStrum)
    this.play(currentStrum)
    // advance the next note time to the next beat fraction
    const beatFractionLength = 60.0 / bpm / timeSignature.beatType
    this.store.nextNoteTime += beatFractionLength
  }

  @action.bound
  private resetProgressPercentage() {
    this.store.percentage = 0
  }

  @action.bound
  private updateProgressPercentage() {
    const secondsPerBeat = 60.0 / this.store.bpm
    const secondsPerMeasure = secondsPerBeat * this.store.timeSignature.beatsPerMeasure
    const elapsedSeconds = this.store.audioContext.currentTime - this.store.startTime
    const measureSecondsElapsed = elapsedSeconds % secondsPerMeasure
    const progressPercentage = (measureSecondsElapsed / secondsPerMeasure) * 100
    this.store.percentage = progressPercentage
  }

  @action.bound
  public stop() {
    const { audioContext, schedulerId } = this.store
    cancelAnimationFrame(schedulerId)
    this.store.nextNoteTime = audioContext.currentTime
    this.resetProgressPercentage()
  }

  @action.bound
  private play(strumData: GuitarStrum) {
    const { audioContext } = this.store
    const noteDuration = '4n'
    const strumDelay = 0.035
    const time = audioContext.currentTime
    const velocity = 0.5

    if (strumData.kind)
      switch (strumData.kind) {
        case 'd':
          this.playDownStrum(time, strumDelay, noteDuration, velocity)
          break
        case 'u':
          this.playUpStrum(time, strumDelay, noteDuration, velocity)
          break
        case 'm':
          this.playMutedStrum(time, strumDelay)
          break
        case undefined:
          break
        default:
          console.error('Unknown strum type', strumData)
      }
  }

  private playUpStrum(time: number, strumDelay: number, noteDuration: string, velocity: number) {
    //const upTuning = ['A3', 'E3', 'C3', 'G3']
    const upTuning = ['E3', 'B3', 'G3', 'D3', 'A2', 'E2']
    upTuning.forEach((note, index) => {
      const delay = index * strumDelay
      AcousticGuitar.triggerAttackRelease(note, noteDuration, time + delay, velocity)
    })
  }

  private playDownStrum(time: number, strumDelay: number, noteDuration: string, velocity: number) {
    // const downTuning = ['G3', 'C3', 'E3', 'A3']
    const downTuning = ['E2', 'A2', 'D3', 'G3', 'B3', 'E3']
    downTuning.forEach((note, index) => {
      const delay = index * strumDelay
      AcousticGuitar.triggerAttackRelease(note, noteDuration, time + delay, velocity)
    })
  }

  private playMutedStrum(time: number, strumDelay: number) {
    Woodblock.start(time, 0, time + strumDelay)
  }

  @action.bound
  public setBpm(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value === '') {
      // @ts-ignore
      this.bpm = ''
      return
    }
    const bpm = parseInt(e.target.value)
    this.store.bpm = bpm
  }

  @action.bound
  public setTimeSignature(timeSignature: TimeSignature) {
    this.store.timeSignature = timeSignature
  }

  @action.bound
  public setStrumPattern(strumPattern: GuitarStrumPattern) {
    this.store.strumPattern = strumPattern
  }
}
