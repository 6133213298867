import { HttpService } from './http_service'

export type UploadedRecording = {
  id: string
  pk: string
  createdAt: string
  updatedAt: string
  description: string
  duration: number
  filename: string
  key: string
  size: number
  tags: string[]
  title: string
}

export class RecordingService {
  serviceUrl: string = '/recording'

  constructor(private readonly httpService: HttpService) {}

  async downloadRecording(recordingId: string): Promise<Blob> {
    const path = `${this.serviceUrl}/${recordingId}/download`
    const response = await this.httpService.get({ path })
    const { url } = await response.json()
    const download = await fetch(url)
    const blob = await download.blob()
    return blob
  }

  async listRecordings(): Promise<UploadedRecording[]> {
    const path = `${this.serviceUrl}/list`
    const response = await this.httpService.get({ path })
    const { recordings } = await response.json()
    return recordings
  }
}
