import mobxRemotedev from 'mobx-remotedev'
import { QwertyActionsStore } from 'modules/qwerty_actions/store'
import { action, makeObservable } from 'mobx'
import { HotkeyAction } from 'modules/hotkey_registry/types'

@mobxRemotedev
export class QwertyActionsController {
  constructor(private readonly store: QwertyActionsStore) {
    makeObservable(this)
  }

  @action.bound start() {
    this.store.on = true
    document.addEventListener('keydown', this.keydown)
    document.addEventListener('keyup', this.keyup)
  }

  @action.bound stop() {
    this.store.on = false
    document.removeEventListener('keydown', this.keydown)
    document.removeEventListener('keyup', this.keyup)
  }

  @action.bound addEventListener(fn: (action: HotkeyAction) => void) {
    this.store.subscribers.add(fn)
  }

  @action.bound removeEventListener(fn: (action: HotkeyAction) => void) {
    this.store.subscribers.delete(fn)
  }

  @action.bound private keydown(e: KeyboardEvent) {
    if (this.keyIsDown(e.key)) {
      return
    }

    this.store.keys.add(e.key)
    const action = QwertyKeyActionMapper.map(this.keysPressed)
    if (action == null) {
      return
    }

    const listeners = Array.from(this.store.subscribers.values())
    listeners.forEach((listener) => listener(action))
  }

  @action.bound private keyup(e: KeyboardEvent) {
    this.store.keys.delete(e.key)
  }

  private get keysPressed(): KeyboardKey[] {
    return Array.from(this.store.keys.values())
  }

  private keyIsDown(key: KeyboardKey) {
    return this.store.keys.has(key)
  }
}

namespace QwertyKeyActionMapper {
  export function map(keysToMap: string[]) {
    const key = keysToMap.join(' ').toLowerCase()
    const action = keysToActions.get(key)
    return action
  }

  const keysToActions = new Map([
    ['control c', HotkeyAction.COPY],
    ['control v', HotkeyAction.PASTE],
    ['?', HotkeyAction.HELP],
    ['shift ?', HotkeyAction.HELP],

    // TODO remove this from this map, see TODO in types for hotkey registry
    ['space', HotkeyAction.RECORD],
    [' ', HotkeyAction.RECORD],
  ])
}

type KeyboardKey = string
