import mobxRemotedev from 'mobx-remotedev'
import { HotkeyRegistryStore } from './store'
import { computed, makeObservable } from 'mobx'

@mobxRemotedev
export class HotkeyRegistryPresenter {
  constructor(private readonly store: HotkeyRegistryStore) {
    makeObservable(this)
  }

  @computed get noop(): undefined {
    return undefined
  }
}
