import mobxRemotedev from 'mobx-remotedev'
import { RecordingListStore } from "components/recording-list/store"
import { computed, makeObservable } from "mobx"

@mobxRemotedev export class RecordingListPresenter {
  constructor(private readonly store: RecordingListStore) {
    makeObservable(this)
  }

  @computed get noop(): undefined {
    return undefined
  }
}
