import { AppStore } from 'app/store'
import { AppController } from 'app/controller'
import { AppPresenter } from 'app/presenter'

export function createAppStore() {
  const appStore = new AppStore()
  const appController = new AppController(appStore)
  const appPresenter = new AppPresenter(appStore)

  return { appStore, appController, appPresenter }
}
