import mobxRemotedev from "mobx-remotedev"
import { makeObservable, observable } from "mobx"
import { UploadedRecording } from 'services/recording_service'
import { Recording } from 'modules/recording/recording'

@mobxRemotedev
export class RecordingListStore {
  constructor() {
    makeObservable(this)
  }

  @observable.ref uploadedRecordings: UploadedRecording[] = observable.array([])

  @observable.ref recordings: Recording[] = observable.array([])
}
