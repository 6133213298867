import { RecordingToolStore } from 'components/recording_tool/store'
// import { makeObservable } from 'mobx'
import { HotkeyRegistryName } from 'modules/hotkey_manager/store'
import { HotkeyManagerController } from 'modules/hotkey_manager/controller'
import { bound } from 'utilities/bound'
import { MicrophoneRecorderController } from 'modules/microphone_recorder/controller'
import { MicrophoneRecorderPresenter } from 'modules/microphone_recorder/presenter'
import { HotkeyRegistry } from 'modules/hotkey_registry'
import { HotkeyAction } from 'modules/hotkey_registry/types'
import { UploadService } from 'services/upload_service'

export class RecordingToolController {
  constructor(
    private readonly store: RecordingToolStore,
    private readonly hotkeyController: HotkeyManagerController,
    private readonly microphoneRecorderController: MicrophoneRecorderController,
    private readonly microphoneRecorderPresenter: MicrophoneRecorderPresenter,
    private readonly uploadService: UploadService,
  ) {
    //makeObservable(this)
  }

  @bound public addHotkeyRegistry(hotkeyRegistry: HotkeyRegistry) {
    this.hotkeyController.addRegistry(HotkeyRegistryName.RECORDING_TOOL, hotkeyRegistry)

    hotkeyRegistry.controller.addEventListener([HotkeyAction.RECORD], this.recordActionListener)
  }

  @bound public enableHotkeys() {
    this.hotkeyController.activateRegistry(HotkeyRegistryName.RECORDING_TOOL)
  }

  @bound public disableHotkeys() {
    this.hotkeyController.deactivateRegistry(HotkeyRegistryName.RECORDING_TOOL)
  }

  @bound public recordActionListener() {
    const { isRecording } = this.microphoneRecorderPresenter
    isRecording ? this.stopRecording() : this.startRecording()
  }

  @bound public startRecording() {
    this.microphoneRecorderController.start()
  }

  @bound public async stopRecording() {
    const recording = await this.microphoneRecorderController.stop().catch((e) => {
      console.error(e)
      return null
    })

    if (recording == null) {
      return
    }

    const upload = await this.uploadService.uploadRecording(recording)
    console.log(upload)
  }
}
