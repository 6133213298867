import * as React from 'react'

export class PitchCanvas extends React.Component<{
  accuracy: number
  data: number[]
  note: string
  octave: number
}> {
  private height = 500
  private readonly ref: React.RefObject<HTMLCanvasElement> = React.createRef()
  private readonly lowestFrequency = 0
  private readonly highestFrequency = 1000

  componentDidMount() {
    this.height = this.canvas.clientHeight
    this.canvas.width = this.canvas.clientWidth
    this.canvas.height = this.canvas.clientHeight
    this.redraw()
  }

  render() {
    if (this.ref.current != null) {
      // update and redraw
      this.redraw()
    }

    return (
      <canvas
        ref={this.ref}
        className='w-full h-full'
      ></canvas>
    )
  }

  private redraw() {
    if (this.canvas.height !== this.height) {
      this.canvas.height = this.height
      return
    }

    this.ctx.clearRect(0, 0, 10000, 10000)
    this.ctx.lineWidth = 1
    this.ctx.strokeStyle = 'orange'

    this.ctx.beginPath()
    this.props.data.forEach((y, x) => {
      const adjustedY = Math.min(Math.max(y, this.lowestFrequency), this.highestFrequency)
      const xPosition = (this.canvas.width / this.props.data.length) * x
      const yPosition = this.height - (adjustedY / this.highestFrequency) * this.height
      if (x === 0) {
        this.ctx.moveTo(xPosition, yPosition)
      } else {
        this.ctx.lineTo(xPosition, yPosition)
      }
    })
    this.ctx.stroke()
  }

  private get ctx(): CanvasRenderingContext2D {
    return this.canvas.getContext('2d')
  }

  private get canvas(): HTMLCanvasElement {
    if (this.ref.current == null) {
      throw new Error('canvas hasnt been mounted yet')
    }
    return this.ref.current
  }
}
