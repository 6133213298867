import { AudioNodeKind } from 'components/audio_node_manager/audio_node_constructor'
import { action, makeObservable, observable } from 'mobx'
import { bound } from 'utilities/bound'

export type PipelineNode = [AudioNodeKind, AudioNode]

export class AudioNodePipeline {
  @observable nodes: PipelineNode[] = observable.array([], { deep: false })

  constructor(private readonly source: AudioNode, private readonly destination: AudioNode) {
    makeObservable(this)
    this.connect()
  }

  @bound public connect() {
    if (this.nodes.length === 0) {
      this.source.connect(this.destination)
      return
    }

    if (this.nodes.length === 1) {
      const node = this.nodes[0][1]
      this.source.connect(node)
      node.connect(this.destination)
      return
    }

    const firstNode = this.nodes[0][1]
    const lastNode = this.nodes[this.nodes.length - 1][1]

    for (let i = 1; i < this.nodes.length; i++) {
      const lastNode = this.nodes[i - 1][1]
      const currentNode = this.nodes[i][1]
      lastNode.connect(currentNode)
    }

    this.source.connect(firstNode)
    lastNode.connect(this.destination)
  }

  @bound public disconnect() {
    this.source.disconnect()
    this.nodes.forEach(([_, n]) => n.disconnect())
    this.destination.disconnect()
  }

  @action.bound public add(node: PipelineNode) {
    this.disconnect()
    this.nodes.push(node)
    this.connect()
  }

  @action.bound public remove(node: PipelineNode) {
    const index = this.nodes.findIndex((n) => n === node)
    if (index > -1) {
      this.disconnect()
      this.nodes.splice(index, 1)
      this.connect()
    }
  }

  @action.bound public reposition(node: PipelineNode, pos: number) {
    const index = this.nodes.findIndex((n) => n === node)
    if (index === -1) {
      return
    }

    let newIndex = Math.max(0, Math.min(pos, this.nodes.length - 1))
    if (newIndex === index) {
      return
    }

    // index or newIndex have to change based on the insertion/delete order
    // i chose newIndex for no particular reason
    // we delete index, so if index affects newIndex's position we need to decrement newIndex
    // if index comes after newIndex then newIndex doesnt change
    if (newIndex > index) {
      newIndex--
    }

    this.disconnect()
    this.nodes.splice(index, 1)
    this.nodes.splice(newIndex, 0, node)
    this.connect()
  }

  @bound public moveUp(node: PipelineNode) {
    const index = this.nodes.findIndex((n) => n === node)
    if (index === -1) {
      return
    }

    this.reposition(node, index - 1)
  }

  @bound public moveDown(node: PipelineNode) {
    const index = this.nodes.findIndex((n) => n === node)
    if (index === -1) {
      return
    }

    this.reposition(node, index + 1)
  }
}
