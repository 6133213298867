import { AuthStateKind, AuthStore } from 'components/auth/store'
import { computed, makeObservable } from 'mobx'

export class AuthPresenter {
  constructor(private readonly store: AuthStore) {
    makeObservable(this)
  }

  @computed get isSignedIn(): boolean {
    return this.store.state.kind === AuthStateKind.SIGNED_IN
  }

  @computed get token(): null | string {
    if (this.store.state.kind !== AuthStateKind.SIGNED_IN) {
      return null
    }
    return this.store.state.data.token
  }

  @computed get error(): null | string {
    if (this.store.state.kind !== AuthStateKind.FAILED) {
      return null
    }
    return this.store.state.error
  }

  @computed get email(): string {
    if (this.store.state.kind === AuthStateKind.SIGNED_IN) {
      return ''
    }

    return this.store.state.data.email
  }

  @computed get password(): string {
    if (this.store.state.kind === AuthStateKind.SIGNED_IN) {
      return ''
    }

    return this.store.state.data.password
  }
}
