export const enum AudioNodeKind {
  ANALYSER = 'analyser',
  BIQUAD_FILTER = 'biquad_filter',
  OSCILLATOR = 'oscillator',
  PANNER = 'panner',
  CONVOLVER = 'convolver',
  DELAY = 'delay',
  DYNAMICS_COMPRESSOR = 'dynamics_compressor',
  GAIN = 'gain',
  IIR_FILTER = 'iir_filter',
}

export class AudioNodeFactory {
  constructor(private readonly audioContext: AudioContext) {}

  createNode(type: AudioNodeKind, args?: IIRFilterArgs | DelayArgs): AudioNode {
    switch (type) {
      case AudioNodeKind.ANALYSER:
        return this.audioContext.createAnalyser()
      case AudioNodeKind.BIQUAD_FILTER:
        return this.audioContext.createBiquadFilter()
      case AudioNodeKind.OSCILLATOR:
        return this.audioContext.createOscillator()
      case AudioNodeKind.PANNER:
        return this.audioContext.createPanner()
      case AudioNodeKind.CONVOLVER:
        return this.audioContext.createConvolver()
      case AudioNodeKind.DELAY:
        const maxDelayTime = guardDelayArgs(args) ? args.maxDelayTime : 179
        return this.audioContext.createDelay(maxDelayTime)
      case AudioNodeKind.DYNAMICS_COMPRESSOR:
        return this.audioContext.createDynamicsCompressor()
      case AudioNodeKind.GAIN:
        return this.audioContext.createGain()
      case AudioNodeKind.IIR_FILTER:
        const defaults = { feedback: undefined, feedforward: undefined }
        const { feedback, feedforward } = guardIIRFilterArgs(args) ? args : defaults
        return this.audioContext.createIIRFilter(feedforward, feedback)
    }
  }
}

type DelayArgs = { maxDelayTime: number }
type IIRFilterArgs = { feedforward: number[]; feedback: number[] }

function guardDelayArgs(args: IIRFilterArgs | DelayArgs | undefined): args is DelayArgs {
  return args != null && (args as DelayArgs).maxDelayTime != null
}

function guardIIRFilterArgs(args: IIRFilterArgs | DelayArgs | undefined): args is IIRFilterArgs {
  return args != null && (args as IIRFilterArgs).feedforward != null
}
