export class HttpService {
  private token: string | null = null

  constructor(private readonly baseUrl: string) {}

  public async get({ url, path }: { url?: string; path?: string }): Promise<any> {
    const headers = { 'Content-Type': 'application/json' }
    if (this.token != null) {
      headers['Authorization'] = `Bearer ${this.token}`
    }

    const location = this.resolveUrl(url, path)
    const response = await fetch(location, { headers })
    return response
  }

  public async post({
    url,
    path,
    body,
    mode = 'cors',
  }: {
    url?: string
    path?: string
    body: any
    mode?: 'cors' | 'no-cors' | 'same-origin'
  }): Promise<any> {
    const headers = { 'Content-Type': 'application/json' }
    if (this.token != null) {
      headers['Authorization'] = `Bearer ${this.token}`
    }

    const location = this.resolveUrl(url, path)
    const response = await fetch(location, {
      mode,
      method: 'POST',
      body: JSON.stringify(body),
      headers,
    })
    return response
  }

  public async put({
    url,
    path,
    body,
    mode = 'cors',
  }: {
    url?: string
    path?: string
    body: any
    mode?: 'cors' | 'no-cors' | 'same-origin'
  }): Promise<any> {
    const location = this.resolveUrl(url, path)
    const response = await fetch(location, {
      mode,
      method: 'PUT',
      body,
    })
    return response.text()
  }

  public setBearerToken(token: string): void {
    this.token = token
  }

  private resolveUrl(url?: string, path?: string): string {
    if (url != null) {
      return url
    }
    if (path != null) {
      return `${this.baseUrl}${path}`
    }
    throw new Error('No url or path provided')
  }
}
