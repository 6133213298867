import * as Tone from 'tone'
import { PianoSamplePaths } from './sample_paths'
import { publicPath } from 'global_env'

export const Piano = new Tone.Sampler({
  //baseUrl: `${publicPath}assets/instrument_samples/`,
  baseUrl: `https://sounds.lamusi.de/`,
  urls: PianoSamplePaths,
}).toDestination()

Piano.volume.value = -12
