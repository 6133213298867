import mobxRemotedev from 'mobx-remotedev'
import { MicrophoneDeviceStore } from 'modules/microphone_device/store'
import { computed, makeObservable } from 'mobx'

@mobxRemotedev
export class MicrophoneDevicePresenter {
  constructor(private readonly store: MicrophoneDeviceStore) {
    makeObservable(this)
  }

  @computed get connection():
    | {
        audioContext: AudioContext
        audioDevice: MediaStream
        audioSource: MediaStreamAudioSourceNode
      }
    | undefined {
    if (this.store.state.kind === 'connected') {
      const { audioContext, audioDevice, audioSource } = this.store.state
      return {
        audioContext,
        audioDevice,
        audioSource,
      }
    }
    return undefined
  }
}
