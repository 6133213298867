import * as React from 'react'
import { observer } from 'mobx-react'
import KeyboardPresenter from 'components/keyboard/presenter'

export function createNoteTimeline(keyboardPresenter: KeyboardPresenter) {
  const C = observer(() => {
    return <NoteTimeline notes={keyboardPresenter.notesPlayed} />
  })

  return { NoteTimeline: C }
}

export const NoteTimeline = observer(({ notes }: { notes: string[] }) => {
  return <div>{notes.join(' ')}</div>
})
