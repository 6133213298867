import * as React from 'react'
import { observer } from 'mobx-react'
import { HomeStore } from 'pages/home/store'
import { HomeController } from 'pages/home/controller'
import { HomePresenter } from 'pages/home/presenter'
import { Link } from 'wouter'

export function createHome() {
  const store = new HomeStore()
  const controller = new HomeController(store)
  const presenter = new HomePresenter(store)

  const Home = observer(() => {
    return (
      <div>
        <ul>
          <li>
            <Link to='/'>Play</Link>
          </li>
        </ul>
      </div>
    )
  })

  return { Home }
}
