import * as React from 'react'
import { keyNumberToNote, keyNumberToOctave, selectKeyboardAssist } from 'utilities/music_utilities'
import { WhiteKey, BlackKey } from './key'
import './styles.css'

export const Keyboard = ({
  keys,
  loading,
  label,
  onKeyClick,
}: {
  keys: Array<[number, boolean]>
  loading: boolean
  label: string
  onKeyClick(key: number): void
}) => {
  const whiteKeys = []
  const blackKeys = []

  keys.forEach(([key, pressed]) => {
    const note = keyNumberToNote(key)
    const octave = keyNumberToOctave(key)
    const keyboardAssist = selectKeyboardAssist(label, key)
    const playKey = () => onKeyClick(key)
    if (note.match('#') == null) {
      whiteKeys.push(
        <WhiteKey
          key={key}
          note={note}
          octave={octave}
          label={keyboardAssist}
          pressed={pressed}
          onClick={playKey}
        />,
      )
    } else {
      blackKeys.push(
        <BlackKey
          key={key}
          note={note}
          octave={octave}
          label={keyboardAssist}
          pressed={pressed}
          onClick={playKey}
        />,
      )
    }
  })

  const keyboardClassnames = ['keyboard', loading && 'keyboard--loading'].filter((x) => !!x).join(' ')

  return (
    <div
      id='KeyboardInstrument'
      className='w-full h-full overflow-x-scroll'
    >
      <div
        id='Keyboard__Container'
        className={keyboardClassnames}
      >
        <div className='items-center p-4 pt-10 border-2 border-gray-900 border-solid h-72'>
          <div
            id='Keyboard__KeysOuterContainer'
            className='w-full overflow-x-scroll'
          >
            <div
              id='Keyboard__KeysInnerContainer'
              className='relative inline-block'
            >
              <div
                id='Keyboard__BlackKeys'
                className='absolute left-0 text-xs pointer-events-none transform translate-x-4 whitespace-nowrap h-44'
              >
                {blackKeys}
              </div>
              <div
                id='Keyboard__WhiteKeys'
                className='text-sm whitespace-nowrap h-44'
              >
                {whiteKeys}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
