import * as React from 'react'
import { TextInput } from 'components/base/inputs'

export const EmailInput = ({ className, error, onChange, value }) => {
  const errorClasses = error ? 'border-red-500' : ''
  const classes = `${className} ${errorClasses}`
  return (
    <TextInput
      type='email'
      id='HeaderAuthForm__usernameInput'
      placeholder='your@email.com'
      className={classes}
      value={value}
      onChange={onChange}
    />
  )
}

export const PasswordInput = ({ className, error, onChange, value }) => {
  const errorClasses = error ? 'border-red-500' : ''
  const classes = `${className} ${errorClasses}`
  return (
    <TextInput
     type='password'
      id='HeaderAuthForm__passwordInput'
      placeholder='password'
      className={classes}
      value={value}
      onChange={onChange}
    />
  )
}