import * as React from 'react'
import { observer } from 'mobx-react'
import { MidiSelectPresenter } from 'components/midi_select/presenter'
import { KeyboardAssistSelectorPresenter } from 'components/keyboard_assist_selector/presenter'
import KeyboardStore from './store'
import KeyboardController from './controller'
import KeyboardPresenter from './presenter'
import KeyboardPlayer from './player'
import QwertyPlayer from './qwerty_player'
import GuiKeyboardPlayer from 'components/keyboard/gui_keyboard_player'
import { Keyboard } from 'components/keyboard/keyboard'
import './styles.css'

export default function createKeyboard(
  midiSelectPresenter: MidiSelectPresenter,
  keyboardAssistSelectorPresenter: KeyboardAssistSelectorPresenter,
) {
  const store = new KeyboardStore()
  const controller = new KeyboardController(store)
  const presenter = new KeyboardPresenter(store)
  new KeyboardPlayer(controller, presenter, midiSelectPresenter)
  new QwertyPlayer(controller, presenter)
  const guiKeyboardPlayer = new GuiKeyboardPlayer(controller, presenter)

  const KeyboardComponent = observer(() => {
    const { activeKeys, loading } = presenter
    const { selectedAssist } = keyboardAssistSelectorPresenter
    return (
      <Keyboard
        keys={activeKeys}
        loading={loading}
        label={selectedAssist.value}
        onKeyClick={guiKeyboardPlayer.onClick}
      />
    )
  })

  return { Keyboard: KeyboardComponent, keyboardPresenter: presenter }
}
