import * as React from 'react'
import { PrimaryButton } from 'components/base/buttons'
import { EmailInput, PasswordInput } from './base'

export const StackedForm = ({
  email,
  password,
  error,
  label,
  onEmailChange,
  onPasswordChange,
  onSubmit,
}: {
  email: string
  password: string
  error: string | null
  label: string
  onEmailChange: (email: string) => void
  onPasswordChange: (password: string) => void
  onSubmit: () => void
}) => {
  const setEmail = (e: React.ChangeEvent<HTMLInputElement>) => onEmailChange(e.target.value)
  const setPassword = (e: React.ChangeEvent<HTMLInputElement>) => onPasswordChange(e.target.value)
  return (
    <>
      <EmailInput className={'rounded-md'} error={error} onChange={setEmail} value={email} />
      <PasswordInput className='my-1 rounded-md' error={error} onChange={setPassword} value={password} />
      <PrimaryButton className='mt-1 rounded-md' error={error} onClick={() => onSubmit()}>{label}</PrimaryButton>
    </>
  )
}

export const InlineForm = ({
  email,
  password,
  error,
  label,
  onEmailChange,
  onPasswordChange,
  onSubmit,
}: {
  email: string
  password: string
  error: string | null
  label: string
  onEmailChange: (email: string) => void
  onPasswordChange: (password: string) => void
  onSubmit: () => void
}) => {
  const setEmail = (e: React.ChangeEvent<HTMLInputElement>) => onEmailChange(e.target.value)
  const setPassword = (e: React.ChangeEvent<HTMLInputElement>) => onPasswordChange(e.target.value)
  return (
    <>
      <EmailInput className='rounded-l-md' error={error} onChange={setEmail} value={email} />
      <PasswordInput className='border-l-0' error={error} onChange={setPassword} value={password} />
      <PrimaryButton className='rounded-r-md' error={error} onClick={() => onSubmit()}>{label}</PrimaryButton>
    </>
  )
}
