import * as Tone from 'tone'
import KeyboardController from './controller'
import KeyboardPresenter from './presenter'
import { Piano } from 'instruments/piano'
import { convertKey, keyboardToPianoKey } from 'utilities/music_utilities'
import { reaction } from 'mobx'

export default class QwertyPlayer {
  keydown: any
  keyup: any

  constructor(
    private readonly keyboardController: KeyboardController,
    private readonly keyboardPresenter: KeyboardPresenter,
  ) {
    reaction(
      () => this.keyboardPresenter.enableQwerty,
      (enabled) => {
        if (enabled) {
          this.bindListeners()
        } else {
          this.unbindListeners()
        }
      },
      { fireImmediately: true },
    )
  }

  private bindListeners() {
    document.addEventListener('keydown', this.onKeyDown)
    document.addEventListener('keyup', this.onKeyUp)
  }

  private unbindListeners() {
    document.removeEventListener('keydown', this.onKeyDown)
    document.removeEventListener('keyup', this.onKeyUp)
  }

  private readonly onKeyDown = (e: KeyboardEvent) => {
    const { key } = e
    const pianoKey = keyboardToPianoKey(key)
    if (!pianoKey) return
    if (this.keyboardPresenter.keyIsPressed(pianoKey)) {
      return
    }
    const note = convertKey(pianoKey)
    // see https://github.com/Tonejs/Tone.js/issues/306
    const time = Tone.context.currentTime // immediately fires the note
    // 127 is a magic number. the largest value is 127, we need it to be 100
    const normalizedVelocity = 0.25
    this.keyboardController.setKeyDown(pianoKey, normalizedVelocity)
    Piano.triggerAttackRelease(note, '2m', time, normalizedVelocity)
    setTimeout(() => this.onKeyUp(e), 75)
  }

  private readonly onKeyUp = ({ key }: KeyboardEvent) => {
    const pianoKey = keyboardToPianoKey(key)
    if (!pianoKey) return
    if (!this.keyboardPresenter.keyIsPressed(pianoKey)) {
      return
    }
    this.keyboardController.setKeyUp(pianoKey)
  }
}
