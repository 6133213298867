import * as React from 'react'

export const Form = ({ children }: { children: React.ReactNode }) => (
  <div className='w-full p-4 border-gray-700 rounded-sm shadow-inner bg-gray-50'>{children}</div>
)

export const FormTitle = ({ children }: { children: React.ReactNode }) => (
  <div className='text-sm text-gray-600'>{children}</div>
)

export const Row = ({ children }: { children: React.ReactNode }) => (
  <div className='flex flex-col w-full mt-4'>{children}</div>
)

export const Title = ({ children }: { children: React.ReactNode }) => <div className='text-gray-600'>{children}</div>

export const TitleKey = ({ children }: { children: React.ReactNode }) => (
  <>
    <span>{children}</span>
  </>
)

export const Divider = () => <span> - </span>

export const TitleValue = ({ children }: { children: React.ReactNode }) => <span className='font-bold'>{children}</span>

export const Control = ({ label, children }: { label: string; children: React.ReactNode }) => (
  <div className='p2'>
    <div className='text-xs text-gray-500'>{label}</div>
    {children}
  </div>
)
