import mobxRemotedev from 'mobx-remotedev'
import { makeObservable, observable } from 'mobx'

@mobxRemotedev
export class LayoutStore {
  constructor() {
    makeObservable(this)
  }

  @observable.ref layout: Layout
}

export type Layout = { device: DeviceKind; orientation: OrientationKind }

export const enum DeviceKind {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}

export const enum OrientationKind {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
}
