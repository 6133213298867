import mobxRemotedev from 'mobx-remotedev'
import { AuthStateKind, AuthStore } from 'components/auth/store'
import { action, makeObservable } from 'mobx'
import { UserService } from 'services/user_service'
import { AppController } from 'app/controller'

@mobxRemotedev
export class AuthController {
  constructor(
    private readonly appController: AppController,
    private readonly store: AuthStore,
    private readonly userService: UserService,
  ) {
    makeObservable(this)
    const existingAuth = localStorage.getItem('auth')
    if (existingAuth) {
      const data = JSON.parse(existingAuth)
      this.userService.setBearerToken(data.token)
      this.setSignedIn(data)
    }
  }

  public signIn = async (): Promise<void> => {
    try {
      const { state } = this.store

      if (state.kind === AuthStateKind.SIGNED_IN) {
        console.log(`AuthController.signIn: Tried to transition from "${state.kind}"`)
        return
      }

      this.setSigningIn()

      const { data, error } = await this.userService.signIn(state.data)

      if (error) {
        this.setFailed(error)
        return
      }

      this.setSignedIn(data)
      localStorage.setItem('auth', JSON.stringify(data))
    } catch (e) {
      console.error(e)
      this.setFailed(e.message)
    }
  }

  public signUp = async (): Promise<void> => {
    try {
      const { state } = this.store

      if (state.kind === AuthStateKind.SIGNED_IN) {
        console.log(`AuthController.signUp: Tried to transition from "${state.kind}"`)
        return
      }

      this.setSigningIn()

      const { data, error } = await this.userService.signUp(state.data)

      if (error) {
        this.setFailed(error)
        return
      }

      this.setSignedIn(data)
      localStorage.setItem('auth', JSON.stringify(data))
    } catch (e) {
      console.error(e)
      this.setFailed(e.message)
    }
  }

  // logic in here is not quite right, setFailed shouldn't be called
  public signOut = async (): Promise<void> => {
    try {
      const { state } = this.store

      if (state.kind !== AuthStateKind.SIGNED_IN) {
        console.log(`AuthController.signOut: Tried to transition from "${state.kind}"`)
        return
      }

      const { error } = await this.userService.signOut()

      if (error) {
        this.setFailed(error)
        return
      }

      localStorage.removeItem('auth')
      this.setInitial()
    } catch (e) {
      console.error(e)
      this.setFailed(e.message)
    }
  }

  @action
  public setEmail = (email: string): void => {
    const { state } = this.store

    if (state.kind !== AuthStateKind.INITIAL && state.kind !== AuthStateKind.FAILED) {
      console.log(`AuthController.setEmail: Tried to transition from "${state.kind}"`)
      return
    }

    this.store.state = { kind: AuthStateKind.INITIAL, error: null, data: { email, password: state.data.password } }
  }

  @action
  public setPassword = (password: string): void => {
    const { state } = this.store

    if (state.kind !== AuthStateKind.INITIAL && state.kind !== AuthStateKind.FAILED) {
      console.log(`AuthController.setPassword: Tried to transition from "${state.kind}"`)
      return
    }

    this.store.state = { kind: AuthStateKind.INITIAL, error: null, data: { email: state.data.email, password } }
  }

  @action
  private setInitial = (): void => {
    this.store.state = { kind: AuthStateKind.INITIAL, error: null, data: { email: '', password: '' } }
  }

  @action
  private setSigningIn = (): void => {
    const { state } = this.store

    if (state.kind !== AuthStateKind.INITIAL && state.kind !== AuthStateKind.FAILED) {
      console.log(`AuthController.setSigningIn: Tried to transition from "${state.kind}"`)
      return
    }

    this.store.state = { kind: AuthStateKind.SIGNING_IN, error: null, data: state.data }
  }

  @action
  private setFailed = (error: string): void => {
    const { state } = this.store
    this.store.state = { kind: AuthStateKind.FAILED, error, data: state.data }
  }

  @action
  private setSignedIn = (token: string): void => {
    const { state } = this.store

    if (state.kind !== AuthStateKind.SIGNING_IN && state.kind !== AuthStateKind.INITIAL) {
      console.log(`AuthController.setSignedIn: Tried to transition from "${state.kind}"`)
      return
    }

    const preAuthData = this.store.state.data
    const user = { email: preAuthData.email, password: preAuthData.password, token }
    this.store.state = { kind: AuthStateKind.SIGNED_IN, error: null, data: user }
    this.appController.setUser(user)
  }
}
