import { SheetMusicSelectorStore, SearchItem } from './store'
import { makeObservable, computed } from 'mobx'
import mobxRemotedev from 'mobx-remotedev'

@mobxRemotedev
export class SheetMusicSelectorPresenter {
  constructor(private readonly store: SheetMusicSelectorStore) {
    makeObservable(this)
  }

  @computed get searchItems(): SearchItem[] {
    return this.store.sheetMusic
  }

  @computed get activeItem(): SearchItem {
    return this.store.selectedSheetMusic
  }
}
