import { PitchDetector } from 'pitchy'
import mobxRemotedev from 'mobx-remotedev'
import { MicrophoneInputStore } from './store'
import { action, makeObservable } from 'mobx'
import { loop } from 'utilities/loop'
import { MicrophoneDeviceController } from 'modules/microphone_device/controller'
import { MicrophoneDevicePresenter } from 'modules/microphone_device/presenter'
import { AudioNodeManagerController } from 'components/audio_node_manager/controller'
import { AudioNodePipeline } from 'components/audio_node_manager/audio_node_pipeline'

@mobxRemotedev
export class MicrophoneInputController {
  private readonly SAMPLE_RATE = 60
  private readonly SAMPLE_COUNT = this.SAMPLE_RATE * 1
  private clearLoop: () => void | undefined
  private clearLoop2: () => void | undefined
  private clearLoop3: () => void | undefined
  private unsubscribeMicrophone: () => void | undefined

  constructor(
    private readonly store: MicrophoneInputStore,
    private readonly microphoneDeviceController: MicrophoneDeviceController,
    private readonly microphoneDevicePresenter: MicrophoneDevicePresenter,
    private readonly audioNodeManagerController: AudioNodeManagerController,
  ) {
    makeObservable(this)
  }

  public readonly start = async () => {
    try {
      this.unsubscribeMicrophone = await this.microphoneDeviceController.subscribe()
      const audioConnection = this.microphoneDevicePresenter.connection
      this.connect(audioConnection)
      this.clearLoop = loop(this.deriveFFT, 24)
      this.clearLoop2 = loop(this.deriveVolume, 24)
      this.clearLoop3 = loop(this.derivePitch, 12)
    } catch (e) {
      this.handleConnectionFailure()
    }
  }

  public readonly stop = () => {
    if (this.unsubscribeMicrophone == null) {
      throw new Error('Tried to stop MicrophoneInput when no unsubscribe callback was registered')
    }

    this.clearLoop != null && this.clearLoop()
    this.clearLoop2 != null && this.clearLoop2()
    this.clearLoop3 != null && this.clearLoop3()

    if (this.store.state.kind === 'connected') {
      this.unsubscribeMicrophone()
      this.setDisconnected()
    }
  }

  @action private setDisconnected() {
    this.store.state = { kind: 'disconnected' }
  }

  @action private handleConnectionFailure() {
    this.store.state = { kind: 'failed' }
  }

  @action private connect({ audioDevice, audioContext }: { audioDevice: MediaStream; audioContext: AudioContext }) {
    // make a new source from the old one
    const audioSource = audioContext.createMediaStreamSource(audioDevice)
    const audioProcessor = audioContext.createScriptProcessor(1024, 1, 1)
    const audioAnalyser = audioContext.createAnalyser()
    audioAnalyser.fftSize = 8192
    const pitchDetector = PitchDetector.forFloat32Array(audioAnalyser.fftSize)

    // a little noise gate, found params on github on a 4 year old repo
    const dynamicsCompressor = audioContext.createDynamicsCompressor()
    dynamicsCompressor.threshold.value = -30
    dynamicsCompressor.release.value = 0.25
    dynamicsCompressor.attack.value = 0
    dynamicsCompressor.knee.value = 40
    dynamicsCompressor.ratio.value = 12
    const biquadFilter = audioContext.createBiquadFilter()
    biquadFilter.detune.value = 0
    biquadFilter.gain.value = 2
    biquadFilter.frequency.value = 355
    biquadFilter.Q.value = 8.3
    audioSource.connect(dynamicsCompressor)
    dynamicsCompressor.connect(biquadFilter)

    const pipeline = new AudioNodePipeline(biquadFilter, audioAnalyser)
    this.audioNodeManagerController.registerPipeline('MicrophoneInput Module', pipeline)
    this.store.state = {
      kind: 'connected',
      audioContext,
      audioDevice,
      audioSource,
      audioProcessor,
      audioAnalyser,
      pitchDetector,
    }
  }

  @action.bound private deriveFFT() {
    if (this.store.state.kind !== 'connected') {
      return
    }

    const { audioAnalyser } = this.store.state
    const floatFrequencyData = new Float32Array(audioAnalyser.frequencyBinCount)
    audioAnalyser.getFloatFrequencyData(floatFrequencyData)
    this.store.floatFrequencyData = floatFrequencyData
  }

  @action.bound private deriveVolume() {
    if (this.store.state.kind !== 'connected') {
      return
    }

    const { audioAnalyser } = this.store.state
    const byteFrequencyData = new Uint8Array(audioAnalyser.frequencyBinCount)
    audioAnalyser.getByteFrequencyData(byteFrequencyData)
    const sum = byteFrequencyData.reduce((acc, curr) => acc + curr)
    const avg = sum / byteFrequencyData.length
    console.log(avg)
    this.store.volume = avg
  }

  @action.bound private derivePitch() {
    if (this.store.state.kind !== 'connected') {
      return
    }
    if (this.store.volume === 0) {
      this.store.pitch = 0
      this.store.clarity = 0
      return
    }

    const { audioAnalyser, audioContext, pitchDetector } = this.store.state
    const input = new Float32Array(pitchDetector.inputLength)
    audioAnalyser.getFloatTimeDomainData(input)
    const [pitch, clarity] = pitchDetector.findPitch(input, audioContext.sampleRate)
    this.store.pitch = pitch
    this.store.clarity = clarity
    this.store.pitchData.push(pitch)
    if (this.store.pitchData.length > this.SAMPLE_COUNT) {
      this.store.pitchData.shift()
    }
  }
}
