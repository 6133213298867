import { RecordingController } from './controller'
import { RecordingPresenter } from './presenter'
import { Recording } from './recording'
import { RecordingStore } from './store'

export function createRecording() {
  const recordingStore = new RecordingStore()
  const recordingController = new RecordingController(recordingStore)
  const recordingPresenter = new RecordingPresenter(recordingStore)
  const recording = new Recording(recordingController, recordingPresenter)

  return { recording }
}

export type RecordingModule = {
  recording: Recording
}
