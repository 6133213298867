import * as React from 'react'
import { observer } from 'mobx-react'
import { Singleton } from 'singleton'
import { DashboardStore } from 'pages/dashboard/store'
import { DashboardController } from 'pages/dashboard/controller'
import { DashboardPresenter } from 'pages/dashboard/presenter'
import { LayoutProps } from 'components/layout'
import { createMidiInputSelect } from 'components/midi_select'
import { createSheetMusicSelector } from 'components/sheet_music_selector'
import { createSheetMusic } from 'components/sheet_music'
import createKeyboard from 'components/keyboard'
import { createVelocityGraph } from 'components/velocity_graph'
import { createMetronome } from 'components/metronome'
import { createPitchGraph } from 'components/pitch_graph'
import { createVisualisations } from 'components/visualisations'
import { createMicrophoneInput } from 'modules/microphone_input'
import { createMicrophoneDevice } from 'modules/microphone_device'
import { createMicrophoneRecorder } from 'modules/microphone_recorder'
import { createRecordingTool } from 'components/recording_tool'
import { createNoteTimeline } from 'components/note_timeline'
import { createKeyboardAssistSelector } from 'components/keyboard_assist_selector'
import { createVolumeControl } from 'components/volume_control'
import { createAudioNodeManager } from 'components/audio_node_manager'
import { Piano } from 'instruments/piano'
import { createRecordingList } from 'components/recording-list'
import { createSoundExercisePlayer } from 'components/sound-exercise-player'
import { createGuitarStrumPlayer } from 'components/guitar_strum_player'

export function createDashboard(Layout: React.ComponentType<LayoutProps>, singleton: Singleton) {
  const store = new DashboardStore()
  const controller = new DashboardController(store)
  const presenter = new DashboardPresenter(store)

  // Development Tooling
  const { AudioNodeManager, audioNodeManagerController } = createAudioNodeManager()

  // Modules
  const { microphoneDeviceController, microphoneDevicePresenter } = createMicrophoneDevice(audioNodeManagerController)
  const { microphoneInputController, microphoneInputPresenter } = createMicrophoneInput(
    microphoneDeviceController,
    microphoneDevicePresenter,
    audioNodeManagerController,
  )
  const microphoneRecordingModule = createMicrophoneRecorder(microphoneDeviceController, microphoneDevicePresenter)

  // Components
  const { Metronome } = createMetronome()
  const { midiSelectPresenter, MidiInputSelect } = createMidiInputSelect(singleton.app.store)
  const { sheetMusicSelectorPresenter, SheetMusicSelector } = createSheetMusicSelector()
  const { KeyboardAssistSelector, keyboardAssistSelectorPresenter } = createKeyboardAssistSelector()
  const { SheetMusic } = createSheetMusic(sheetMusicSelectorPresenter)
  const { VolumeControl } = createVolumeControl({ Instrument: Piano })
  const { Keyboard, keyboardPresenter } = createKeyboard(midiSelectPresenter, keyboardAssistSelectorPresenter)
  const { VelocityGraph } = createVelocityGraph(keyboardPresenter)
  const { NoteTimeline } = createNoteTimeline(keyboardPresenter)
  const { PitchGraph } = createPitchGraph(microphoneInputController, microphoneInputPresenter)
  const { RecordingTool } = createRecordingTool(singleton, { microphoneRecordingModule })
  const { RecordingList } = createRecordingList(singleton)
  const { SoundExercisePlayer } = createSoundExercisePlayer()
  const { GuitarStrumPlayer } = createGuitarStrumPlayer()

  const Recording = () => {
    return (
      <div>
        <RecordingTool />
        <RecordingList />
      </div>
    )
  }

  const Tools = [
    VolumeControl,
    AudioNodeManager,
    MidiInputSelect,
    Metronome,
    SheetMusicSelector,
    KeyboardAssistSelector,
  ]
  const Instruments = [Keyboard]

  const { Visualisations } = createVisualisations({
    singleton,
    visualisationComponents: [
      { Component: GuitarStrumPlayer, label: 'Guitar Helper' },
      { Component: Recording, label: 'Record Yourself' },
      { Component: SoundExercisePlayer, label: 'Sound Exercise Player' },
      { Component: SheetMusic, label: 'Sheet Music' },
      { Component: PitchGraph, label: 'Pitch Graph' },
      { Component: VelocityGraph, label: 'Velocity Graph' },
      { Component: NoteTimeline, label: 'Note Timeline' },
    ],
  })

  const Dashboard = observer(() => {
    return (
      <>
        <Layout Tools={Tools} Instruments={Instruments} Visualisations={Visualisations} />
      </>
    )
  })

  return { Dashboard }
}
