import * as React from 'react'
import { observer } from 'mobx-react'
import { KeyboardAssistSelectorStore } from './store'
import { KeyboardAssistSelectorController } from './controller'
import { KeyboardAssistSelectorPresenter } from './presenter'
import { Option, Select } from 'components/base/selects'

export function createKeyboardAssistSelector() {
  const store = new KeyboardAssistSelectorStore()
  const controller = new KeyboardAssistSelectorController(store)
  const presenter = new KeyboardAssistSelectorPresenter(store)

  const setSelectedAssist = (option: Option) => {
    controller.setSelectedAssist(option)
  }

  const KeyboardAssistSelector = observer(() => {
    return (
      <div id='KeyboardAssistSelector'>
        <p className='mb-2 font-bold'>Keyboard Labels</p>
        <Select
          onChange={setSelectedAssist}
          options={presenter.options}
          defaultOption={presenter.selectedAssist}
        />
      </div>
    )
  })

  return { keyboardAssistSelectorPresenter: presenter, KeyboardAssistSelector }
}
