import { makeObservable, observable } from 'mobx'

type Email = string
type Password = string
type Token = string
type PreAuthData = { email: Email; password: Password }
type PostAuthData = { email: Email; password: Password; token: Token }

export const enum AuthStateKind {
  INITIAL = 'INITIAL',
  SIGNING_IN = 'SIGNING_IN',
  FAILED = 'FAILED',
  SIGNED_IN = 'SIGNED_IN',
}

export type AuthState =
  | { kind: AuthStateKind.INITIAL; error: null; data: PreAuthData }
  | { kind: AuthStateKind.SIGNING_IN; error: null; data: PreAuthData }
  | { kind: AuthStateKind.FAILED; error: string; data: PreAuthData }
  | { kind: AuthStateKind.SIGNED_IN; error: null; data: PostAuthData }

export class AuthStore {
  constructor() {
    makeObservable(this)
  }

  @observable.ref state: AuthState = { kind: AuthStateKind.INITIAL, error: null, data: { email: '', password: '' } }
}
