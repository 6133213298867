import mobxRemotedev from 'mobx-remotedev'
import { SingleRecordingStore } from 'components/single_recording/store'
import { action, makeObservable } from 'mobx'

@mobxRemotedev
export class SingleRecordingController {
  constructor(private readonly store: SingleRecordingStore) {
    makeObservable(this)
  }

  @action noop() {}
}
