import mobxRemotedev from 'mobx-remotedev'
import { PitchGraphStore } from 'components/pitch_graph/store'
import { action, makeObservable } from 'mobx'

@mobxRemotedev
export class PitchGraphController {
  constructor(private readonly store: PitchGraphStore) {
    makeObservable(this)
  }

  @action noop() {}
}
