import * as React from 'react'
import { observer } from 'mobx-react'
import { SheetMusicSelectorStore } from './store'
import { SheetMusicSelectorController } from './controller'
import { SheetMusicSelectorPresenter } from './presenter'
import { Select, Option } from 'components/base/selects'

export function createSheetMusicSelector() {
  const store = new SheetMusicSelectorStore()
  const controller = new SheetMusicSelectorController(store)
  const presenter = new SheetMusicSelectorPresenter(store)

  const setSearchItem = (option: Option) => {
    const item = presenter.searchItems.find((item) => item.url === option.value)
    controller.setSelectedSearchItem(item)
  }

  const SheetMusicSelector = observer(() => {
    const options = [{ name: 'Choose a song', url: '' }, ...presenter.searchItems].map(({ name, url }) => ({
      key: name,
      value: url,
    }))
    return (
      <div id='SheetMusicSelector'>
        <p className='mb-2 font-bold'>Learn / Practice</p>
        <Select
          onChange={setSearchItem}
          options={options}
          defaultOption={options[0]}
        />
      </div>
    )
  })

  return { sheetMusicSelectorPresenter: presenter, SheetMusicSelector }
}
