import mobxRemotedev from 'mobx-remotedev'
import { computed, makeObservable } from 'mobx'
import KeyboardStore from './store'

@mobxRemotedev
export default class KeyboardPresenter {
  constructor(private readonly store: KeyboardStore) {
    makeObservable(this)
  }

  @computed get loading(): boolean {
    return this.store.loading
  }

  @computed get velocity(): number {
    return this.store.velocity
  }

  @computed get activeKeys(): Array<[number, boolean]> {
    return Array.from(this.store.keys.entries())
  }

  @computed get velocities(): number[] {
    return this.store.velocities.slice(0, 8)
  }

  @computed get notesPlayed(): string[] {
    return this.store.notesPlayed
  }

  @computed get timestamps(): number[] {
    return this.store.timestamps.slice(0, 8)
  }

  @computed get enableQwerty(): boolean {
    return this.store.enableQwerty
  }

  @computed get enableGuiKeyboard(): boolean {
    return this.store.enableGuiKeyboard
  }

  @computed get enableKeyboard(): boolean {
    return this.store.enableKeyboard
  }

  keyIsPressed(key: number): boolean {
    return this.store.keys.get(key) === true
  }
}
