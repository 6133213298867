import mobxRemotedev from 'mobx-remotedev'
import { VisualisationsStore } from 'components/visualisations/store'
import { action, makeObservable } from 'mobx'

@mobxRemotedev
export class VisualisationsController {
  constructor(private readonly store: VisualisationsStore) {
    makeObservable(this)
  }

  @action noop() {}
}
