import mobxRemotedev from 'mobx-remotedev'
import { RecordingListStore } from 'components/recording-list/store'
import { action, makeObservable } from 'mobx'
import { RecordingService, UploadedRecording } from 'services/recording_service'
import { createRecording } from 'modules/recording'
import { Recording } from 'modules/recording/recording'

@mobxRemotedev
export class RecordingListController {
  constructor(private readonly store: RecordingListStore, private readonly recordingService: RecordingService) {
    makeObservable(this)
  }

  public readonly downloadRecording = async (uploadedRecording: UploadedRecording): Promise<void> => {
    try {
      const blob = await this.recordingService.downloadRecording(uploadedRecording.id)
      const { recording } = createRecording()
      await recording.setFromDownloadedData(blob, uploadedRecording.duration)
      this.addRecording(recording)
      this.removeUploadedRecording(uploadedRecording)
      recording.play()
    } catch (e) {
      console.error(e.message)
    }
  }

  public readonly loadRecordings = async (): Promise<void> => {
    try {
      const recordings = await this.recordingService.listRecordings()
      if (recordings == null) {
        throw new Error('Failed to load recordings')
      }
      this.addUploadedRecordings(recordings)
    } catch (e) {
      console.error(e.message)
    }
  }

  @action addUploadedRecordings(data: UploadedRecording[]) {
    this.store.uploadedRecordings = data
  }

  @action addRecording(recording: Recording) {
    this.store.recordings.push(recording)
  }

  @action removeUploadedRecording(uploadedRecording: UploadedRecording) {
    this.store.uploadedRecordings = this.store.uploadedRecordings.filter((r) => r.id !== uploadedRecording.id)
  }
}
