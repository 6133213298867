import * as React from 'react'
import { RangeInput } from 'components/base/inputs'
import { Form, FormTitle, Row, Control } from './base_components'
import { Select } from 'components/base/selects'

export const GainNodeForm = ({
  initialGain = 0,
  onGainChange,
}: {
  initialGain?: number
  onGainChange(v: number): void
}) => {
  const [gain, setGain] = React.useState(initialGain)
  const changeGain = React.useCallback((v: number) => {
    onGainChange(v)
    setGain(v)
  }, [])
  return (
    <Form>
      <FormTitle>Gain Node</FormTitle>
      <Row>
        <Control label='Gain'>
          <RangeInput
            value={gain}
            onChange={changeGain}
            min={0}
            max={20}
          />
        </Control>
      </Row>
    </Form>
  )
}

export const DelayNodeForm = ({
  initialDelay = 0,
  onDelayChange,
}: {
  initialDelay?: number
  onDelayChange(v: number): void
}) => {
  const [delay, setDelay] = React.useState(initialDelay)
  const changeDelay = React.useCallback((v: number) => {
    setDelay(v)
    onDelayChange(v)
  }, [])

  return (
    <Form>
      <FormTitle>Delay Node</FormTitle>
      <Row>
        <Control label='Delay'>
          <RangeInput
            value={delay}
            onChange={changeDelay}
            min={0}
            max={10}
          />
        </Control>
      </Row>
    </Form>
  )
}

/**
 * See this for more info on biquad filter settings
 * https://webaudio.github.io/web-audio-api/#dictdef-biquadfilteroptions
 *
 * Q Factor
 * types lowpass and highpass the q factor should range from -770.63678 to +770.63678
 * types lowshelf and highshelf dont use q factor
 * rest of the types the q factor should range from +0 to +3.4028235𝑒38
 *
 * Detune
 * -153600 to +153600
 *
 * Gain
 * +0 to +1541
 */
export const BiquadFilterNodeForm = ({
  initialDetune = 0,
  initialGain = 0,
  initialFrequency = 350,
  initialType = 'lowpass',
  initialQFactor = 1,
  onDetuneChange,
  onGainChange,
  onFrequencyChange,
  onTypeChange,
  onQFactorChange,
}: {
  initialDetune: number
  initialGain: number
  initialFrequency: number
  initialType: BiquadFilterType
  initialQFactor: number
  onDetuneChange(v: number): void
  onGainChange(v: number): void
  onFrequencyChange(v: number): void
  onTypeChange(t: BiquadFilterType): void
  onQFactorChange(v: number): void
}) => {
  const [detune, setDetune] = React.useState(initialDetune)
  const [gain, setGain] = React.useState(initialGain)
  const [frequency, setFrequency] = React.useState(initialFrequency)
  const [qFactor, setQFactor] = React.useState(initialQFactor)
  const changeDetune = React.useCallback((v: number) => {
    setDetune(v)
    onDetuneChange(v)
  }, [])
  const changeGain = React.useCallback((v: number) => {
    setGain(v)
    onGainChange(v)
  }, [])
  const changeFrequency = React.useCallback((v: number) => {
    setFrequency(v)
    onFrequencyChange(v)
  }, [])
  const changeType = React.useCallback((v: { value: BiquadFilterType }) => {
    onTypeChange(v.value)
  }, [])
  const changeQFactor = React.useCallback((v: number) => {
    setQFactor(v)
    onQFactorChange(v)
  }, [])

  return (
    <Form>
      <FormTitle>Biquad Filter Node</FormTitle>
      <Row>
        <Control label={`Detune: ${detune}`}>
          <RangeInput
            value={detune}
            onChange={changeDetune}
            min={0}
            max={10}
            step={0.01}
          />
        </Control>
        <Control label={`Gain: ${gain}`}>
          <RangeInput
            value={gain}
            onChange={changeGain}
            min={0}
            max={10}
            step={0.01}
          />
        </Control>
        <Control label={`Frequency: ${frequency}`}>
          <RangeInput
            value={frequency}
            onChange={changeFrequency}
            min={0}
            max={20000}
            step={1}
          />
        </Control>
        <Control label={`Q Factor: ${qFactor}`}>
          <RangeInput
            value={qFactor}
            onChange={changeQFactor}
            min={0}
            max={10}
            step={0.1}
          />
        </Control>
        <Control label='Type'>
          <Select
            defaultOption={biquadFilterTypeOptions.find((f) => f.value === initialType)}
            options={biquadFilterTypeOptions}
            onChange={changeType}
          />
        </Control>
      </Row>
    </Form>
  )
}

const biquadFilterTypeOptions = [
  'allpass',
  'bandpass',
  'highpass',
  'highshelf',
  'lowpass',
  'lowshelf',
  'notch',
  'peaking',
].map((k) => ({ key: k, value: k }))

/**
 * See this for more info on oscillator settings
 * https://webaudio.github.io/web-audio-api/#OscillatorNode
 *
 * Detune
 * -153600 to +153600
 */
export const OscillatorNodeForm = ({
  initialDetune = 0,
  initialFrequency = 350,
  initialType = 'sine',
  onDetuneChange,
  onFrequencyChange,
  onTypeChange,
}: {
  initialDetune: number
  initialFrequency: number
  initialType: OscillatorType
  onDetuneChange(v: number): void
  onFrequencyChange(v: number): void
  onTypeChange(t: OscillatorType): void
}) => {
  const [detune, setDetune] = React.useState(initialDetune)
  const [frequency, setFrequency] = React.useState(initialFrequency)
  const changeDetune = React.useCallback((v: number) => {
    setDetune(v)
    onDetuneChange(v)
  }, [])
  const changeFrequency = React.useCallback((v: number) => {
    setFrequency(v)
    onFrequencyChange(v)
  }, [])
  const changeType = React.useCallback((v: { value: OscillatorType }) => {
    onTypeChange(v.value)
  }, [])

  return (
    <Form>
      <FormTitle>Delay Node</FormTitle>
      <Row>
        <Control label='Detune'>
          <RangeInput
            value={detune}
            onChange={changeDetune}
            min={0}
            max={10}
            step={0.1}
          />
        </Control>
        <Control label='Frequency'>
          <RangeInput
            value={frequency}
            onChange={changeFrequency}
            min={0}
            max={20000}
          />
        </Control>
        <Control label='Type'>
          <Select
            defaultOption={oscillatorTypeOptions.find((f) => f.value === initialType)}
            options={oscillatorTypeOptions}
            onChange={changeType}
          />
        </Control>
      </Row>
    </Form>
  )
}
const oscillatorTypeOptions = ['sine', 'square', 'sawtooth', 'triangle'].map((k) => ({ key: k, value: k }))

/**
 * See this for more info on dynamic compressor settings
 * https://webaudio.github.io/web-audio-api/#DynamicsCompressorNode
 */
export const DynamicsCompressorNodeForm = ({
  initialThreshold = -24,
  initialKnee = 30,
  initialRatio = 12,
  initialAttack = 0.003,
  initialRelease = 0.25,
  onThresholdChange,
  onKneeChange,
  onRatioChange,
  onAttackChange,
  onReleaseChange,
}: {
  initialThreshold: number
  initialKnee: number
  initialRatio: number
  initialAttack: number
  initialRelease: number
  onThresholdChange(v: number): void
  onKneeChange(v: number): void
  onRatioChange(v: number): void
  onAttackChange(v: number): void
  onReleaseChange(v: number): void
}) => {
  const [threshold, setThreshold] = React.useState(initialThreshold)
  const [knee, setKnee] = React.useState(initialKnee)
  const [ratio, setRatio] = React.useState(initialRatio)
  const [release, setRelease] = React.useState(initialRelease)
  const [attack, setAttack] = React.useState(initialAttack)

  const changeThreshold = React.useCallback((v: number) => {
    setThreshold(v)
    onThresholdChange(v)
  }, [])
  const changeKnee = React.useCallback((v: number) => {
    setKnee(v)
    onKneeChange(v)
  }, [])
  const changeRatio = React.useCallback((v: number) => {
    setRatio(v)
    onRatioChange(v)
  }, [])
  const changeRelease = React.useCallback((v: number) => {
    setRelease(v)
    onReleaseChange(v)
  }, [])
  const changeAttack = React.useCallback((v: number) => {
    setAttack(v)
    onAttackChange(v)
  }, [])

  return (
    <Form>
      <FormTitle>Dynamics Compressor Node</FormTitle>
      <Row>
        <Control label={`Attack: ${attack}`}>
          <RangeInput
            value={attack}
            onChange={changeAttack}
            min={0}
            max={1}
            step={0.001}
          />
        </Control>
        <Control label={`Release: ${release}`}>
          <RangeInput
            value={release}
            onChange={changeRelease}
            min={0}
            max={1}
            step={0.001}
          />
        </Control>
        <Control label={`Threshold: ${threshold}`}>
          <RangeInput
            value={threshold}
            onChange={changeThreshold}
            min={-100}
            max={0}
          />
        </Control>
        <Control label={`Knee: ${knee}`}>
          <RangeInput
            value={knee}
            onChange={changeKnee}
            min={0}
            max={40}
            step={0.5}
          />
        </Control>
        <Control label={`Ratio: ${ratio}`}>
          <RangeInput
            value={ratio}
            onChange={changeRatio}
            min={1}
            max={20}
            step={0.25}
          />
        </Control>
      </Row>
    </Form>
  )
}
