import { MicrophoneInputStore } from './store'
import { MicrophoneInputController } from './controller'
import { MicrophoneInputPresenter } from './presenter'
import { MicrophoneDevicePresenter } from 'modules/microphone_device/presenter'
import { MicrophoneDeviceController } from 'modules/microphone_device/controller'
import { AudioNodeManagerController } from 'components/audio_node_manager/controller'

export function createMicrophoneInput(
  microphoneDeviceController: MicrophoneDeviceController,
  microphoneDevicePresenter: MicrophoneDevicePresenter,
  audioNodeManagerController: AudioNodeManagerController,
) {
  const microphoneInputStore = new MicrophoneInputStore()
  const microphoneInputController = new MicrophoneInputController(
    microphoneInputStore,
    microphoneDeviceController,
    microphoneDevicePresenter,
    audioNodeManagerController,
  )
  const microphoneInputPresenter = new MicrophoneInputPresenter(microphoneInputStore)

  return {
    microphoneInputController,
    microphoneInputPresenter,
  }
}

export type MicrophoneInputModule = {
  microphoneInputController: MicrophoneInputController
  microphoneInputPresenter: MicrophoneInputPresenter
}
