import { QwertyActionsStore } from 'modules/qwerty_actions/store'
import { QwertyActionsController } from 'modules/qwerty_actions/controller'
// import { QwertyActionsPresenter } from "modules/qwerty_actions/presenter"

export type QwertyActionsDevice = {
  qwertyActionsController: QwertyActionsController
}

export function createQwertyActions() {
  const qwertyActionsStore = new QwertyActionsStore()
  const qwertyActionsController = new QwertyActionsController(qwertyActionsStore)
  // const qwertyActionsPresenter = new QwertyActionsPresenter(qwertyActionsStore)

  return { qwertyActionsController }
}

export type QwertyActionsModule = {
  qwertyActionsController: QwertyActionsController
}
