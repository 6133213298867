import * as React from 'react'
import { AudioNodeKind } from 'components/audio_node_manager/audio_node_constructor'
import {
  BiquadFilterNodeForm,
  DelayNodeForm,
  DynamicsCompressorNodeForm,
  GainNodeForm,
  OscillatorNodeForm,
} from 'components/audio_node_manager/forms'

export const AudioNodeSection = ({ type, node }: { type: AudioNodeKind; node: AudioNode }) => {
  switch (type) {
    case AudioNodeKind.DELAY:
      return <DelayNodeSection node={node as DelayNode} />
    case AudioNodeKind.GAIN:
      return <GainNodeSection node={node as GainNode} />
    case AudioNodeKind.BIQUAD_FILTER:
      return <BiquadFilterSection node={node as BiquadFilterNode} />
    case AudioNodeKind.OSCILLATOR:
      return <OscillatorNodeSection node={node as OscillatorNode} />
    case AudioNodeKind.DYNAMICS_COMPRESSOR:
      return <DynamicsCompressorNodeSection node={node as DynamicsCompressorNode} />
    default:
      throw new Error('dont have this node built yet')
  }
}

const DelayNodeSection = ({ node }: { node: DelayNode }) => {
  const updateDelay = React.useCallback((v: number) => {
    node.delayTime.value = v
  }, [])

  return (
    <DelayNodeForm
      onDelayChange={updateDelay}
      initialDelay={node.delayTime.value}
    />
  )
}

const GainNodeSection = ({ node }: { node: GainNode }) => {
  const updateGain = React.useCallback((v: number) => {
    node.gain.value = v
  }, [])

  return (
    <GainNodeForm
      onGainChange={updateGain}
      initialGain={node.gain.value}
    />
  )
}

const BiquadFilterSection = ({ node }: { node: BiquadFilterNode }) => {
  // v is in hertz
  const updateFrequency = React.useCallback((v) => (node.frequency.value = v), [])
  // v is in cents https://en.wikipedia.org/wiki/Cent_%28music%29
  const updateDetune = React.useCallback((v) => (node.detune.value = v), [])
  // v is q factor https://en.wikipedia.org/wiki/Q_factor
  const updateQFactor = React.useCallback((v) => (node.Q.value = v), [])
  // v is a gain multiplier https://en.wikipedia.org/wiki/Gain_(electronics)
  const updateGain = React.useCallback((v) => (node.gain.value = v), [])
  // v is in cents https://developer.mozilla.org/en-US/docs/Web/API/BiquadFilterNode/type
  const updateType = React.useCallback((t) => (node.type = t), [])

  return (
    <BiquadFilterNodeForm
      initialFrequency={node.frequency.value}
      initialDetune={node.detune.value}
      initialQFactor={node.Q.value}
      initialGain={node.gain.value}
      initialType={node.type}
      onFrequencyChange={updateFrequency}
      onDetuneChange={updateDetune}
      onQFactorChange={updateQFactor}
      onGainChange={updateGain}
      onTypeChange={updateType}
    />
  )
}

const OscillatorNodeSection = ({ node }: { node: OscillatorNode }) => {
  // v is in hertz
  const updateFrequency = React.useCallback((v) => (node.frequency.value = v), [])
  // v is in cents https://en.wikipedia.org/wiki/Cent_%28music%29
  const updateDetune = React.useCallback((v) => (node.detune.value = v), [])
  // v is in cents https://developer.mozilla.org/en-US/docs/Web/API/BiquadFilterNode/type
  const updateType = React.useCallback((t) => (node.type = t), [])

  return (
    <OscillatorNodeForm
      initialFrequency={node.frequency.value}
      initialDetune={node.detune.value}
      initialType={node.type}
      onFrequencyChange={updateFrequency}
      onDetuneChange={updateDetune}
      onTypeChange={updateType}
    />
  )
}

const DynamicsCompressorNodeSection = ({ node }: { node: DynamicsCompressorNode }) => {
  const updateAttack = React.useCallback((v) => (node.attack.value = v), [])
  const updateRelease = React.useCallback((v) => (node.release.value = v), [])
  const updateKnee = React.useCallback((v) => (node.knee.value = v), [])
  const updateThreshold = React.useCallback((v) => (node.threshold.value = v), [])
  const updateRatio = React.useCallback((v) => (node.ratio.value = v), [])

  return (
    <DynamicsCompressorNodeForm
      initialThreshold={node.threshold.value}
      initialKnee={node.knee.value}
      initialRatio={node.ratio.value}
      initialAttack={node.attack.value}
      initialRelease={node.release.value}
      onThresholdChange={updateThreshold}
      onKneeChange={updateKnee}
      onRatioChange={updateRatio}
      onAttackChange={updateAttack}
      onReleaseChange={updateRelease}
    />
  )
}
