import mobxRemotedev from "mobx-remotedev"
import { makeObservable, observable } from "mobx"
import { Option } from 'components/base/selects'

@mobxRemotedev
export class SoundExercisePlayerStore {
  constructor() {
    makeObservable(this)
  }

  @observable.ref baseNote: Option = { value: 'C', key: 'C' }

  @observable.ref scaleType: Option = { value: 'major', key: 'major' }

  @observable.ref octave: number = 4

  @observable.ref octaves: number = 1

  @observable.ref reverse: boolean = false
}
