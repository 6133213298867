import mobxRemotedev from 'mobx-remotedev'
import { makeObservable, observable } from 'mobx'

@mobxRemotedev
export class HeaderAuthFormStore {
  constructor() {
    makeObservable(this)
  }

  @observable.ref state: 'signin' | 'signup' | 'none' = 'none'
}
