import mobxRemotedev from 'mobx-remotedev'
import { makeObservable, observable } from 'mobx'

export const enum KeyboardAssistKind {
  NONE = 'none',
  NOTE = 'note',
  NOTE_AND_OCTAVE = 'note-and-octave',
  QWERTY = 'qwerty',
}

@mobxRemotedev
export class KeyboardAssistSelectorStore {
  constructor() {
    makeObservable(this)
  }

  @observable.ref selectedAssist: [KeyboardAssistKind, string] = [KeyboardAssistKind.QWERTY, 'Computer keyboard labels']

  @observable options: [KeyboardAssistKind, string][] = observable.array([
    [KeyboardAssistKind.NONE, 'No labels'],
    [KeyboardAssistKind.QWERTY, 'Computer keyboard labels'],
    [KeyboardAssistKind.NOTE, 'Just the note'],
    [KeyboardAssistKind.NOTE_AND_OCTAVE, 'Note and octave'],
  ])
}
