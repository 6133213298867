import * as React from 'react'
import { observer } from 'mobx-react'
import { VisualisationsStore } from 'components/visualisations/store'
import { VisualisationsController } from 'components/visualisations/controller'
import { VisualisationsPresenter } from 'components/visualisations/presenter'
import { NavButton } from 'components/base/buttons'
import { Singleton } from 'singleton'

export function createVisualisations({
  singleton,
  visualisationComponents,
}: {
  singleton: Singleton
  visualisationComponents: {
    Component: React.FunctionComponent
    label: string
  }[]
}) {
  const visualisationsStore = new VisualisationsStore()
  const visualisationsController = new VisualisationsController(visualisationsStore)
  const visualisationsPresenter = new VisualisationsPresenter(visualisationsStore)

  const Visualisations = observer(() => {
    if (singleton.layout.presenter.isMobile) {
      return <MobileVisualisations Elements={visualisationComponents} />
    }
    return <DesktopVisualisations Elements={visualisationComponents} />
  })

  return { Visualisations }
}

const DesktopVisualisations = ({ Elements }: { Elements: { Component: React.FunctionComponent; label: string }[] }) => {
  const [currentVisualisation, setCurrentVisualisation] = React.useState(Elements[0])
  return (
    <div
      id='DesktopLayout__Visualisations'
      className='flex flex-row flex-1 h-full'
    >
      <nav
        id='DesktopLayout__VisualisationTabs'
        className='relative flex flex-col items-start p-4 pt-8 border-r-2 border-gray-200 space-y-1'
        aria-label='Sidebar'
      >
        <SectionTitle id='VisualisationTabs__Title'>Visualisations</SectionTitle>
        {Elements.map((Element, i) => {
          const activeClass = Element !== currentVisualisation ? '' : 'bg-gray-200 text-indigo-900'

          return (
            <NavButton
              key={i}
              className={activeClass}
              onClick={() => setCurrentVisualisation(Element)}
            >
              {Element.label}
            </NavButton>
          )
        })}
      </nav>
      <div
        id='DesktopLayout__CurrentVisualisation'
        className='relative flex flex-col flex-1 h-full pt-8 overflow-x-hidden overflow-y-scroll'
      >
        <SectionTitle id='CurrentVisualisation__Title'>Current Visualisation</SectionTitle>
        <Visualisation Element={currentVisualisation} />
      </div>
    </div>
  )
}

const MobileVisualisations = ({ Elements }: { Elements: { Component: React.FunctionComponent; label: string }[] }) => {
  return (
    <div className='MobileLayout__Carousel'>
      {Elements.map(({ Component }, i) => (
        <Component key={i} />
      ))}
    </div>
  )
}

const Visualisation = ({
  Element,
}: {
  Element: {
    Component: React.FunctionComponent
    label: string
  }
}) => {
  const { Component, label } = Element
  return (
    <div className='flex flex-col w-full h-full p-4 pt-0'>
      <span className='font-bold'>{label}</span>
      <Component />
    </div>
  )
}

const SectionTitle = ({ id, children }: { id: string; children: React.ReactNode }) => (
  <div
    id={id}
    className='absolute top-0 left-0 p-1 text-xs bg-gray-200'
  >
    {children}
  </div>
)
