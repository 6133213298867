import mobxRemotedev from 'mobx-remotedev'
import { makeObservable, observable } from 'mobx'

@mobxRemotedev
export class PitchGraphStore {
  constructor() {
    makeObservable(this)
  }

  @observable.ref noop: undefined
}
