import mobxRemotedev from 'mobx-remotedev'
import { HomeStore } from 'pages/home/store'
import { action, makeObservable } from 'mobx'

@mobxRemotedev
export class HomeController {
  constructor(private readonly store: HomeStore) {
    makeObservable(this)
  }

  @action noop() {}
}
