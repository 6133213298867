import * as React from 'react'
import { observer } from 'mobx-react'
import { VolumeControlStore } from 'components/volume_control/store'
import { VolumeControlController } from 'components/volume_control/controller'
import { VolumeControlPresenter } from 'components/volume_control/presenter'
import { SpeakerIcon } from 'components/base/icons'
import { Sampler } from 'tone'
import { LightGreyBadge } from 'components/base/badges'

export function createVolumeControl({ Instrument }: { Instrument: Sampler }) {
  const volumeControlStore = new VolumeControlStore(Instrument.volume.value)
  const volumeControlController = new VolumeControlController(volumeControlStore, Instrument)
  const volumeControlPresenter = new VolumeControlPresenter(volumeControlStore)

  const VolumeControl = observer(() => {
    return (
      <div className='VolumeControl'>
        <label className='flex-1 block mb-2'>
          <p>
            <span className='font-bold'>Volume</span>
            <LightGreyBadge className='float-right w-16'> {volumeControlPresenter.volume} dB</LightGreyBadge>
          </p>
        </label>
        <div className='flex flex-row items-center w-full mt-4'>
          <div className='flex-grow-0 flex-shrink-0 pr-2 text-gray-600'>
            <SpeakerIcon
              className='w-4 h-4'
              outline={true}
            />
          </div>
          <input
            className='flex-grow w-full'
            type='range'
            value={volumeControlPresenter.volume}
            onChange={volumeControlController.setVolume}
            min={-60}
            max={0}
          />
        </div>
      </div>
    )
  })

  return { VolumeControl }
}
