import mobxRemotedev from 'mobx-remotedev'
import { VisualisationsStore } from 'components/visualisations/store'
import { computed, makeObservable } from 'mobx'

@mobxRemotedev
export class VisualisationsPresenter {
  constructor(private readonly store: VisualisationsStore) {
    makeObservable(this)
  }

  @computed get noop(): undefined {
    return undefined
  }
}
