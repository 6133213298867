import * as React from 'react'
import { observer } from 'mobx-react'
import KeyboardPresenter from 'components/keyboard/presenter'

export function createVelocityGraph(keyboardPresenter: KeyboardPresenter) {
  const C = observer(() => {
    return <VelocityGraph velocities={keyboardPresenter.velocities} />
  })

  return { VelocityGraph: C }
}

export const VelocityGraph = ({ velocities }: { velocities: number[] }) => {
  return (
    <div>
      {velocities.map((v, i) => (
        <span
          key={i}
          style={{ marginLeft: '15px' }}
        >
          {(v * 100).toFixed(0)}
        </span>
      ))}
    </div>
  )
}
