import { computed } from 'mobx'
import { RecordingController } from 'modules/recording/controller'
import { RecordingPresenter } from 'modules/recording/presenter'
import { PlayingState } from 'modules/recording/store'
import { assert, assertPlayable } from 'modules/recording/utilities'

export class Recording {
  constructor(private readonly controller: RecordingController, private readonly presenter: RecordingPresenter) {}

  public readonly play = () => {
    assertPlayable(this.presenter.state)
    this.controller.setPlaying()
    this.bindToAudio()
    this.presenter.audio.play()
  }

  public readonly pause = () => {
    assert<PlayingState>(this.presenter.state, 'playing')
    this.controller.setPaused()
    this.bindToAudio()
    this.presenter.audio.play()
  }

  public readonly download = () => {
    assertPlayable(this.presenter.state)
    const { blob } = this.presenter
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = URL.createObjectURL(blob)
    a.download = 'download'
    a.click()
    URL.revokeObjectURL(url)
  }

  public setRecording = this.controller.setRecording

  public setRecorded = this.controller.setRecorded

  public setFromDownloadedData = this.controller.setFromDownloadedData

  public addData = this.controller.addData

  public setTime = this.controller.setTime

  @computed get isPlayable(): boolean {
    return this.presenter.isPlayable
  }

  @computed get isPlaying(): boolean {
    return this.presenter.isPlaying
  }

  @computed get currentTime(): number {
    return this.presenter.currentTime
  }

  @computed get key(): string {
    return this.presenter.key
  }

  @computed get audio(): HTMLAudioElement {
    return this.presenter.audio
  }

  @computed get blob(): Blob {
    return this.presenter.blob
  }

  @computed get size(): number {
    return this.presenter.blob.size
  }

  @computed get duration(): number {
    return this.presenter.duration
  }

  private bindToAudio() {
    const { audio } = this.presenter

    audio.ontimeupdate = () => {
      this.controller.setPlaying()
    }

    audio.onended = () => {
      audio.ontimeupdate = null
      audio.currentTime = 0.01
      this.controller.setPaused()
    }
  }
}
