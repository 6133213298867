import * as React from 'react'
import { observer } from 'mobx-react'
import { AudioNodeManagerStore } from 'components/audio_node_manager/store'
import { AudioNodeManagerController } from 'components/audio_node_manager/controller'
import { AudioNodeManagerPresenter } from 'components/audio_node_manager/presenter'
import { PipelineSection } from 'components/audio_node_manager/pipeline_section'
import { Select } from 'components/base/selects'

export function createAudioNodeManager() {
  const audioNodeManagerStore = new AudioNodeManagerStore()
  const audioNodeManagerController = new AudioNodeManagerController(audioNodeManagerStore)
  const audioNodeManagerPresenter = new AudioNodeManagerPresenter(audioNodeManagerStore)

  const AudioNodeManager = observer(() => {
    return (
      <div>
        <div className='font-bold'>Audio Node Manager</div>
        {audioNodeManagerPresenter.options.length > 0 && (
          <Select
            options={audioNodeManagerPresenter.options}
            defaultOption={audioNodeManagerPresenter.selectedPipeline}
            onChange={(o) => audioNodeManagerController.selectPipeline(o.value)}
          />
        )}
        {audioNodeManagerPresenter.currentPipeline != null && (
          <PipelineSection
            createNode={audioNodeManagerController.createNode}
            addNode={audioNodeManagerPresenter.currentPipeline.add}
            pipeline={audioNodeManagerPresenter.currentPipeline}
          />
        )}
      </div>
    )
  })

  return { AudioNodeManager, audioNodeManagerController }
}
