export const PianoSamplePaths = {
  A1: 'piano/A1.mp3',
  A2: 'piano/A2.mp3',
  A3: 'piano/A3.mp3',
  A4: 'piano/A4.mp3',
  A5: 'piano/A5.mp3',
  A6: 'piano/A6.mp3',
  'A#1': 'piano/As1.mp3',
  'A#2': 'piano/As2.mp3',
  'A#3': 'piano/As3.mp3',
  'A#4': 'piano/As4.mp3',
  'A#5': 'piano/As5.mp3',
  'A#6': 'piano/As6.mp3',
  B1: 'piano/B1.mp3',
  B2: 'piano/B2.mp3',
  B3: 'piano/B3.mp3',
  B4: 'piano/B4.mp3',
  B5: 'piano/B5.mp3',
  B6: 'piano/B6.mp3',
  C1: 'piano/C1.mp3',
  C2: 'piano/C2.mp3',
  C3: 'piano/C3.mp3',
  C4: 'piano/C4.mp3',
  C5: 'piano/C5.mp3',
  C6: 'piano/C6.mp3',
  C7: 'piano/C7.mp3',
  'C#1': 'piano/Cs1.mp3',
  'C#2': 'piano/Cs2.mp3',
  'C#3': 'piano/Cs3.mp3',
  'C#4': 'piano/Cs4.mp3',
  'C#5': 'piano/Cs5.mp3',
  'C#6': 'piano/Cs6.mp3',
  D1: 'piano/D1.mp3',
  D2: 'piano/D2.mp3',
  D3: 'piano/D3.mp3',
  D4: 'piano/D4.mp3',
  D5: 'piano/D5.mp3',
  D6: 'piano/D6.mp3',
  'D#1': 'piano/Ds1.mp3',
  'D#2': 'piano/Ds2.mp3',
  'D#3': 'piano/Ds3.mp3',
  'D#4': 'piano/Ds4.mp3',
  'D#5': 'piano/Ds5.mp3',
  'D#6': 'piano/Ds6.mp3',
  E1: 'piano/E1.mp3',
  E2: 'piano/E2.mp3',
  E3: 'piano/E3.mp3',
  E4: 'piano/E4.mp3',
  E5: 'piano/E5.mp3',
  E6: 'piano/E6.mp3',
  F1: 'piano/F1.mp3',
  F2: 'piano/F2.mp3',
  F3: 'piano/F3.mp3',
  F4: 'piano/F4.mp3',
  F5: 'piano/F5.mp3',
  F6: 'piano/F6.mp3',
  'F#1': 'piano/Fs1.mp3',
  'F#2': 'piano/Fs2.mp3',
  'F#3': 'piano/Fs3.mp3',
  'F#4': 'piano/Fs4.mp3',
  'F#5': 'piano/Fs5.mp3',
  'F#6': 'piano/Fs6.mp3',
  G1: 'piano/G1.mp3',
  G2: 'piano/G2.mp3',
  G3: 'piano/G3.mp3',
  G4: 'piano/G4.mp3',
  G5: 'piano/G5.mp3',
  G6: 'piano/G6.mp3',
  'G#1': 'piano/Gs1.mp3',
  'G#2': 'piano/Gs2.mp3',
  'G#3': 'piano/Gs3.mp3',
  'G#4': 'piano/Gs4.mp3',
  'G#5': 'piano/Gs5.mp3',
  'G#6': 'piano/Gs6.mp3',
}
