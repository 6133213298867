import mobxRemotedev from 'mobx-remotedev'
import { makeObservable, observable, ObservableMap } from 'mobx'

const minKey = 36
const nKeys = 61

const initialKeys = new Map<number, boolean>(
  Array(nKeys)
    .fill(true)
    .map((_, i) => [i + minKey, false]),
)

@mobxRemotedev
export default class KeyboardStore {
  constructor() {
    makeObservable(this)
  }

  @observable.ref loading: boolean = false
  @observable.ref velocity: number = 0
  @observable.ref notePlayed: string = ''
  @observable.ref enableQwerty: boolean = true
  @observable.ref enableKeyboard: boolean = true
  @observable.ref enableGuiKeyboard: boolean = true
  velocities: number[] = observable.array([], { deep: false })
  notesPlayed: string[] = observable.array([], { deep: false })
  timestamps: number[] = observable.array([], { deep: false })
  keys: ObservableMap<number, boolean> = observable.map(initialKeys)
}
