import remotedev from 'mobx-remotedev'
import { observable, makeObservable } from 'mobx'

@remotedev
export class AppStore {
  constructor() {
    makeObservable(this)
  }

  @observable.ref user: { email: string; password: string; token: string }

  @observable.ref midiAccess: WebMidi.MIDIAccess | undefined
}
