import * as React from 'react'
import { observer } from 'mobx-react'
import { HeaderAuthFormStore } from 'components/header_auth_form/store'
import { HeaderAuthFormController } from 'components/header_auth_form/controller'
import { HeaderAuthFormPresenter } from 'components/header_auth_form/presenter'
import { TextButton } from 'components/base/buttons'
import { MobileModal } from 'components/header_auth_form/mobile_modal'
import { createProfileDropdown } from '../profile_dropdown'
import { Singleton } from 'singleton'
import { AuthUi } from 'components/auth'

export function createHeaderAuthForm(singleton: Singleton, authUi: AuthUi) {
  const store = new HeaderAuthFormStore()
  const controller = new HeaderAuthFormController(store)
  const presenter = new HeaderAuthFormPresenter(store)

  const userPresenter = singleton.user.presenter
  const userController = singleton.user.controller
  const layoutPresenter = singleton.layout.presenter

  const { ProfileDropdown } = createProfileDropdown(singleton)

  const { StackedSignInForm, InlineSignInForm, StackedSignUpForm, InlineSignUpForm } = authUi
  const SignOutForm = () => <TextButton onClick={() => userController.signOut()}>Sign Out</TextButton>
  const ShowSignIn = () => <TextButton onClick={() => controller.showSignIn()}>Sign In</TextButton>
  const ShowSignUp = () => <TextButton onClick={() => controller.showSignUp()}>Sign Up</TextButton>

  const MobileLogin = observer(() => {
    if (userPresenter.isSignedIn) {
      return <SignOutForm />
    }

    if (presenter.showSignIn) {
      return (
        <>
          <StackedSignInForm /> <ShowSignUp />
        </>
      )
    }
    if (presenter.showSignUp) {
      return (
        <>
          <StackedSignUpForm /> <ShowSignIn />
        </>
      )
    }
    return (
      <div className='flex flex-row items-center'>
        <ShowSignIn /> / <ShowSignUp />
      </div>
    )
  })

  const DesktopContent = observer(() => {
    if (userPresenter.isSignedIn) {
      return <ProfileDropdown />
    } else if (presenter.showSignIn) {
      return (
        <>
          <InlineSignInForm /> <ShowSignUp />
        </>
      )
    } else if (presenter.showSignUp) {
      return (
        <>
          <InlineSignUpForm /> <ShowSignIn />
        </>
      )
    } else {
      return (
        <>
          <ShowSignIn /> / <ShowSignUp />
        </>
      )
    }
  })

  const InlineContent = () => (
    <div className='flex flex-row items-center float-right w-full'>
      <DesktopContent />
    </div>
  )

  const MobileContent = observer(() => {
    if (userPresenter.isSignedIn) {
      return <ProfileDropdown />
    }
    return (
      <MobileModal>
        <div className='flex flex-col items-center w-full'>
          <MobileLogin />
        </div>
      </MobileModal>
    )
  })

  const HeaderAuthForm = observer(() => {
    if (layoutPresenter.isDesktop) {
      return <InlineContent />
    }
    return <MobileContent />
  })

  return { HeaderAuthForm }
}
