import mobxRemotedev from 'mobx-remotedev'
import { SingleRecordingStore } from 'components/single_recording/store'
import { computed, makeObservable } from 'mobx'

@mobxRemotedev
export class SingleRecordingPresenter {
  constructor(private readonly store: SingleRecordingStore) {
    makeObservable(this)
  }

  @computed get noop(): undefined {
    return undefined
  }
}
