import * as React from 'react'

const UnpressedBlackKey = ({
  octave,
  note,
  label,
  onClick,
}: {
  octave: number
  note: string
  label: string
  onClick(): void
}) => {
  const isPadded = note === 'C#' || note === 'F#'
  const margin = isPadded ? 'ml-8' : ''
  return (
    <div
      className={`inline-block w-8 h-3/4 text-center ${margin} first:ml-0 cursor-pointer pointer-events-auto`}
      onClick={onClick}
      data-testid={testId(note, octave)}
    >
      <div
        className='h-full pb-1 bg-gray-900 rounded-t-sm rounded-b-md'
        style={{ width: '70%', margin: '0 auto' }}
      >
        <div className='flex items-end justify-center h-full px-1 text-white border border-t-0 border-gray-500 rounded-sm bg-gradient-to-l from-gray-800 to-gray-700'>
          {label}
        </div>
      </div>
    </div>
  )
}

const PressedBlackKey = ({
  octave,
  note,
  label,
  onClick,
}: {
  octave: number
  note: string
  label: string
  onClick(): void
}) => {
  const isPadded = note === 'C#' || note === 'F#'
  const margin = isPadded ? 'ml-8' : ''
  return (
    <div
      data-testid={testId(note, octave)}
      className={`inline-block w-8 h-3/4 text-center ${margin} first:ml-0 pointer-events-auto cursor-pointer`}
      onClick={onClick}
    >
      <div
        className='h-full pb-1 rounded-t-sm rounded-b-md bg-gradient-to-b from-yellow-300 to-yellow-500'
        style={{ width: '70%', margin: '0 auto' }}
      >
        <div className='flex items-end justify-center h-full px-1 text-white border border-t-0 border-yellow-300 rounded-sm bg-gradient-to-l from-yellow-500 to-yellow-400'>
          {label}
        </div>
      </div>
    </div>
  )
}
export const BlackKey = React.memo(
  ({
    note,
    octave,
    label,
    pressed,
    onClick,
  }: {
    note: string
    octave: number
    label: string
    pressed: boolean
    onClick(): void
  }) => {
    if (pressed) {
      return (
        <PressedBlackKey
          note={note}
          label={label}
          onClick={onClick}
          octave={octave}
        />
      )
    } else {
      return (
        <UnpressedBlackKey
          note={note}
          label={label}
          onClick={onClick}
          octave={octave}
        />
      )
    }
  },
)

const UnpressedWhiteKey = ({
  note,
  octave,
  onClick,
  label,
}: {
  note: string
  octave: number
  label: string
  onClick(): void
}) => (
  <div
    className='inline-flex items-end justify-center w-8 h-full text-center border border-t-0 border-gray-400 cursor-pointer pointer-events-auto rounded-md bg-gradient-to-b to-gray-200 from-white'
    onClick={onClick}
    data-testid={testId(note, octave)}
  >
    {label}
  </div>
)
const PressedWhiteKey = ({
  note,
  octave,
  onClick,
  label,
}: {
  note: string
  octave: number
  label: string
  onClick(): void
}) => (
  <div
    className='inline-flex items-end justify-center w-8 h-full text-center text-white border border-t-0 border-yellow-200 shadow-inner cursor-pointer pointer-events-auto rounded-md bg-gradient-to-b from-yellow-300 to-yellow-500'
    onClick={onClick}
    data-testid={testId(note, octave)}
  >
    {label}
  </div>
)
export const WhiteKey = React.memo(
  ({
    note,
    octave,
    label,
    pressed,
    onClick,
  }: {
    note: string
    octave: number
    label: string
    pressed: boolean
    onClick(): void
  }) => {
    if (pressed) {
      return (
        <PressedWhiteKey
          octave={octave}
          note={note}
          label={label}
          onClick={onClick}
        />
      )
    } else {
      return (
        <UnpressedWhiteKey
          octave={octave}
          note={note}
          label={label}
          onClick={onClick}
        />
      )
    }
  },
)

function testId(note: string, octave: number): string {
  return 'keyboard-key-' + note + octave
}
