import mobxRemotedev from 'mobx-remotedev'
import { AudioNodeManagerStore } from 'components/audio_node_manager/store'
import { computed, makeObservable } from 'mobx'
import { AudioNodePipeline } from 'components/audio_node_manager/audio_node_pipeline'

@mobxRemotedev
export class AudioNodeManagerPresenter {
  constructor(private readonly store: AudioNodeManagerStore) {
    makeObservable(this)
  }

  @computed get currentPipeline(): AudioNodePipeline | undefined {
    return this.store.pipelines.get(this.store.selectedPipeline)
  }

  @computed get selectedPipeline(): { key: string; value: AudioNodePipeline } | undefined {
    if (this.store.selectedPipeline == null) {
      return
    }
    return {
      key: this.store.selectedPipeline,
      value: this.store.pipelines.get(this.store.selectedPipeline),
    }
  }

  @computed get options(): { key: string; value: string }[] {
    const pipelines = Array.from(this.store.pipelines.entries())
    return pipelines.map(([name]) => ({ key: name, value: name }))
  }
}
