import mobxRemotedev from 'mobx-remotedev'
import { VolumeControlStore } from 'components/volume_control/store'
import { computed, makeObservable } from 'mobx'

@mobxRemotedev
export class VolumeControlPresenter {
  constructor(private readonly store: VolumeControlStore) {
    makeObservable(this)
  }

  @computed get volume(): number {
    return this.store.volume
  }
}
