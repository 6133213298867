import { AppStore } from 'app/store'
import { action, makeAutoObservable } from 'mobx'

export class AppController {
  constructor(private readonly store: AppStore) {
    makeAutoObservable(this)
    this.getMidiAccess()
  }

  @action setUser(user: { email: string; password: string; token: string }) {
    this.store.user = user
  }

  private readonly getMidiAccess = async () => {
    const access = await this.requestMidiAccess()
    this.setMidiAccess(access)
  }

  private setMidiAccess = (access: WebMidi.MIDIAccess) => {
    this.store.midiAccess = access
  }

  private requestMidiAccess = (): Promise<WebMidi.MIDIAccess> => {
    return navigator.requestMIDIAccess({ sysex: true })
  }
}
