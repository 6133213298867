import mobxRemotedev from 'mobx-remotedev'
import * as React from 'react'
import { action, makeObservable } from 'mobx'
import { MetronomeStore } from './store'

@mobxRemotedev
export class MetronomeController {
  constructor(private readonly store: MetronomeStore) {
    makeObservable(this)
  }

  @action.bound setBpm(e: React.ChangeEvent<HTMLInputElement>) {
    const value = parseInt(e.target.value)
    this.store.bpm = isNaN(value) ? undefined : value
  }

  @action.bound setVolume(e: React.ChangeEvent<HTMLInputElement>) {
    const value = parseInt(e.target.value)
    if (!isNaN(value)) {
      this.store.volume = value
    }
  }

  @action.bound stop() {
    this.store.state = 'off'
  }

  @action.bound start() {
    this.store.state = 'on'
  }
}
