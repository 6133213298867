import mobxRemotedev from 'mobx-remotedev'
import { RecordingToolStore } from 'components/recording_tool/store'
import { computed, makeObservable } from 'mobx'

@mobxRemotedev
export class RecordingToolPresenter {
  constructor(private readonly store: RecordingToolStore) {
    makeObservable(this)
  }

  @computed get noop(): undefined {
    return undefined
  }
}
