import mobxRemotedev from 'mobx-remotedev'
import { LayoutStore, DeviceKind, Layout, OrientationKind } from 'components/layout/store'
import { makeObservable, computed } from 'mobx'

@mobxRemotedev
export class LayoutPresenter {
  constructor(private readonly store: LayoutStore) {
    makeObservable(this)
  }

  @computed get layout(): Layout {
    return this.store.layout
  }

  @computed get device(): DeviceKind {
    return this.store.layout.device
  }

  @computed get orientation(): OrientationKind {
    return this.store.layout.orientation
  }

  @computed get isMobile(): boolean {
    return this.store.layout.device === DeviceKind.MOBILE
  }

  @computed get isTablet(): boolean {
    return this.store.layout.device === DeviceKind.TABLET
  }

  @computed get isDesktop(): boolean {
    return this.store.layout.device === DeviceKind.DESKTOP
  }
}
