import mobxRemotedev from 'mobx-remotedev'
import { DashboardStore } from 'pages/dashboard/store'
import { action, makeObservable } from 'mobx'

@mobxRemotedev
export class DashboardController {
  constructor(private readonly store: DashboardStore) {
    makeObservable(this)
  }

  @action noop() {}
}
