import * as React from 'react'
import { observer } from 'mobx-react'
import { LayoutStore, DeviceKind, OrientationKind } from 'components/layout/store'
import { LayoutController } from 'components/layout/controller'
import { LayoutPresenter } from 'components/layout/presenter'
import { MobileLayout } from 'components/layout/mobile'
import { DesktopLayout } from 'components/layout/desktop'

export type LayoutProps = {
  Instruments: React.FunctionComponent[]
  Tools: React.FunctionComponent[]
  Visualisations: React.ComponentType<{}>
}

export type InternalLayoutProps = LayoutProps & {
  device: DeviceKind
  orientation: OrientationKind
}

export function createLayout() {
  const store = new LayoutStore()
  new LayoutController(store)
  const layoutPresenter = new LayoutPresenter(store)

  const Layout = observer((p: LayoutProps) => {
    const { device, orientation } = layoutPresenter.layout
    switch (layoutPresenter.device) {
      case DeviceKind.MOBILE:
        return (
          <MobileLayout
            device={device}
            orientation={orientation}
            {...p}
          />
        )
      case DeviceKind.TABLET:
        return (
          <DesktopLayout
            device={device}
            orientation={orientation}
            {...p}
          />
        )
      case DeviceKind.DESKTOP:
        return (
          <DesktopLayout
            device={device}
            orientation={orientation}
            {...p}
          />
        )
      default:
        throw new Error('layout is broken')
    }
  })

  return { Layout, layoutPresenter }
}
