import { HeaderAuthFormStore } from 'components/header_auth_form/store'
import { action, makeObservable } from 'mobx'
import mobxRemotedev from 'mobx-remotedev'

@mobxRemotedev
export class HeaderAuthFormController {
  constructor(private readonly store: HeaderAuthFormStore) {
    makeObservable(this)
  }

  @action showSignIn() {
    this.store.state = 'signin'
  }

  @action showSignUp() {
    this.store.state = 'signup'
  }
}
