import * as React from 'react'
import { default as Hammer } from 'hammerjs'
import { InternalLayoutProps } from 'components/layout'
import './mobile.css'

export const MobileLayout = (p: InternalLayoutProps) => {
  const { Visualisations } = p
  return (
    <div className='MobileLayout'>
      <Drawer Tools={p.Tools} />
      <div id='MobileLayout__VisualisationContainer'>
        <Visualisations />
      </div>
      <Carousel Elements={p.Instruments} />
    </div>
  )
}

const Drawer = ({ Tools }: { Tools: React.FunctionComponent[] }) => {
  const [drawer, hammer] = useHammer()
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    if (hammer.current == null) {
      return
    }
    const hammertime = hammer.current
    hammertime.get('pan').set({ direction: Hammer.DIRECTION_VERTICAL })
    hammertime.get('swipe').set({ direction: Hammer.DIRECTION_ALL })
    hammertime.on('pan', ({ direction }) => setOpen(direction === Hammer.DIRECTION_DOWN))
  }, [hammer])

  const translate = open ? window.innerHeight : 20

  return (
    <div
      className='MobileLayout__Drawer'
      ref={drawer}
      style={{ transform: `translate3d(0, ${translate}px, 0)` }}
    >
      <div className='Drawer__Body'>
        {Tools.map((Tool, i) => (
          <Tool key={i} />
        ))}
      </div>
      <div className='Drawer__Handle'>Open Me</div>
    </div>
  )
}

const Carousel = ({ Elements }: { Elements: React.FunctionComponent[] }) => {
  return (
    <div className='MobileLayout__Carousel'>
      {Elements.map((Element, i) => (
        <Element key={i} />
      ))}
    </div>
  )
}

function useHammer(): [React.RefObject<any>, React.RefObject<any>] {
  const ref = React.useRef(null)
  const hammer = React.useRef(null)

  React.useEffect(() => {
    if (ref.current == null || hammer.current != null) {
      return
    }
    hammer.current = new Hammer(ref.current, {})
  }, [ref, hammer])

  return [ref, hammer]
}
