import mobxRemotedev from 'mobx-remotedev'
import { makeObservable, observable } from 'mobx'

@mobxRemotedev
export class RecordingStore {
  constructor() {
    makeObservable(this)
  }

  public readonly timestamp: Date = new Date()
  @observable.ref public state: State = { kind: 'init' }
}

export type InitState = { kind: 'init' }
export type RecordingState = { kind: 'recording'; events: BlobEvent[] }
export type RecordedState = {
  kind: 'recorded'
  audio: HTMLAudioElement
  blob: Blob
  currentTime: number
  duration: number
}
export type PlayingState = {
  kind: 'playing'
  audio: HTMLAudioElement
  blob: Blob
  currentTime: number
  duration: number
}
export type PausedState = {
  kind: 'paused'
  audio: HTMLAudioElement
  blob: Blob
  currentTime: number
  duration: number
}
export type State = InitState | RecordingState | RecordedState | PlayingState | PausedState
