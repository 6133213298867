import * as React from 'react'
import { observer } from 'mobx-react'
import { MetronomeStore } from './store'
import { MetronomeController } from './controller'
import { MetronomePresenter } from './presenter'
import { MetronomePlayer } from './player'
import { NumberInput } from 'components/base/inputs'
import { PrimaryButton } from 'components/base/buttons'
import { SpeakerIcon } from 'components/base/icons'
import { LightGreyBadge } from 'components/base/badges'

export const enum MetronomeState {
  ON = 1,
  OFF,
}

export function createMetronome() {
  const metronomeStore = new MetronomeStore()
  const metronomeController = new MetronomeController(metronomeStore)
  const metronomePresenter = new MetronomePresenter(metronomeStore)
  new MetronomePlayer(metronomeStore)

  const C = observer(() => {
    return (
      <Metronome
        on={metronomePresenter.playing}
        bpm={metronomePresenter.bpm}
        onBpmChange={metronomeController.setBpm}
        onVolumeChange={metronomeController.setVolume}
        onStartClick={metronomeController.start}
        onStopClick={metronomeController.stop}
        volume={metronomePresenter.volume}
      />
    )
  })
  return { Metronome: C }
}

export const Metronome = ({
  on,
  bpm,
  onBpmChange,
  onVolumeChange,
  onStartClick,
  onStopClick,
  volume,
}: {
  on: boolean
  bpm: number
  onBpmChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onVolumeChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onStartClick: () => void
  onStopClick: () => void
  volume: number
}) => {
  return (
    <div id='Metronome'>
      <label
        className='flex-1 block mb-2'
        htmlFor='Metronome__input'
      >
        <p>
          <span className='font-bold'>Metronome</span>
          <LightGreyBadge className='float-right w-16'> {volume} dB</LightGreyBadge>
        </p>
      </label>
      <div className='flex flex-row w-full'>
        <NumberInput
          id='Metronome__input'
          className='rounded-l-md'
          value={bpm}
          onChange={(v) => onBpmChange(v)}
        />
        {on && (
          <PrimaryButton
            className='rounded-r-md'
            onClick={() => onStopClick()}
          >
            Stop
          </PrimaryButton>
        )}
        {!on && (
          <PrimaryButton
            className='rounded-r-md'
            onClick={() => onStartClick()}
          >
            Start
          </PrimaryButton>
        )}
      </div>
      <div className='flex flex-row items-center w-full mt-4'>
        <div className='flex-grow-0 flex-shrink-0 pr-2 text-gray-600'>
          <SpeakerIcon
            className='w-4 h-4'
            outline={true}
          />
        </div>
        <input
          className='flex-grow w-full'
          type='range'
          value={volume}
          onChange={onVolumeChange}
          min={-60}
          max={0}
        />
      </div>
    </div>
  )
}

const volumes = [
  { label: 'Inaudible', value: -40 },
  { label: 'Barely Audible', value: -30 },
  { label: 'Very Quiet', value: -25 },
  { label: 'Quiet', value: -20 },
  { label: 'Small', value: -15 },
  { label: 'Medium', value: -10 },
  { label: 'Large', value: -5 },
  { label: 'Loud', value: 0 },
  { label: 'Very Loud', value: 4 },
  { label: 'Protect Your Ears', value: 10 },
  { label: 'Screaming', value: 15 },
  { label: "My Laptop Doesn't Support This", value: 20 },
]
