import { MicrophoneRecorderStore } from './store'
import { MicrophoneRecorderController } from './controller'
import { MicrophoneRecorderPresenter } from './presenter'
import { MicrophoneDeviceController } from 'modules/microphone_device/controller'
import { MicrophoneDevicePresenter } from 'modules/microphone_device/presenter'

export function createMicrophoneRecorder(
  microphoneDeviceController: MicrophoneDeviceController,
  microphoneDevicePresenter: MicrophoneDevicePresenter,
) {
  const microphoneRecorderStore = new MicrophoneRecorderStore()
  const microphoneRecorderController = new MicrophoneRecorderController(
    microphoneRecorderStore,
    microphoneDeviceController,
    microphoneDevicePresenter,
  )
  const microphoneRecorderPresenter = new MicrophoneRecorderPresenter(microphoneRecorderStore)

  return {
    microphoneRecorderController,
    microphoneRecorderPresenter,
  }
}

export type MicrophoneRecoderModule = {
  microphoneRecorderController: MicrophoneRecorderController
  microphoneRecorderPresenter: MicrophoneRecorderPresenter
}
