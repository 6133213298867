import mobxRemotedev from 'mobx-remotedev'
import { SoundExercisePlayerStore } from "components/sound-exercise-player/store"
import { computed, makeObservable } from "mobx"

@mobxRemotedev export class SoundExercisePlayerPresenter {
  constructor(private readonly store: SoundExercisePlayerStore) {
    makeObservable(this)
  }

  @computed get noop(): undefined {
    return undefined
  }
}
