import { MicrophoneIcon as SolidMicrophoneIcon } from '@heroicons/react/solid'
import { MicrophoneIcon as OutlineMicrophoneIcon } from '@heroicons/react/outline'
import { PrimaryButton } from 'components/base/buttons'
import { observer } from 'mobx-react'
import * as React from 'react'

const RecordButton = ({
  onStartClick,
  onStopClick,
  isRecording,
}: {
  onStartClick(): void
  onStopClick(): void
  isRecording: boolean
}) => {
  if (isRecording) {
    return <PrimaryButton onClick={onStopClick}>Stop</PrimaryButton>
  }
  return <PrimaryButton onClick={onStartClick}>Start</PrimaryButton>
}

const MicrophoneIcon = ({ isRecording }: { isRecording: boolean }) => {
  if (isRecording) {
    return <SolidMicrophoneIcon className='w-5 h-5 text-red-500' />
  } else {
    return <OutlineMicrophoneIcon className='w-5 h-5' />
  }
}

export const RecordingTool = observer(
  ({
    isRecording,
    onStartClick,
    onStopClick,
    onMount,
    onUnmount,
    Recordings,
  }: {
    onStartClick(): void
    onStopClick(): void
    isRecording: boolean
    onMount(): void
    onUnmount(): void
    Recordings: React.ComponentType<{}>
  }) => {
    React.useEffect(() => {
      onMount()
      return onUnmount
    })

    return (
      <div>
        <div className='flex flex-row items-center w-full'>
          <p className='mt-1 mb-2 text-sm italic text-gray-400'>You can hit spacebar to start and stop recording</p>
        </div>
        <div className='flex flex-row items-center w-full'>
          <div className='flex-shrink-0 pr-2 text-gray-600 flex-grow-0-'>
            <MicrophoneIcon isRecording={isRecording} />
          </div>
          <RecordButton isRecording={isRecording} onStopClick={onStopClick} onStartClick={onStartClick} />
        </div>
        <Recordings />
      </div>
    )
  },
)
