import mobxRemotedev from 'mobx-remotedev'
import { makeObservable, observable } from 'mobx'
import { AudioNodePipeline } from 'components/audio_node_manager/audio_node_pipeline'
import { AudioNodeFactory } from 'components/audio_node_manager/audio_node_constructor'

@mobxRemotedev
export class AudioNodeManagerStore {
  constructor() {
    makeObservable(this)
  }

  @observable.ref audioContext: AudioContext | undefined
  @observable.ref nodeFactory: AudioNodeFactory | undefined

  @observable pipelines: Map<string, AudioNodePipeline> = observable.map(new Map())

  @observable.ref selectedPipeline: string | undefined
}
