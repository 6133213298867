import { HttpService } from './http_service'

export class UserService {
  serviceUrl: string = '/user'

  constructor(private readonly httpService: HttpService) {}

  public setBearerToken(token: string | null): void {
    this.httpService.setBearerToken(token)
  }

  async signUp({ email, password }: { email: string; password: string }): Promise<any> {
    const path = `${this.serviceUrl}/create-user`
    const body = { email, password }
    const response = await this.httpService.post({ path, body })
    if (response.status !== 200) {
      return { data: null, error: `Failed to sign up: ${response.status}` }
    }
    const data = await response.json()
    const error = null
    this.httpService.setBearerToken(data.token)
    return { data, error }
  }

  async signIn({ email, password }: { email: string; password: string }): Promise<any> {
    const path = `${this.serviceUrl}/log-in`
    const body = { email, password }
    const response = await this.httpService.post({ path, body })
    if (response.status !== 200) {
      return { data: null, error: `Failed to sign in: ${response.status}` }
    }
    const data = await response.json()
    const error = null
    this.httpService.setBearerToken(data.token)
    return { data, error }
  }

  async signOut(): Promise<any> {
    const path = `${this.serviceUrl}/log-out`
    const response = await this.httpService.post({ path, body: {} })
    if (response.status !== 200) {
      const data = null
      const error = `Failed to sign out: ${response.status}`
      return { data, error }
    }
    const data = null
    const error = null
    this.httpService.setBearerToken(null)
    return { data, error }
  }
}
