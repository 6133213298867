import * as Tone from 'tone'
import KeyboardController from './controller'
import KeyboardPresenter from './presenter'
import { Piano } from 'instruments/piano'
import { keyNumberToNoteAndOctave } from 'utilities/music_utilities'

export default class GuiKeyboardPlayer {
  keydown: any
  keyup: any

  constructor(
    private readonly keyboardController: KeyboardController,
    private readonly keyboardPresenter: KeyboardPresenter,
  ) {}

  public readonly onClick = (key: number) => {
    const note = keyNumberToNoteAndOctave(key)
    if (!note) return
    if (this.keyboardPresenter.keyIsPressed(key)) {
      return
    }
    // see https://github.com/Tonejs/Tone.js/issues/306
    const time = Tone.context.currentTime // immediately fires thei note
    // 127 is a magic number. the largest value is 127, we need it to be 100
    const normalizedVelocity = 0.25

    this.keyboardController.setKeyDown(key, normalizedVelocity)
    setTimeout(() => this.keyboardController.setKeyUp(key), 200)
    Piano.triggerAttackRelease(note, '2m', time, normalizedVelocity)
  }
}
