import mobxRemotedev from 'mobx-remotedev'
import { PitchGraphStore } from 'components/pitch_graph/store'
import { makeObservable, computed } from 'mobx'
import { keyNumberToNote, keyNumberToOctave } from 'utilities/music_utilities'

@mobxRemotedev
export class PitchGraphPresenter {
  constructor(private readonly store: PitchGraphStore) {
    makeObservable(this)
  }

  @computed get noop() {
    return this.store.noop
  }

  public closestFrequency(hz: number): [string, number, number] {
    const closestIndex = this.findClosestIndex(hz)
    const correspondingNote = this.getNoteFromIndex(closestIndex)
    const percentageAway = this.getPercentageFromNote(hz, closestIndex)
    const octave = this.getOctaveFromIndex(closestIndex)
    return [correspondingNote, octave, percentageAway]
  }

  private findClosestIndex(hz: number) {
    const oneBigger = this.findFirstBiggerIndex(hz)
    if (oneBigger === 1) {
      return oneBigger
    }
    const oneSmaller = oneBigger - 1
    const d1 = NOTE_FREQUENCIES[oneBigger] - hz
    const d2 = hz - NOTE_FREQUENCIES[oneSmaller]
    if (d1 > d2) {
      return oneSmaller
    } else {
      return oneBigger
    }
  }

  private findFirstBiggerIndex(hz: number): number {
    const i = NOTE_FREQUENCIES.findIndex((n) => hz < n)
    return i === -1 ? NOTE_FREQUENCIES.length : i
  }

  private getNoteFromIndex(n: number): string {
    const note = keyNumberToNote(n)
    return note
  }

  private getOctaveFromIndex(n: number): number {
    //  12 + because this function was written for midi not this usage
    const octave = keyNumberToOctave(12 + n)
    return octave
  }

  private getPercentageFromNote(hz: number, index: number) {
    const noteLog = Math.log(hz)
    const comparisonNote = NOTE_LOGS[index]
    const distance = Math.abs(noteLog - comparisonNote)
    const percentage = Number(((distance / LOG_STEP_SIZE) * 100).toFixed(0))
    return percentage
  }
}

// starts at c0 and ends at b8
// c, c#, d, d#, e, f, f#, g, g#, a, a#, b
const NOTE_FREQUENCIES = [
  16.35, 17.32, 18.35, 19.45, 20.6, 21.83, 23.12, 24.5, 25.96, 27.5, 29.14, 30.87, 32.7, 34.65, 36.71, 38.89, 41.2,
  43.65, 46.25, 49, 51.91, 55, 58.27, 61.74, 65.41, 69.3, 73.42, 77.78, 82.41, 87.31, 92.5, 98, 103.83, 110, 116.54,
  123.47, 130.81, 138.59, 146.83, 155.56, 164.81, 174.61, 185, 196, 207.65, 220, 233.08, 246.94, 261.63, 277.18, 293.66,
  311.13, 329.63, 349.23, 369.99, 392, 415.3, 440, 466.16, 493.88, 523.25, 554.37, 587.33, 622.25, 659.25, 698.46,
  739.99, 783.99, 830.61, 880, 932.33, 987.77, 1046.5, 1108.73, 1174.66, 1244.51, 1318.51, 1396.91, 1479.98, 1567.98,
  1661.22, 1760, 1864.66, 1975.53, 2093, 2217.46, 2349.32, 2489.02, 2637.02, 2793.83, 2959.96, 3135.96, 3322.44, 3520,
  3729.31, 3951.07, 4186.01, 4434.92, 4698.63, 4978.03, 5274.04, 5587.65, 5919.91, 6271.93, 6644.88, 7040, 7458.62,
  7902.13,
]

const NOTE_LOGS = [
  2.7942278973432626, 2.851861903134289, 2.9096295745005794, 2.9678470700644555, 3.0252910757955354, 3.0832851705618527,
  3.1406980438041767, 3.1986731175506815, 3.2565568918358894, 3.3141860046725258, 3.3721118007670587, 3.429784838514068,
  3.487375077903208, 3.5452977256359124, 3.603049197508743, 3.660737148167656, 3.7184382563554808, 3.776203282285611,
  3.834061463958434, 3.8918202981106265, 3.9495114498391377, 4.007333185232471, 4.065087381154983, 4.122932019074013,
  4.180675151973121, 4.238444906195857, 4.2961963780686885, 4.3538843287276014, 4.411706788775292, 4.469465003822716,
  4.52720864451838, 4.584967478670572, 4.642754946315362, 4.700480365792417, 4.758234561714928, 4.815998211579652,
  4.873745888700135, 4.931519934080711, 4.989275454974846, 5.047031509287547, 5.104793295246089, 5.162554915534036,
  5.220355825078325, 5.278114659230518, 5.335853970076762, 5.393627546352362, 5.451381742274873, 5.509145392139597,
  5.566931291907004, 5.624667114640657, 5.682422635534792, 5.740210831269753, 5.797970813310776, 5.85573073092826,
  5.913475978246007, 5.971261839790462, 6.029001150636708, 6.0867747269123065, 6.1445289228348186, 6.202292572699543,
  6.2600593613261095, 6.317832333857444, 6.3755868424430115, 6.4333419412477015, 6.491102825233396, 6.548877911488205,
  6.606636672593393, 6.66439626516702, 6.722160370613705, 6.779921907472252, 6.837686829268253, 6.895449877124983,
  6.953206541886054, 7.0109704951296425, 7.068734023002957, 7.1264971571309275, 7.184257590140765, 7.2420179334164505,
  7.299783853153339, 7.357543445726965, 7.41530755117365, 7.473069088032197, 7.530834009828197, 7.588591995764992,
  7.646353722445999, 7.704117675689588, 7.7618812035629015, 7.819644337690873, 7.877404770700711, 7.935168693298651,
  7.992931033713284, 8.050690626286912, 8.108454731733595, 8.166216268592143, 8.223978508930557, 8.28174170728811,
  8.339503291918524, 8.397264856249533, 8.455026255845166, 8.512789509426051, 8.570551951260656, 8.62831408419907,
  8.686076525059786, 8.743839401253677, 8.80160191229354, 8.859363449152088, 8.917125689490502, 8.97488762236727,
]

const LOG_STEP_SIZE = NOTE_LOGS[1] - NOTE_LOGS[0]
