import * as React from 'react'
import { observer } from 'mobx-react'
import { SingleRecordingStore } from 'components/single_recording/store'
import { SingleRecordingController } from 'components/single_recording/controller'
import { SingleRecordingPresenter } from 'components/single_recording/presenter'
import { PauseIcon, PlayIcon } from 'components/base/icons'
import { InvisibleButton } from 'components/base/buttons'
import { Recording } from 'modules/recording/recording'
import './single_recording.css'

export function createSingleRecording() {
  const singleRecordingStore = new SingleRecordingStore()
  const singleRecordingController = new SingleRecordingController(singleRecordingStore)
  const singleRecordingPresenter = new SingleRecordingPresenter(singleRecordingStore)

  const Component = observer(() => {
    return <div>SingleRecording</div>
  })

  return { SingleRecording: Component }
}

export const SingleRecording = observer(({ recording }: { recording: Recording }) => (
  <SingleRecordingComponent
    active={recording.isPlaying}
    currentTime={recording.currentTime}
    duration={recording.audio.duration}
    onChange={recording.setTime}
    onClickPause={recording.pause}
    onClickPlay={recording.play}
    playing={recording.isPlaying}
  />
))

export const SingleRecordingComponent = ({
  active = false,
  currentTime,
  duration,
  onChange,
  onClickPause,
  onClickPlay,
  playing,
}: {
  active?: boolean
  currentTime: number
  duration: number
  onChange: (v: number) => void
  onClickPause: () => void
  onClickPlay: () => void
  playing: boolean
}) => {
  const handler = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const val = Number(e.target.value)
    onChange(val)
  }, [])
  const formattedTime = formatTime(currentTime)
  const formattedDuration = formatTime(duration)

  const Button = () => {
    if (playing) {
      return (
        <InvisibleButton onClick={onClickPause}>
          <PauseIcon outline={true} />
        </InvisibleButton>
      )
    } else {
      return (
        <InvisibleButton onClick={onClickPlay}>
          <PlayIcon />
        </InvisibleButton>
      )
    }
  }

  return (
    <div className='flex flex-row w-full h-16 p-4 bg-gray-100 shadow-inner SingleRecording rounded-md max-w-lg'>
      <div className='flex flex-row items-center w-36 SingleRecording__MetaContainer'>
        <div className='SingleRecording__Button'>
          <Button />
        </div>
        <div className='SingleRecording__Time'>
          {formattedTime} / {formattedDuration}
        </div>
      </div>
      <div className='flex-grow h-full ml-4 SingleRecording__RangeContainer'>
        <input
          className={`w-full h-full border-none shadow SingleRecording__RangeSlider overflow-hidden rounded-md ${
            active ? 'bg-yellow-400' : 'bg-gray-400'
          }`}
          onChange={handler}
          max={duration}
          min={0}
          step={1}
          type='range'
          value={currentTime}
        />
      </div>
    </div>
  )
}

function formatTime(t: number): string {
  const min = `${Math.floor(t / 60)}`.padStart(2, '0')
  const sec = `${Math.floor(t % 60)}`.padStart(2, '0')
  return `${min}:${sec}`
}
