export const AcousticGuitarSamplePaths = {
  A1: 'guitar-acoustic/A1.mp3',
  A2: 'guitar-acoustic/A2.mp3',
  A3: 'guitar-acoustic/A3.mp3',
  A4: 'guitar-acoustic/A4.mp3',
  A5: 'guitar-acoustic/A5.mp3',
  A6: 'guitar-acoustic/A6.mp3',
  'A#1': 'guitar-acoustic/As1.mp3',
  'A#2': 'guitar-acoustic/As2.mp3',
  'A#3': 'guitar-acoustic/As3.mp3',
  'A#4': 'guitar-acoustic/As4.mp3',
  'A#5': 'guitar-acoustic/As5.mp3',
  'A#6': 'guitar-acoustic/As6.mp3',
  B1: 'guitar-acoustic/B1.mp3',
  B2: 'guitar-acoustic/B2.mp3',
  B3: 'guitar-acoustic/B3.mp3',
  B4: 'guitar-acoustic/B4.mp3',
  B5: 'guitar-acoustic/B5.mp3',
  B6: 'guitar-acoustic/B6.mp3',
  C1: 'guitar-acoustic/C1.mp3',
  C2: 'guitar-acoustic/C2.mp3',
  C3: 'guitar-acoustic/C3.mp3',
  C4: 'guitar-acoustic/C4.mp3',
  C5: 'guitar-acoustic/C5.mp3',
  C6: 'guitar-acoustic/C6.mp3',
  C7: 'guitar-acoustic/C7.mp3',
  'C#1': 'guitar-acoustic/Cs1.mp3',
  'C#2': 'guitar-acoustic/Cs2.mp3',
  'C#3': 'guitar-acoustic/Cs3.mp3',
  'C#4': 'guitar-acoustic/Cs4.mp3',
  'C#5': 'guitar-acoustic/Cs5.mp3',
  'C#6': 'guitar-acoustic/Cs6.mp3',
  D1: 'guitar-acoustic/D1.mp3',
  D2: 'guitar-acoustic/D2.mp3',
  D3: 'guitar-acoustic/D3.mp3',
  D4: 'guitar-acoustic/D4.mp3',
  D5: 'guitar-acoustic/D5.mp3',
  D6: 'guitar-acoustic/D6.mp3',
  'D#1': 'guitar-acoustic/Ds1.mp3',
  'D#2': 'guitar-acoustic/Ds2.mp3',
  'D#3': 'guitar-acoustic/Ds3.mp3',
  'D#4': 'guitar-acoustic/Ds4.mp3',
  'D#5': 'guitar-acoustic/Ds5.mp3',
  'D#6': 'guitar-acoustic/Ds6.mp3',
  E1: 'guitar-acoustic/E1.mp3',
  E2: 'guitar-acoustic/E2.mp3',
  E3: 'guitar-acoustic/E3.mp3',
  E4: 'guitar-acoustic/E4.mp3',
  E5: 'guitar-acoustic/E5.mp3',
  E6: 'guitar-acoustic/E6.mp3',
  F1: 'guitar-acoustic/F1.mp3',
  F2: 'guitar-acoustic/F2.mp3',
  F3: 'guitar-acoustic/F3.mp3',
  F4: 'guitar-acoustic/F4.mp3',
  F5: 'guitar-acoustic/F5.mp3',
  F6: 'guitar-acoustic/F6.mp3',
  'F#1': 'guitar-acoustic/Fs1.mp3',
  'F#2': 'guitar-acoustic/Fs2.mp3',
  'F#3': 'guitar-acoustic/Fs3.mp3',
  'F#4': 'guitar-acoustic/Fs4.mp3',
  'F#5': 'guitar-acoustic/Fs5.mp3',
  'F#6': 'guitar-acoustic/Fs6.mp3',
  G1: 'guitar-acoustic/G1.mp3',
  G2: 'guitar-acoustic/G2.mp3',
  G3: 'guitar-acoustic/G3.mp3',
  G4: 'guitar-acoustic/G4.mp3',
  G5: 'guitar-acoustic/G5.mp3',
  G6: 'guitar-acoustic/G6.mp3',
  'G#1': 'guitar-acoustic/Gs1.mp3',
  'G#2': 'guitar-acoustic/Gs2.mp3',
  'G#3': 'guitar-acoustic/Gs3.mp3',
  'G#4': 'guitar-acoustic/Gs4.mp3',
  'G#5': 'guitar-acoustic/Gs5.mp3',
  'G#6': 'guitar-acoustic/Gs6.mp3',
}
