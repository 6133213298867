import { SheetMusicStore, SheetMusicXMLDocument } from './store'
import { action, runInAction, makeAutoObservable } from 'mobx'
import { OpenSheetMusicDisplay } from 'opensheetmusicdisplay'
import { SheetMusicSelectorPresenter } from 'components/sheet_music_selector/presenter'

export class SheetMusicController {
  constructor(private readonly store: SheetMusicStore) {
    makeAutoObservable(this)
  }

  @action
  setRootNode(node: HTMLElement) {
    this.store.rootNode = node
  }

  @action
  setOsmd(osmd: OpenSheetMusicDisplay) {
    this.store.osmd = osmd
  }

  async downloadSheetMusic(name: string, url: string) {
    runInAction(() => (this.store.state = { kind: 'loading' }))
    const result = await fetch(url).then((response) => response.text())
    const parser = new DOMParser()
    const doc = parser.parseFromString(result, 'text/xml')
    const sheet: SheetMusicXMLDocument = {
      kind: 'xml',
      name,
      url,
      doc,
    }
    await this.store.osmd.load(sheet.doc)
    runInAction(() => (this.store.state = { kind: 'loaded', sheet }))
  }
}
