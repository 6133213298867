import * as React from 'react'

export const OutlinedUserIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='w-6 h-6'
    fill='none'
    viewBox='0 0 24 24'
    stroke='currentColor'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z'
    />
  </svg>
)

export const SolidUserIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='w-5 h-5'
    viewBox='0 0 20 20'
    fill='currentColor'
  >
    <path
      fillRule='evenodd'
      d='M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z'
      clipRule='evenodd'
    />
  </svg>
)

export const SolidUserCircleIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='w-5 h-5'
    viewBox='0 0 20 20'
    fill='currentColor'
  >
    <path
      fill-rule='evenodd'
      d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z'
      clip-rule='evenodd'
    />
  </svg>
)
