import mobxRemotedev from 'mobx-remotedev'
import { MicrophoneDeviceStore } from 'modules/microphone_device/store'
import { action, makeObservable } from 'mobx'
import { AudioNodeManagerController } from 'components/audio_node_manager/controller'

@mobxRemotedev
export class MicrophoneDeviceController {
  constructor(
    private readonly store: MicrophoneDeviceStore,
    private readonly audioNodeManagerController: AudioNodeManagerController,
  ) {
    makeObservable(this)
  }

  public readonly subscribe = async (): Promise<() => void | undefined> => {
    try {
      this.incrementSubscribers()
      const settings = { audio: true }
      const audioDevice = await navigator.mediaDevices.getUserMedia(settings)
      this.connect(audioDevice)
      return this.unsubscribe
    } catch (e) {
      this.decrementSubscribers()
      this.handleConnectionFailure()
    }
  }

  public readonly unsubscribe = () => {
    if (this.store.subscribers === 0) {
      throw new Error('Unsubscribed from MicrophoneDeviceController when there was no subscriber')
    }

    if (this.store.state.kind !== 'connected') {
      throw new Error('Unsubscribed from MicrophoneDeviceController when the Audio Context was not connected')
    }

    try {
      this.decrementSubscribers()
      if (this.store.subscribers === 0) {
        this.store.state.audioContext.close().then(() => this.setDisconnected())
      }
    } catch (e) {
      this.handleConnectionFailure()
    }
  }

  @action private incrementSubscribers() {
    this.store.subscribers++
  }

  @action private decrementSubscribers() {
    this.store.subscribers--
  }

  @action private setDisconnected() {
    this.store.state = { kind: 'disconnected' }
  }

  @action private handleConnectionFailure() {
    this.store.state = { kind: 'failed' }
  }

  @action private connect(audioDevice: MediaStream) {
    const audioContext = new AudioContext()
    const audioSource = audioContext.createMediaStreamSource(audioDevice)
    this.audioNodeManagerController.setAudioContext(audioContext)

    this.store.state = {
      kind: 'connected',
      audioContext,
      audioDevice,
      audioSource,
    }
  }
}
