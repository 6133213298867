import * as React from 'react'
import { observer } from 'mobx-react'
import { Router, Route } from 'wouter'
import { createAppStore } from 'app/create'
import { createHeader } from 'components/header'
import { createLayout } from 'components/layout'
import { publicPath } from 'global_env'
import { createHotkeyManager } from 'modules/hotkey_manager'
import { createDashboard } from 'pages/dashboard'
import { createHome } from 'pages/home'
import { Singleton } from 'singleton'
import 'tailwindcss/tailwind.css'
import './app.css'
import { HttpService } from 'services/http_service'
import { UploadService } from 'services/upload_service'
import { UserService } from 'services/user_service'
import { createAuth } from 'components/auth'
import { RecordingService } from 'services/recording_service'

export function createApp() {
  const httpService = new HttpService(process.env.ORIGIN_SERVER)
  const userService = new UserService(httpService)
  const uploadService = new UploadService(httpService)
  const recordingService = new RecordingService(httpService)

  const { appStore, appController, appPresenter } = createAppStore()
  const { authPresenter, authController, authUi } = createAuth({ appController, userService })
  const pathName = publicPath == null || publicPath === '/' ? undefined : new URL(publicPath).pathname
  const base = pathName == null || pathName === '/' ? undefined : stripTrailingSlash(pathName)
  const { Layout, layoutPresenter } = createLayout()
  const { hotkeyManagerController } = createHotkeyManager()

  const singleton: Singleton = {
    app: {
      controller: appController,
      presenter: appPresenter,
      store: appStore,
    },
    hotkey: {
      controller: hotkeyManagerController,
    },
    layout: { presenter: layoutPresenter },
    user: { presenter: authPresenter, controller: authController },
    services: {
      httpService,
      recordingService,
      userService,
      uploadService,
    },
  }

  const { Header } = createHeader(singleton, authUi)
  const { Dashboard } = createDashboard(Layout, singleton)
  const { Home } = createHome()

  const App = observer(() => {
    return (
      <div>
        <Header />
        <Router base={base}>
          <Route path='/play' component={Dashboard} />
          <Route path='/' component={Home} />
        </Router>
      </div>
    )
  })

  return { App }
}

function stripTrailingSlash(str: string): string {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1)
  }
  return str
}
