import * as React from 'react'
import { observer } from 'mobx-react'
import { AuthStore } from './store'
import { AuthController } from './controller'
import { AuthPresenter } from './presenter'
import { UserService } from 'services/user_service'
import { StackedForm, InlineForm } from './forms'
import { AppController } from 'app/controller'

export type AuthUi = {
  StackedSignInForm: React.FC
  InlineSignInForm: React.FC
  StackedSignUpForm: React.FC
  InlineSignUpForm: React.FC
}

export function createAuth({ appController, userService }: { appController: AppController; userService: UserService }) {
  const authStore = new AuthStore()
  const authController = new AuthController(appController, authStore, userService)
  const authPresenter = new AuthPresenter(authStore)

  const InlineSignInForm = observer(() => {
    if (authPresenter.isSignedIn) {
      return null
    }

    return (
      <InlineForm
        email={authPresenter.email}
        password={authPresenter.password}
        error={authPresenter.error}
        onEmailChange={authController.setEmail}
        onPasswordChange={authController.setPassword}
        onSubmit={authController.signIn}
        label='Sign In'
      />
    )
  })

  const StackedSignInForm = observer(() => {
    if (authPresenter.isSignedIn) {
      return null
    }

    return (
      <StackedForm
        email={authPresenter.email}
        password={authPresenter.password}
        error={authPresenter.error}
        onEmailChange={authController.setEmail}
        onPasswordChange={authController.setPassword}
        onSubmit={authController.signIn}
        label='Sign In'
      />
    )
  })

  const InlineSignUpForm = observer(() => {
    if (authPresenter.isSignedIn) {
      return null
    }

    return (
      <InlineForm
        email={authPresenter.email}
        password={authPresenter.password}
        error={authPresenter.error}
        onEmailChange={authController.setEmail}
        onPasswordChange={authController.setPassword}
        onSubmit={authController.signUp}
        label='Sign Up'
      />
    )
  })

  const StackedSignUpForm = observer(() => {
    if (authPresenter.isSignedIn) {
      return null
    }

    return (
      <StackedForm
        email={authPresenter.email}
        password={authPresenter.password}
        error={authPresenter.error}
        onEmailChange={authController.setEmail}
        onPasswordChange={authController.setPassword}
        onSubmit={authController.signUp}
        label='Sign Up'
      />
    )
  })

  return {
    authController,
    authPresenter,
    authUi: {
      InlineSignInForm,
      StackedSignInForm,
      InlineSignUpForm,
      StackedSignUpForm,
    },
  }
}
