import * as React from 'react'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { MicrophoneInputPresenter } from 'modules/microphone_input/presenter'
import { MicrophoneInputController } from 'modules/microphone_input/controller'
import { TextButton } from 'components/base/buttons'
import { MicrophoneIcon as SolidMicrophoneIcon } from '@heroicons/react/solid'
import { MicrophoneIcon as OutlineMicrophoneIcon } from '@heroicons/react/outline'
import { PitchGraphStore } from './store'
import { PitchGraphController } from './controller'
import { PitchGraphPresenter } from './presenter'
import { PitchCanvas } from './pitch_graph'

export function createPitchGraph(
  microphoneInputController: MicrophoneInputController,
  microphoneInputPresenter: MicrophoneInputPresenter,
) {
  const pitchGraphStore = new PitchGraphStore()
  const pitchGraphController = new PitchGraphController(pitchGraphStore)
  const pitchGraphPresenter = new PitchGraphPresenter(pitchGraphStore)

  // TODO move to model
  const isRecording = observable.box(false)

  const Button = observer(() => {
    if (microphoneInputPresenter.isRecording) {
      return (
        <TextButton
          onClick={microphoneInputController.stop}
          className='!p-0'
        >
          Stop
        </TextButton>
      )
    } else {
      return (
        <TextButton
          onClick={microphoneInputController.start}
          className='!p-0'
        >
          Start
        </TextButton>
      )
    }
  })

  const MicrophoneIcon = observer(() => {
    if (isRecording.get()) {
      return <SolidMicrophoneIcon className='w-5 h-5 text-red-500' />
    } else {
      return <OutlineMicrophoneIcon className='w-5 h-5' />
    }
  })

  const ControlRow = ({ children }: { children: React.ReactNode }) => (
    <div className='flex flex-row items-center'>{children}</div>
  )

  const PitchGraph = observer(() => {
    const [note, octave, accuracy] = pitchGraphPresenter.closestFrequency(microphoneInputPresenter.pitch)
    return (
      <div className='flex flex-row w-full h-full overflow-hidden'>
        <div className='flex flex-col w-32 px-4 py-2 text-xs text-gray-600 bg-gray-100 rounded-lg'>
          <ControlRow>
            <Button />
          </ControlRow>
          <ControlRow>Note: {note}</ControlRow>
          <ControlRow>Octave: {octave}</ControlRow>
          <ControlRow>Accuracy: {accuracy}</ControlRow>
        </div>
        <div className='flex-1'>
          <PitchCanvas
            accuracy={accuracy}
            data={[...microphoneInputPresenter.pitchHistory]}
            note={note}
            octave={octave}
          />
        </div>
      </div>
    )
  })

  return { PitchGraph }
}
