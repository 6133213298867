import mobxRemotedev from 'mobx-remotedev'
import { makeObservable, observable } from 'mobx'
import { HotkeyRegistry } from 'modules/hotkey_registry'
import { QwertyActionsDevice } from 'modules/qwerty_actions'

@mobxRemotedev
export class HotkeyManagerStore {
  constructor() {
    makeObservable(this)
  }

  @observable.ref hotkeyRegistries: Map<HotkeyRegistryName, HotkeyRegistry> = observable.map(new Map(), { deep: false })

  @observable.ref hotkeyActionDevices: Map<HotkeyActionDeviceName, HotkeyActionDevice> = observable.map(new Map(), {
    deep: false,
  })

  @observable.ref activeRegistries: Map<HotkeyRegistryName, boolean> = observable.map(new Map(), { deep: false })
}

export type HotkeyActionDevice = QwertyActionsDevice

// TODO delete this enum and just use id's at runtime
export const enum HotkeyRegistryName {
  GLOBAL = 'global',
  RECORDING_TOOL = 'recording_tool',
}

export const enum HotkeyActionDeviceName {
  QWERTY = 'qwerty',
}
