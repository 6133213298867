import * as React from 'react'
import { observer } from 'mobx-react'
import { SheetMusicStore } from './store'
import { SheetMusicController } from './controller'
import { SheetMusicPresenter } from './presenter'
import { SheetMusicRenderer } from './renderer'
import { SheetMusicSelectorPresenter } from 'components/sheet_music_selector/presenter'
import './styles.css'

export function createSheetMusic(sheetMusicSelectorPresenter: SheetMusicSelectorPresenter) {
  const store = new SheetMusicStore()
  const controller = new SheetMusicController(store)
  const presenter = new SheetMusicPresenter(store)
  new SheetMusicRenderer(controller, presenter, sheetMusicSelectorPresenter)

  const SheetMusic = observer(() => {
    const rootNodeRef = React.useCallback((node) => {
      controller.setRootNode(node)
    }, [])

    return (
      <div
        id='SheetMusic'
        className='w-full min-h-0 overflow-hidden'
      >
        <div
          id='SheetMusic__ScrollContainer'
          className='w-full h-full overflow-scroll'
        >
          {presenter.idle && <div>Choose a song to download in the "Learn/Practice" part of the Tools section</div>}
          {presenter.loading && <div>Loading sheet music, one moment</div>}
          <div
            id='OSMDContainer'
            ref={rootNodeRef}
          ></div>
        </div>
      </div>
    )
  })

  return { SheetMusic }
}
