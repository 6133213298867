import mobxRemotedev from 'mobx-remotedev'
import { computed, makeObservable } from 'mobx'
import { RecordingStore, State } from './store'
import { assertPlayable, isPlayable } from './utilities'

@mobxRemotedev
export class RecordingPresenter {
  constructor(private readonly store: RecordingStore) {
    makeObservable(this)
  }

  public get key(): string {
    return this.store.timestamp.toISOString()
  }

  public get label(): string {
    return this.store.timestamp.toUTCString()
  }

  @computed public get state(): State {
    return this.store.state
  }

  @computed public get currentTime(): number {
    assertPlayable(this.store.state)
    return this.store.state.currentTime
  }

  @computed public get isPlaying(): boolean {
    return this.store.state.kind === 'playing'
  }

  @computed public get isPlayable(): boolean {
    return isPlayable(this.store.state)
  }

  @computed public get audio(): HTMLAudioElement {
    assertPlayable(this.store.state)
    return this.store.state.audio
  }

  @computed public get blob(): Blob {
    assertPlayable(this.store.state)
    return this.store.state.blob
  }

  @computed public get duration(): number {
    assertPlayable(this.store.state)
    return this.store.state.duration
  }
}
