import mobxRemotedev from 'mobx-remotedev'
import { makeObservable, observable } from 'mobx'
import { Recording } from 'modules/recording/recording'

export type InitState = { kind: 'init' }
export type ConnectedState = { kind: 'connected'; mediaRecorder: MediaRecorder }
export type FailedState = { kind: 'failed' }
export type RecordingState = {
  kind: 'recording'
  mediaRecorder: MediaRecorder
  recording: Recording
}
export type State = InitState | ConnectedState | RecordingState | FailedState

@mobxRemotedev
export class MicrophoneRecorderStore {
  constructor() {
    makeObservable(this)
  }

  @observable recordings: Recording[] = observable.array([])

  @observable.ref state: State = { kind: 'init' }
}
