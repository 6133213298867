import mobxRemotedev from 'mobx-remotedev'
import { makeObservable, observable } from 'mobx'

@mobxRemotedev
export class VolumeControlStore {
  constructor(private readonly initialVolume: number) {
    makeObservable(this)
  }

  @observable.ref volume: number = this.initialVolume
}
