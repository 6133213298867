import mobxRemotedev from 'mobx-remotedev'
import { action, makeObservable, observable, runInAction, when } from 'mobx'
import { RecordingState, RecordingStore } from './store'
import { assert, assertPlayable, assertRecording } from './utilities'
import { bound } from 'utilities/bound'

@mobxRemotedev
export class RecordingController {
  constructor(private readonly store: RecordingStore) {
    makeObservable(this)
  }

  @action.bound public setRecording() {
    this.store.state = { kind: 'recording', events: [] }
  }

  @action.bound public setRecorded() {
    assertRecording(this.store.state)

    const { events } = this.store.state
    const blob = new Blob(
      events.map((d) => d.data),
      { type: 'audio/webm' },
    )
    const src = URL.createObjectURL(blob)
    const audio = new Audio(src)

    const duration = observable.box()
    audio.onloadedmetadata = () => {
      // chrome has a weird bug where audio duration is infinity
      // so we set the current time to a huge number and then
      // ontimeupdate we get the duration
      if (audio.duration == Infinity) {
        audio.currentTime = 1e101
        audio.ontimeupdate = () => {
          audio.ontimeupdate = () => undefined
          // setting to 0 doesn't seem to work, the audio refuses to play
          audio.currentTime = 0.01
          runInAction(() => duration.set(audio.duration))
        }
      }
    }

    when(() => duration.get() != null).then(() => {
      runInAction(() => {
        this.store.state = {
          kind: 'recorded',
          blob,
          audio,
          currentTime: 0,
          duration: Math.round(duration.get() * 1000),
        }
      })
    })
  }

  @action.bound public setPaused() {
    assertPlayable(this.store.state)
    this.store.state = {
      kind: 'paused',
      blob: this.store.state.blob,
      currentTime: this.store.state.audio.currentTime,
      audio: this.store.state.audio,
      duration: this.store.state.duration,
    }
  }

  @action.bound public setTime(v: number) {
    assertPlayable(this.store.state)
    this.store.state.audio.currentTime = v
    this.store.state = { ...this.store.state, currentTime: v }
  }

  @action.bound public setPlaying() {
    assertPlayable(this.store.state)
    const { audio } = this.store.state
    this.store.state = {
      kind: 'playing',
      audio,
      blob: this.store.state.blob,
      currentTime: audio.currentTime,
      duration: this.store.state.duration,
    }
  }

  @action.bound public addData(event: BlobEvent) {
    assert<RecordingState>(this.store.state, 'recording')
    this.store.state.events.push(event)
  }

  @bound
  public async setFromDownloadedData(blob: Blob, realDuration: number): Promise<void> {
    return new Promise((resolve) => {
      const src = URL.createObjectURL(blob)
      const audio = new Audio(src)
      audio.onloadedmetadata = () => {
        // chrome has a weird bug where audio duration is infinity
        // so we set the current time to a huge number and then
        // ontimeupdate we get the duration
        if (audio.duration == Infinity) {
          audio.currentTime = 1e101
          audio.ontimeupdate = () => {
            audio.ontimeupdate = () => undefined
            // setting to 0 doesn't seem to work, the audio refuses to play
            audio.currentTime = 0.01

            runInAction(() => {
              this.store.state = {
                kind: 'recorded',
                blob,
                audio,
                currentTime: 0,
                duration: realDuration,
              }
            })
            resolve()
          }
        }
      }
    })
  }
  //
}
