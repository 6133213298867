import mobxRemotedev from 'mobx-remotedev'
import { makeObservable, computed } from 'mobx'
import { MetronomeStore } from './store'

@mobxRemotedev
export class MetronomePresenter {
  constructor(private readonly store: MetronomeStore) {
    makeObservable(this)
  }

  @computed get playing(): boolean {
    return this.store.state === 'on'
  }

  @computed get bpm(): number | undefined {
    return this.store.bpm
  }

  @computed get volume(): number {
    return this.store.volume
  }
}
