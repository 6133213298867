import { KeyboardAssistKind } from '../components/keyboard_assist_selector/store'

const keyOrder: string[] = '1!2@34$5%6^78*9(0qQwWeErtTyYuiIoOpPasSdDfgGhHjJklLzZxcCvVbBnm'.split('')

export function convertKey(key: number): string {
  return keyNumberToNote(key) + keyNumberToOctave(key)
}

export function keyNumberToQwerty(key: number): string {
  return keyOrder[key - 36]
}

export function keyNumberToNoteAndOctave(key: number): string {
  return `${keyNumberToNote(key)}${keyNumberToOctave(key)}`
}

export function keyNumberToOctave(key: number): number {
  return Math.floor((key - 12) / 12)
}

export function keyNumberToNote(key: number): string {
  const letter = key % 12
  switch (letter) {
    case 0:
      return 'C'
    case 1:
      return 'C#'
    case 2:
      return 'D'
    case 3:
      return 'D#'
    case 4:
      return 'E'
    case 5:
      return 'F'
    case 6:
      return 'F#'
    case 7:
      return 'G'
    case 8:
      return 'G#'
    case 9:
      return 'A'
    case 10:
      return 'A#'
    case 11:
      return 'B'
    default:
      throw new Error('something went wrong converting this key to a note' + JSON.stringify(key))
  }
}

export function keyboardToPianoKey(key: string): number | undefined {
  const index = keyOrder.findIndex((n) => key === n)
  if (index === -1) return
  return index + 36
}

export function selectKeyboardAssist(label: string, key: number) {
  switch (label) {
    case KeyboardAssistKind.NONE:
      return null
    case KeyboardAssistKind.NOTE:
      return keyNumberToNote(key)
    case KeyboardAssistKind.NOTE_AND_OCTAVE:
      return keyNumberToNoteAndOctave(key)
    case KeyboardAssistKind.QWERTY:
      return keyNumberToQwerty(key)
    default:
      return keyNumberToQwerty(key)
  }
}
