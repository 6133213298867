import { MidiSelectStore } from './store'
import { reaction, makeAutoObservable } from 'mobx'
import { AppStore } from 'app/store'

export class MidiSelectController {
  constructor(private readonly store: MidiSelectStore, private readonly appStore: AppStore) {
    makeAutoObservable(this)
    this.listenForMidiAccessChanges()
  }

  readonly updateAvailableMidiInputs = (access: WebMidi.MIDIAccess) => {
    if (access == null) {
      this.store.inputs.clear()
      return
    }

    const inputs = Array.from(access.inputs.values())
    this.store.inputs.replace(inputs)

    if (inputs.length && !this.store.selectedInput) {
      this.store.selectedInput = inputs[0]
    }
  }

  readonly setSelectedInput = (name: string) => {
    const selectedInput = this.store.inputs.find((input) => input.name === name)
    if (!selectedInput) {
      throw new Error('The selected input does not exist')
    }
    this.store.selectedInput = selectedInput
  }

  readonly updateSelectedInput = (selectedInput: WebMidi.MIDIInput) => {
    console.log(selectedInput, 'Map something from here to the inputs array values to get the right input')
  }

  private listenForMidiAccessChanges() {
    reaction(() => this.appStore.midiAccess, this.listenForMidiInputChanges)
  }

  private listenForMidiInputChanges = (access: WebMidi.MIDIAccess) => {
    this.updateAvailableMidiInputs(access)
    access.onstatechange = () => this.updateAvailableMidiInputs(access)
  }
}
