import * as React from 'react'
import Jdenticon from 'react-jdenticon'

type AvatarProps = {
  size?: string
  value: string
}
export const Avatar = ({ size = '48', value }: AvatarProps) => (
  <Jdenticon
    size={size}
    value={value}
  />
)
