import { PausedState, PlayingState, RecordedState, RecordingState, State } from 'modules/recording/store'

export function assert<T extends State>(state: State, key: string): asserts state is T {
  if (state.kind !== key) {
    throw new Error(`expected state "${key}", received "${state.kind}"`)
  }
}

export function assertRecording(state: State): asserts state is RecordingState {
  return assert<RecordingState>(state, 'recording')
}

export function assertPlayable(state: State): asserts state is PlayingState | PausedState | RecordedState {
  if (!isPlayable(state)) {
    throw new Error(`expected state "playing", "paused", "recorded", received "${state.kind}"`)
  }
}

export function assertRecorded(state: State): asserts state is RecordedState {
  return assert<RecordedState>(state, 'recorded')
}

export function isPlayable(state: State): boolean {
  return state.kind === 'recorded' || state.kind === 'playing' || state.kind === 'paused'
}
