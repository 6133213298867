import { makeObservable, observable } from 'mobx'
import { Recording } from 'modules/recording/recording'

export class RecordingToolStore {
  constructor() {
    makeObservable(this)
  }

  @observable.ref recordings: Recording[] = []
  @observable.ref recordingStates: Map<Recording, string> = new Map()
}
