import { AppStore } from 'app/store'
import { makeObservable, computed } from 'mobx'

export class AppPresenter {
  constructor(private readonly store: AppStore) {
    makeObservable(this)
  }

  @computed get user() {
    return this.store.user
  }

  @computed get isLoggedIn() {
    return false // this.store.auth === AuthState.SignedIn && this.store.user
  }
}
