import mobxRemotedev from 'mobx-remotedev'
import { observable, makeObservable } from 'mobx'

@mobxRemotedev
export class MetronomeStore {
  constructor() {
    makeObservable(this)
  }

  @observable.ref state: 'on' | 'off' = 'off'
  @observable.ref bpm: number | undefined = 60
  @observable.ref volume: number = -24
}
