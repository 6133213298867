import * as React from 'react'

type BaseInputProps = {
  id: string
  type: string
  className?: string
  placeholder?: string
  value: any
  onChange(e: React.ChangeEvent<HTMLInputElement>): void
}

type TextInputProps = {
  id?: string
  type: string
  className?: string
  placeholder?: string
  value: any
  onChange(e: React.ChangeEvent<HTMLInputElement>): void
}

type NumberInputProps = {
  id?: string
  className?: string
  placeholder?: string
  value: any
  onChange(e: React.ChangeEvent<HTMLInputElement>): void
}

type RangeInputProps = {
  id?: string
  className?: string
  placeholder?: string
  value: number
  onChange(v: number): void
  min: number
  max: number
  step?: number
}

export const TextInput = ({
  id = '',
  type = 'text',
  className = 'rounded-md',
  placeholder = '',
  value,
  onChange,
}: TextInputProps) => (
  <Input
    id={id}
    type={type}
    className={className}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
  />
)

export const NumberInput = ({ id = '', className = 'rounded-md', placeholder = '', value, onChange }: NumberInputProps) => (
  <Input
    id={id}
    type='number'
    className={className}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
  />
)

export const Input = ({ id, type, className, placeholder, value, onChange }: BaseInputProps) => {
  const classes = [
    'flex-1 inline-block w-32 p-2 border-2 border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm',
    !!className && className,
  ]
    .filter((x) => !!x)
    .join(' ')
  return (
    <input
      type={type}
      id={id}
      className={classes}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  )
}

export const RangeInput = ({
  id = '',
  className = 'rounded-md',
  value,
  onChange,
  min = 0,
  max = 100,
  step = 1,
}: RangeInputProps) => {
  const changeHandler = React.useCallback((e) => onChange(Number(e.target.value)), [])
  const classes = ['w-full', !!className && className].filter((x) => !!x).join(' ')
  return (
    <input
      id={id}
      className={classes}
      type='range'
      value={value}
      onChange={changeHandler}
      min={min}
      max={max}
      step={step}
    />
  )
}
