import mobxRemotedev from 'mobx-remotedev'
import { makeObservable, observable } from 'mobx'

@mobxRemotedev
export class HotkeyRegistryStore {
  constructor() {
    makeObservable(this)
  }

  @observable.ref activeListeners: number = 0

  // how many actions to keep in history
  maxActionLength = 10

  // a map of actions to functions, e.g. ['control c': [() => void, () => void]]
  @observable actionMappings: Map<string, Array<() => void>> = observable.map(new Map(), { deep: false })

  // the last N actions in chronological order of length `this.maxActionLength`
  @observable actionHistory: string[] = observable.array([], { deep: false })
}
