import mobxRemotedev from 'mobx-remotedev'
import { AudioNodeManagerStore } from 'components/audio_node_manager/store'
import { action, makeObservable } from 'mobx'
import { AudioNodePipeline } from 'components/audio_node_manager/audio_node_pipeline'
import { AudioNodeFactory, AudioNodeKind } from 'components/audio_node_manager/audio_node_constructor'
import { bound } from 'utilities/bound'

@mobxRemotedev
export class AudioNodeManagerController {
  constructor(private readonly store: AudioNodeManagerStore) {
    makeObservable(this)
  }

  @action.bound public registerPipeline(name: string, pipeline: AudioNodePipeline): void {
    this.store.pipelines.set(name, pipeline)
    if (Array.from(this.store.pipelines.values()).length === 1) {
      this.selectPipeline(name)
    }
  }

  @action.bound public selectPipeline(name: string): void {
    this.store.selectedPipeline = name
  }

  @action.bound public setAudioContext(audioContext: AudioContext): void {
    this.store.audioContext = audioContext
    this.store.nodeFactory = new AudioNodeFactory(audioContext)
  }

  @bound public createNode(type: AudioNodeKind): AudioNode {
    return this.store.nodeFactory.createNode(type)
  }
}
