import * as React from 'react'
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Avatar } from 'components/profile_dropdown/avatar'
import { observer } from 'mobx-react'
import { Singleton } from 'singleton'

export function createProfileDropdown(singleton: Singleton) {
  const userPresenter = singleton.user.presenter
  const userController = singleton.user.controller

  const ProfileDropdown = observer(() => {
    return (
      <Menu as='div' className='ml-3 relative z-10'>
        {({ open }) => (
          <>
            <div>
              <Menu.Button className='max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none filter contrast-100 hover:contrast-75'>
                <span className='sr-only'>Open user menu</span>
                <div className='h-10 w-10 rounded-full border border-gray-200 hover:border-gray-600 flex justify-center items-center'>
                  <Avatar size='32' value={userPresenter.email} />
                </div>
              </Menu.Button>
            </div>
            <Transition
              show={open}
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items
                static
                className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none'
              >
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className='hover:bg-gray-100 text-gray-900 block px-4 py-3 text-sm w-full text-left outline-none'
                      onClick={() => userController.signOut()}
                    >
                      Logout
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    )
  })

  return { ProfileDropdown }
}
