import mobxRemotedev from 'mobx-remotedev'
import { HeaderAuthFormStore } from 'components/header_auth_form/store'
import { computed, makeObservable } from 'mobx'

@mobxRemotedev
export class HeaderAuthFormPresenter {
  constructor(private readonly store: HeaderAuthFormStore) {
    makeObservable(this)
  }

  @computed get showSignIn(): boolean {
    return this.store.state === 'signin'
  }

  @computed get showSignUp(): boolean {
    return this.store.state === 'signup'
  }
}
