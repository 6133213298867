import mobxRemotedev from 'mobx-remotedev'
import { DashboardStore } from 'pages/dashboard/store'
import { computed, makeObservable } from 'mobx'

@mobxRemotedev
export class DashboardPresenter {
  constructor(private readonly store: DashboardStore) {
    makeObservable(this)
  }

  @computed get noop(): undefined {
    return undefined
  }
}
