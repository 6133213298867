import * as React from 'react'
import { observer } from 'mobx-react'
import { Link } from 'wouter'
import { TextButton } from 'components/base/buttons'
import { Singleton } from 'singleton'
import { AuthUi } from 'components/auth'
import { createHeaderAuthForm } from 'components/header_auth_form'

export function createHeader(singleton: Singleton, authUi: AuthUi) {
  const { HeaderAuthForm } = createHeaderAuthForm(singleton, authUi)

  const Header = observer(() => {
    return (
      <nav className='w-full px-4 py-2 bg-white border-b-2 border-gray-200 border-solid'>
        <div className='flex justify-between'>
          <MenuSection>
            <Logo />
            <MenuItem to='/play' label='Play' />
          </MenuSection>
          <MenuSection>
            <HeaderAuthForm />
          </MenuSection>
        </div>
      </nav>
    )
  })

  return { Header }
}

const MenuSection = ({ children }: { children: React.ReactNode }) => (
  <div className='flex items-center justify-center'>{children}</div>
)

const MenuItem = ({ to, label }: { to: string; label: string }) => (
  <Link to={to}>
    <TextButton onClick={() => undefined}>{label}</TextButton>
  </Link>
)

const Logo = () => (
  <div className='flex flex-col items-center justify-center flex-shrink-0'>
    <img alt='logo' src='/assets/logo.png' className='w-auto mr-4 h-14' />
  </div>
)
