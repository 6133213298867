import mobxRemotedev from 'mobx-remotedev'
import { SoundExercisePlayerStore } from "components/sound-exercise-player/store"
import { action, makeObservable } from "mobx"
import { Option } from 'components/base/selects'
import * as Tone from 'tone'
import { Piano } from 'instruments/piano'
import { Scale } from '@tonaljs/tonal'

@mobxRemotedev
export class SoundExercisePlayerController {
  constructor(private readonly store: SoundExercisePlayerStore, private readonly player: Player) {
    makeObservable(this)
  }

  @action.bound setBaseNote(o: Option) {
    this.store.baseNote = o
  }

  @action.bound setScaleType(o: Option) {
    this.store.scaleType = o
  }

  @action.bound setOctaves(e: React.ChangeEvent<HTMLInputElement>) {
    this.store.octaves = Number(e.currentTarget.value)
  }

  @action.bound setOctave(e: React.ChangeEvent<HTMLInputElement>) {
    this.store.octave = Number(e.currentTarget.value)
  }

  @action.bound setReverse(e: React.ChangeEvent<HTMLInputElement>) {
    this.store.reverse = Boolean(e.currentTarget.checked)
  }

  play = () => {
    const { baseNote, octave, octaves, reverse, scaleType } = this.store
    const tonic = baseNote.value
    const scale = scaleType.value
    const lastNote = `${tonic}${octave + octaves}`
    const notes = Array(octaves)
      .fill(undefined)
      .map((_, i) => Scale.get(`${tonic}${octave + i} ${scale}`).notes)
      .flat()
    const sequence = reverse ? [...notes, lastNote, ...notes.reverse()] : [...notes, lastNote]
    this.player.playSequence(sequence, 500)
  }
}

// TODO refactor this and `components/keyboard/player` later into one module
export class Player {
  init: boolean = false
  readonly timeouts = []

  public readonly startAudio = async () => {
    await Tone.loaded()
    await Tone.start()
  }

  public readonly playSequence = (sequence: string[], interval: number = 1000) => {
    sequence.forEach((note, index) => {
      const timeout = setTimeout(() => {
        this.playNote(note)
      }, index * interval)

      this.timeouts.push(timeout)
    })
  }

  public readonly playNote = (note: string) => {
    const time = Tone.context.currentTime // immediately fires the note
    Piano.triggerAttackRelease(note, 0.6, time)
  }

  public readonly stop = () => {
    this.timeouts.forEach((timeout) => {
      clearTimeout(timeout)
    })
    this.timeouts.length = 0
  }
}