import { action, makeObservable } from 'mobx'
import KeyboardStore from './store'
import mobxRemotedev from 'mobx-remotedev'
import { keyNumberToNote, keyNumberToOctave } from 'utilities/music_utilities'

@mobxRemotedev
export default class KeyboardController {
  constructor(private readonly store: KeyboardStore) {
    makeObservable(this)
  }

  @action setLoading(loading: boolean) {
    this.store.loading = loading
  }

  @action setKeyDown(key: number, velocity: number) {
    this.store.keys.set(key, true)
    this.store.timestamps.unshift(performance.now())
    this.store.velocity = velocity
    this.store.velocities.unshift(velocity)
    this.store.notesPlayed.push(`${keyNumberToNote(key)}${keyNumberToOctave(key)}`)
  }

  @action setKeyUp(key: number) {
    this.store.keys.set(key, false)
  }

  @action resetNotesPlayed(): void {
    // TODO: figure out where to call this Keyboard vs NoteTimeline
    this.store.notesPlayed = []
  }
}
