import { reaction } from 'mobx'
import { convertKey } from 'utilities/music_utilities'
import * as Tone from 'tone'
import KeyboardController from './controller'
import { Piano } from 'instruments/piano'
import { MidiSelectPresenter } from 'components/midi_select/presenter'
import KeyboardPresenter from './presenter'

// https://www.midi.org/specifications/item/table-1-summary-of-midi-message
const MESSAGE_FLAGS = {
  noteOff: 128,
  noteOn: 144,
  afterTouchPolyphonic: 160,
  afterTouchChannelPressure: 208,
  controlChange: 176,
  programChange: 192,
  pitchBend: 224,
}

export default class KeyboardPlayer {
  constructor(
    private readonly keyboardController: KeyboardController,
    private readonly keyboardPresenter: KeyboardPresenter,
    private readonly midiPresenter: MidiSelectPresenter,
  ) {
    this.bindMessages()
  }

  private readonly bindMessages = () => {
    reaction(
      () => this.midiPresenter.selectedInput,
      (midiInput: WebMidi.MIDIInput | undefined) => {
        if (midiInput == null) return
        midiInput.onmidimessage = (e) => this.handleMidiMessage(e)
      },
    )
  }

  private readonly handleMidiMessage = (e: WebMidi.MIDIMessageEvent) => {
    if (!this.keyboardPresenter.enableKeyboard) return

    const [status, ...entries] = e.data
    let key: number
    let velocity: number
    switch (status) {
      case MESSAGE_FLAGS.controlChange:
        break
      case MESSAGE_FLAGS.pitchBend:
        return
      case MESSAGE_FLAGS.noteOff:
        ;[key] = entries
        // not used by vmk-88
        this.onKeyUp(key)
        break
      case MESSAGE_FLAGS.noteOn:
        ;[key, velocity] = entries
        if (velocity > 0) {
          this.onKeyDown(key, velocity)
        } else {
          // note released
          this.onKeyUp(key)
        }
        break
      default:
        return
    }
  }

  private readonly onKeyDown = (key: number, velocity: number) => {
    const note = convertKey(key)
    console.log(key, note)
    // see https://github.com/Tonejs/Tone.js/issues/306
    const time = Tone.context.currentTime // immediately fires the note
    // 127 is a magic number. the largest value is 127, we need it to be 100
    const normalizedVelocity = velocity / 127
    this.keyboardController.setKeyDown(key, normalizedVelocity)
    Piano.triggerAttack(note, time, normalizedVelocity)
  }

  private readonly onKeyUp = (key: number) => {
    const note = convertKey(key)
    this.keyboardController.setKeyUp(key)
    Piano.triggerRelease(note)
  }

  public readonly startAudio = async () => {
    await Tone.loaded()
    await Tone.start()
    this.keyboardController.setLoading(false)
  }
}
