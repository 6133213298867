import * as Tone from 'tone'
import { reaction } from 'mobx'
import { MetronomeStore } from './store'
import { Woodblock } from 'instruments/woodblock'

export class MetronomePlayer {
  // uses the strategy detailed here: https://meowni.ca/posts/metronomes/
  constructor(private readonly store: MetronomeStore) {
    this.bindReactions()
  }

  private readonly player = Woodblock

  private readonly bindReactions = () => {
    reaction(
      () => this.store.state === 'on',
      (start: boolean) => {
        if (start) {
          this.play()
        } else {
          this.pause()
        }
      },
    )

    reaction(
      () => this.store.bpm,
      (bpm: number) => {
        if (this.store.state === 'off') {
          return
        }
        Tone.Transport.bpm.value = clamp(bpm ?? 1, 1, 400)
      },
    )

    reaction(
      () => this.store.volume,
      (volume: number) => {
        if (this.store.state === 'off') {
          return
        }
        this.player.volume.value = volume
      },
    )
  }

  private play() {
    Tone.Transport.scheduleRepeat((time) => {
      this.player.start(time).stop(time + 0.05)
    }, '4n')
    this.player.volume.value = this.store.volume
    Tone.Transport.bpm.value = this.store.bpm
    Tone.Transport.start()
  }

  private pause() {
    Tone.Transport.stop()
    Tone.Transport.cancel()
  }
}

// 60, 30, 90
function clamp(a: number, min: number, max: number): number {
  return Math.max(min, Math.min(a, max))
}
