import mobxRemotedev from 'mobx-remotedev'
import { KeyboardAssistSelectorStore } from 'components/keyboard_assist_selector/store'
import { computed, makeObservable } from 'mobx'
import { Option } from '../base/selects'

@mobxRemotedev
export class KeyboardAssistSelectorPresenter {
  constructor(private readonly store: KeyboardAssistSelectorStore) {
    makeObservable(this)
  }

  @computed get selectedAssist(): Option {
    const [value, name] = this.store.selectedAssist
    return { key: name, value }
  }

  @computed get options(): Option[] {
    return this.store.options.map(([value, name]) => ({ key: name, value }))
  }
}
