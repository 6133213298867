import * as React from 'react'
import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { classNames } from '../../utilities/style_utilities'

export type Option = { key: string; value: any }
type SelectProps = {
  defaultOption?: Option
  onChange: (o: Option) => void
  options: Option[]
  label?: string
}

export function Select(p: SelectProps) {
  const label = p.label ? p.label : ''
  const [selected, setSelected] = useState(p.defaultOption || p.options[0])
  const handleChange = (s: Option) => {
    setSelected(s)
    p.onChange(s)
  }

  return (
    <Listbox
      value={selected}
      onChange={handleChange}
    >
      {({ open }) => (
        <>
          <Listbox.Label className='block text-sm font-medium text-gray-700'>{label}</Listbox.Label>
          <div className='relative mt-1'>
            <Listbox.Button className='relative flex-1 inline-block w-full p-2 py-2 pl-3 pr-10 text-left bg-white border-2 border-gray-300 cursor-default cursor-pointer rounded-md shadow-sm focus:outline-none focus:border-indigo-500 sm:text-sm'>
              <span className='block truncate'>{selected.key}</span>
              <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                <SelectorIcon
                  className='w-5 h-5 text-gray-400'
                  aria-hidden='true'
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                static
                className='absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
              >
                {p.options.map((option, i) => (
                  <Listbox.Option
                    key={i}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9',
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {option.key}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            )}
                          >
                            <CheckIcon
                              className='w-5 h-5'
                              aria-hidden='true'
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
