import { SheetMusicSelectorStore, SearchItem } from './store'
import { makeObservable, action } from 'mobx'
import mobxRemotedev from 'mobx-remotedev'

@mobxRemotedev
export class SheetMusicSelectorController {
  constructor(private readonly store: SheetMusicSelectorStore) {
    makeObservable(this)
  }

  /*
  // they've got annoying cors stuff so this doesnt work
  private async fetchImslpData() {
    const url =
      "https://imslp.org/imslpscripts/API.ISCR.php?account=worklist/disclaimer=accepted/sort=id/type=1/start=0/retformat=json"
    const data = await fetch(url).then(data => data.json())
    console.log(data)
  }
  */

  @action setSearchItems(items: SearchItem[]) {
    this.store.sheetMusic.replace(items)
  }

  @action setSelectedSearchItem(item: SearchItem) {
    this.store.selectedSheetMusic = item
  }
}
