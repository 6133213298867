import * as React from 'react'
import { MidiSelectStore } from './store'
import { MidiSelectPresenter } from './presenter'
import { MidiSelectController } from './controller'
import { AppStore } from 'app/store'
import { observer } from 'mobx-react'
import { Select, Option } from 'components/base/selects'

export function createMidiInputSelect(appStore: AppStore) {
  const store = new MidiSelectStore()
  const presenter = new MidiSelectPresenter(store)
  const controller = new MidiSelectController(store, appStore)

  const C = observer(() => {
    return (
      <MidiInputSelect
        defaultInput={presenter.selectedInput}
        onChange={(o) => controller.setSelectedInput(o.value)}
        inputs={presenter.inputs}
      />
    )
  })

  return {
    midiSelectController: controller,
    midiSelectPresenter: presenter,
    MidiInputSelect: C,
  }
}

export const MidiInputSelect = ({
  defaultInput,
  onChange,
  inputs,
}: {
  defaultInput?: WebMidi.MIDIInput
  onChange: (o: Option) => void
  inputs: WebMidi.MIDIInput[]
}) => {
  const defaultOption = defaultInput
    ? { key: defaultInput.name, value: defaultInput }
    : {
        key: 'Please plug in a Midi Controller',
        value: undefined,
      }
  const options = inputs.length === 0 ? [defaultOption] : inputs.map((midi) => ({ key: midi.name, value: midi }))

  return (
    <div id='MidiSelect'>
      <label
        className='flex-1 block mb-2'
        htmlFor='MidiSelect__input'
      >
        <p className='font-bold'>Midi Device</p>
      </label>
      <div className='flex flex-row flex-col w-full'>
        <Select
          onChange={onChange}
          options={options}
          defaultOption={defaultOption}
        />
      </div>
    </div>
  )
}
