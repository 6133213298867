import mobxRemotedev from 'mobx-remotedev'
import { makeObservable, observable } from 'mobx'

@mobxRemotedev
export class MicrophoneDeviceStore {
  constructor() {
    makeObservable(this)
  }

  @observable.ref state: State = { kind: 'init' }

  @observable.ref subscribers: number = 0
}

export type ConnectedState = {
  kind: 'connected'
  audioContext: AudioContext
  audioDevice: MediaStream
  audioSource: MediaStreamAudioSourceNode
}

export type DisconnectedState = {
  kind: 'disconnected'
}

export type FailedState = {
  kind: 'failed'
}

export type InitState = {
  kind: 'init'
}

export type State = InitState | ConnectedState | DisconnectedState | FailedState
