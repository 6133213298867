import { HotkeyActionDeviceName, HotkeyManagerStore, HotkeyRegistryName } from './store'
import { HotkeyManagerController } from './controller'
import { HotkeyManagerPresenter } from './presenter'
import { createHotkeyRegistry } from 'modules/hotkey_registry'
import { createQwertyActions } from 'modules/qwerty_actions'
import { HotkeyAction } from 'modules/hotkey_registry/types'

export function createHotkeyManager() {
  const hotkeyManagerStore = new HotkeyManagerStore()
  const hotkeyManagerController = new HotkeyManagerController(hotkeyManagerStore)
  const hotkeyManagerPresenter = new HotkeyManagerPresenter(hotkeyManagerStore)

  // create device listeners that will map physical device inputs to HotkeyActions
  const qwertyDevice = createQwertyActions()
  qwertyDevice.qwertyActionsController.start()
  qwertyDevice.qwertyActionsController.addEventListener(hotkeyManagerController.fireAction)

  // add the device to the hotkey manager for record keeping
  hotkeyManagerController.addDevice(HotkeyActionDeviceName.QWERTY, qwertyDevice)

  // create hotkey registries that will map streams of actions to function calls
  const globalHotkeyRegistry = createHotkeyRegistry().hotkeyRegistry
  hotkeyManagerController.addRegistry(HotkeyRegistryName.GLOBAL, globalHotkeyRegistry)

  // activate the global hotkey registry
  hotkeyManagerController.activateRegistry(HotkeyRegistryName.GLOBAL)

  const cb = () => alert("We'll help you out as soon as we have help articles to show you")
  globalHotkeyRegistry.controller.addEventListener([HotkeyAction.HELP], cb)

  return {
    hotkeyManagerController,
    hotkeyManagerPresenter,
  }
}

export type HotkeyManagerModule = {
  hotkeyManagerController: HotkeyManagerController
  hotkeyManagerPresenter: HotkeyManagerPresenter
}
