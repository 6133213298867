import * as React from 'react'

export const InvisibleButton = ({
  onClick,
  children,
  className = '',
}: {
  onClick(): void
  children: React.ReactNode
  className?: string
}) => {
  const classes = [
    'inline-flex justify-center p-2 text-sm font-medium text-indigo-600 bg-transparent border-none hover:underline focus:outline-none focus:text-indigo-500',
    className,
  ].join(' ')
  return (
    <button
      className={classes}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export const TextButton = ({
  onClick,
  children,
  className = '',
}: {
  onClick(): void
  children: React.ReactNode
  className?: string
}) => {
  const classes = [
    'inline-flex justify-center p-2 text-sm font-medium text-indigo-600 bg-transparent border-none hover:underline focus:outline-none focus:text-indigo-500 whitespace-nowrap',
    className,
  ].join(' ')
  return (
    <button
      className={classes}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export const PrimaryButton = ({
  onClick,
  children,
  className = '',
  error,
}: {
  onClick(): void
  children: React.ReactNode
  className?: string
  error?: string | null
}) => {
  const classes = [
    'inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border-2 border-indigo-600 shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 whitespace-nowrap',
    !className && 'rounded-md',
    error && 'border-red-500 focus:ring-red-500 bg-red-500 hover:bg-red-600',
    className,
  ]
    .filter((x) => !!x)
    .join(' ')
  return (
    <button
      className={classes}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export const NavButton = ({
  onClick,
  children,
  className = '',
}: {
  onClick(): void
  children: React.ReactNode
  className?: string
}) => {
  const classes = [
    'w-full flex items-center px-3 py-2 text-sm focus:outline-none font-medium rounded-md text-gray-600 hover:bg-gray-100 hover:text-indigo-600',
    className,
  ].join(' ')
  return (
    <button
      className={classes}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
