import mobxRemotedev from 'mobx-remotedev'
import { computed, makeObservable } from 'mobx'
import { MicrophoneRecorderStore } from './store'
import { Recording } from 'modules/recording/recording'

@mobxRemotedev
export class MicrophoneRecorderPresenter {
  constructor(private readonly store: MicrophoneRecorderStore) {
    makeObservable(this)
  }

  @computed get playableRecordings(): Recording[] {
    return this.recordings.filter((recording) => recording.isPlayable)
  }

  @computed get recordings(): Recording[] {
    return this.store.recordings
  }

  @computed get isRecording(): boolean {
    return this.store.state.kind === 'recording'
  }
}
