import { makeObservable, observable, IObservableArray } from 'mobx'

export class MidiSelectStore {
  constructor() {
    makeObservable(this)
  }

  inputs: IObservableArray<WebMidi.MIDIInput> = observable.array([], { deep: false })
  @observable.ref selectedInput: WebMidi.MIDIInput | undefined = undefined
}
