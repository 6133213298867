import mobxRemotedev from 'mobx-remotedev'
import { makeObservable, observable } from 'mobx'
import { State } from './types'

@mobxRemotedev
export class MicrophoneInputStore {
  constructor() {
    makeObservable(this)
  }

  @observable.ref state: State = { kind: 'init' }
  @observable.ref volume: number = 0
  @observable.ref pitch: number = 0
  @observable.ref clarity: number = 0
  @observable.ref floatFrequencyData: Float32Array = new Float32Array()
  @observable pitchData: number[] = observable.array([])
}
