import { makeObservable, computed } from 'mobx'
import { MidiSelectStore } from './store'

export class MidiSelectPresenter {
  constructor(private readonly store: MidiSelectStore) {
    makeObservable(this)
  }

  @computed get inputs(): WebMidi.MIDIInput[] {
    return this.store.inputs
  }

  @computed get selectedInput(): WebMidi.MIDIInput | undefined {
    return this.store.selectedInput
  }
}
