import mobxRemotedev from 'mobx-remotedev'
import { HotkeyRegistryStore } from './store'
import { action, makeObservable } from 'mobx'
import { HotkeyAction } from 'modules/hotkey_registry/types'

@mobxRemotedev
export class HotkeyRegistryController {
  constructor(private readonly store: HotkeyRegistryStore) {
    makeObservable(this)
  }

  @action.bound fireAction(action: HotkeyAction) {
    const { actionHistory, maxActionLength } = this.store
    // update our action history
    actionHistory.push(action)

    // remove the first element of our action history if adding the new element makes it too long
    if (actionHistory.length > maxActionLength) {
      actionHistory.splice(0, 1)
    }

    // will turn [cut, paste, delete] into [ cut paste delete, paste delete, delete ]
    const actionsToCheck = actionHistory.reduceRight((acc, curr) => {
      const lastVal = acc.length > 0 ? acc[acc.length - 1] : ''
      const newVal = this.toActionString([curr, lastVal].filter((x) => !!x)) // deal with that empty string above
      return [...acc, newVal]
    }, [])

    // call all the listeners for the actions of varying length we mapped above
    actionsToCheck.forEach((action) => {
      const listeners = this.store.actionMappings.get(action)
      if (listeners != null && listeners.length > 0) {
        listeners.forEach((listener) => listener())
      }
    })
  }

  @action.bound addEventListener(action: string[], listener: () => void) {
    if (action.length > this.store.maxActionLength) {
      throw new Error(
        `Can not set actions longer than ${this.store.maxActionLength} steps long, your action "${action}" has ${action.length} steps`,
      )
    }

    const key = this.toActionString(action)
    const value = this.store.actionMappings.has(key) ? [...this.store.actionMappings.get(key)] : []
    value.push(listener)
    this.store.actionMappings.set(key, value)
    this.store.activeListeners++
  }

  // returns false if nothing needed to be removed
  // returns true if the listener was removed
  @action.bound removeEventListener(action: string[], listener: () => void): boolean {
    // if we dont have listeners for this action then bail
    const key = this.toActionString(action)
    if (!this.store.actionMappings.has(key)) {
      return false
    }

    const listeners = [...this.store.actionMappings.get(key)]
    const listenerIndex = listeners.findIndex((l) => l === listener)

    // if we dont have the specified listener already listening to the action then bail
    if (listenerIndex === -1) {
      return false
    }

    listeners.splice(listenerIndex, 1)
    if (listeners.length === 0) {
      // if there are no listeners after this listener has been removed then delete the action
      this.store.actionMappings.delete(key)
    } else {
      // set the listeners back to the action excluding the removed listener
      this.store.actionMappings.set(key, listeners)
    }
    this.store.activeListeners--
    return true
  }

  private toActionString(action: string[]): string {
    return action.join(' ').toLowerCase()
  }
}
