import { SheetMusicController } from './controller'
import { SheetMusicPresenter } from './presenter'
import { reaction, action } from 'mobx'
import { OpenSheetMusicDisplay } from 'opensheetmusicdisplay'
import { SheetMusicXMLDocument } from './store'
import { SheetMusicSelectorPresenter } from 'components/sheet_music_selector/presenter'
import { SearchItem } from 'components/sheet_music_selector/store'

const OSMD_CONFIG = { autoResize: false }
export class SheetMusicRenderer {
  constructor(
    private readonly controller: SheetMusicController,
    private readonly presenter: SheetMusicPresenter,
    private readonly sheetMusicSelectorPresenter: SheetMusicSelectorPresenter,
  ) {
    this.bindObservers()
  }

  private bindObservers(): void {
    reaction(() => this.presenter.osmdNode, this.renderDom)
    reaction(() => this.presenter.currentSheetMusic, this.renderSheetMusic)
    reaction(() => this.sheetMusicSelectorPresenter.activeItem, this.downloadSheetMusic)
  }

  private readonly renderDom = action((node: HTMLElement): void => {
    if (node == null) {
      return
    }
    const osmd = new OpenSheetMusicDisplay(node, OSMD_CONFIG)
    this.controller.setOsmd(osmd)
  })

  private readonly downloadSheetMusic = (item?: SearchItem) => {
    if (item == null) {
      return
    }
    this.controller.downloadSheetMusic(item.name, item.url)
  }

  private readonly renderSheetMusic = async (sheet: SheetMusicXMLDocument | undefined) => {
    if (sheet == null) {
      return
    }
    this.presenter.osmd.render()
  }
}
