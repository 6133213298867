import * as React from 'react'
import { observer } from 'mobx-react'
import { RecordingListStore } from 'components/recording-list/store'
import { RecordingListController } from 'components/recording-list/controller'
import { RecordingListPresenter } from 'components/recording-list/presenter'
import { Singleton } from 'singleton'
import { UploadedRecording } from 'services/recording_service'
import { differenceInCalendarDays, formatDistanceToNow, intlFormat } from 'date-fns'
import { InvisibleButton } from 'components/base/buttons'
import { PlayIcon } from 'components/base/icons'
import { Recording } from 'modules/recording/recording'
import { SingleRecording } from 'components/single_recording'

export function createRecordingList(singleton: Singleton) {
  const { recordingService } = singleton.services

  const recordingListStore = new RecordingListStore()
  const recordingListController = new RecordingListController(recordingListStore, recordingService)
  const recordingListPresenter = new RecordingListPresenter(recordingListStore)

  const RecordingList = observer(() => {
    React.useEffect(() => {
      recordingListController.loadRecordings()
    }, [])

    const { recordings, uploadedRecordings } = recordingListStore

    const recordingList = recordings.map((recording) => (
      <li key={recording.key} className='mt-2'>
        <SingleRecording recording={recording} />
      </li>
    ))

    const uploadedRecordingList = uploadedRecordings.map((recording) => (
      <li key={recording.key} className='mt-2'>
        <RecordingListItem
          key={recording.pk}
          onDownloadClick={recordingListController.downloadRecording}
          uploadedRecording={recording}
        />
      </li>
    ))

    return (
      <div>
        <ul>{recordingList}</ul>
        <ul>{uploadedRecordingList}</ul>
      </div>
    )
  })

  return { RecordingList }
}

const RecordingListItem = ({
  onDownloadClick,
  uploadedRecording,
}: {
  onDownloadClick: (u: UploadedRecording) => Promise<void>
  uploadedRecording: UploadedRecording
}) => {
  // date-fns format to year month day
  const now = new Date()
  const createdAt = new Date(uploadedRecording.createdAt)
  const formattedTime = intlFormat(createdAt, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true,
  })
  const durationAgo = formatDistanceToNow(createdAt)
  const interval = differenceInCalendarDays(createdAt, now)
  const formattedDuration = formatTime(uploadedRecording.duration)

  const Timestamp = () => {
    if (interval > 0) {
      return <div className='text-gray-400'>{formattedTime}</div>
    }

    return (
      <div className='text-gray-400' title={formattedTime}>
        {durationAgo} ago
      </div>
    )
  }

  const Button = () => {
    return (
      <InvisibleButton onClick={() => onDownloadClick(uploadedRecording)}>
        <PlayIcon />
      </InvisibleButton>
    )
  }

  return (
    <div className='flex flex-row items-center w-full h-16 p-4 bg-gray-100 shadow-inner SingleRecording rounded-md max-w-sm'>
      <div className='SingleRecording__Button'>
        <Button />
      </div>
      <div className='SingleRecording__Duration w-24'>
        <div className='text-gray-500'>{formattedDuration}</div>
      </div>
      <div className='SingleRecording__Time justify-self-end'>
        <Timestamp />
      </div>
    </div>
  )
}

function formatTime(t: number): string {
  // 540ms -> 00:00.540
  // 1000ms -> 00:01.000
  // 10000ms -> 00:10.000
  // 60000ms -> 01:00.000

  const minutes = Math.floor(t / 60000)
  const seconds = Math.floor((t % 60000) / 1000)
  const milliseconds = Math.floor((t % 1000) / 10)
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${milliseconds
    .toString()
    .padStart(3, '0')}`
}