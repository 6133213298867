import { HotkeyRegistryStore } from './store'
import { HotkeyRegistryController } from './controller'
import { HotkeyRegistryPresenter } from './presenter'

export type HotkeyRegistry = {
  controller: HotkeyRegistryController
  presenter: HotkeyRegistryPresenter
}

export function createHotkeyRegistry() {
  const hotkeyRegistryStore = new HotkeyRegistryStore()
  const hotkeyRegistryController = new HotkeyRegistryController(hotkeyRegistryStore)
  const hotkeyRegistryPresenter = new HotkeyRegistryPresenter(hotkeyRegistryStore)

  const hotkeyRegistry = {
    controller: hotkeyRegistryController,
    presenter: hotkeyRegistryPresenter,
  }

  return { hotkeyRegistry }
}

export type HotkeyRegistryModule = {
  hotkeyRegistryController: HotkeyRegistryController
  hotkeyRegistryPresenter: HotkeyRegistryPresenter
}
