import mobxRemotedev from 'mobx-remotedev'
import { makeObservable, observable } from 'mobx'
import { HotkeyAction } from 'modules/hotkey_registry/types'

@mobxRemotedev
export class QwertyActionsStore {
  constructor() {
    makeObservable(this)
  }

  @observable.ref on: boolean = false

  @observable keys: Set<string> = observable.set(new Set(), { deep: false })

  @observable subscribers: Set<(a: HotkeyAction) => void> = observable.set(new Set(), {
    deep: false,
  })
}
